import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import DoneIcon from '@mui/icons-material/Done';
import WorkIcon from '@mui/icons-material/Work';
import QuizIcon from '@mui/icons-material/Quiz';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import GeneralBusinessForm2 from '../general-application-components/GeneralBusinessForm2';
import GeneralDocumentForm from '../general-application-components/GeneralDocumentForm';
import GeneralSuccessApplicationMessage from '../general-application-components/GeneralSuccessApplicationMessage';
import GeneralPersonalQuestionnaire from '../general-application-components/GeneralPersonalQuestionnaire';
import GeneralMergedEngagementLetter from '../general-application-components/GeneralMergedEngagementLetter';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className, icon } = props;

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icon}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};


const GeneralMergedApplication = () => {

    const BASE_STEPS = [
        { label: 'Engagement Letter', icon: <EmailIcon /> },
        { label: 'Personal Questionnaire', icon: <QuizIcon /> },
        { label: 'Documents Upload', icon: <UploadFileIcon /> },
        { label: 'Success Message', icon: <DoneIcon /> }
    ];
    const BUSINESS_STEPS = [{ label: 'Business Questionnaire', icon: <WorkIcon /> }];

    const [clientType, setClientType] = useState(null);
    const [step, setStep] = useState(0);
    const [steps, setSteps] = useState(BASE_STEPS);
    const { userInfo, userToken } = useSelector((state) => state.user)
    const navigate = useNavigate();


    const handleNextStep = () => {
        if (step < steps.length - 1) {
            setStep(prevStep => prevStep + 1);
        }
    };

    const handleClientType = (type) => {
        setClientType(type);
    
        let newSteps = [...BASE_STEPS];
    
        switch(type) {
            case 'personal':
                newSteps = [...BASE_STEPS];
                break;
            case 'both':
                newSteps.splice(1, 0, ...BUSINESS_STEPS);
                break;
            default:
                break;
        }
    
        setSteps(newSteps);
    };

    function getComponentForStep(stepLabel) {
        switch(stepLabel.label) {
            case "Engagement Letter":
                return <GeneralMergedEngagementLetter setStep={setStep} handleNextStep={handleNextStep} userToken={userToken} />;
            case "Business Questionnaire":
                return <GeneralBusinessForm2 userToken={userToken} handleNextStep={handleNextStep} userType={userInfo?.mergedAccount} />;
            case "Personal Questionnaire":
                return <GeneralPersonalQuestionnaire userToken={userToken} handleNextStep={handleNextStep} userType={userInfo?.mergedAccount} />;
            case "Documents Upload":
                return <GeneralDocumentForm userToken={userToken} handleNextStep={handleNextStep} userType={userInfo?.mergedAccount} />;
            case "Success Message":
                return <GeneralSuccessApplicationMessage userType={userInfo?.mergedAccount} />;
            default:
                return null;
        }
    }

    useEffect(() => {
        if(userInfo) {
            console.log(userInfo)
        }
    })

    useEffect(() => {
        if (userInfo?.questionnaire?.currentLetter === true) {
            // Finds the index of "Engagement Letter" and set the step to the next index
            const engagementLetterIndex = steps.findIndex(stepObj => stepObj.label === "Engagement Letter");
            if (engagementLetterIndex !== -1 && engagementLetterIndex < steps.length - 1) {
                setStep(engagementLetterIndex + 1);
            }
        }

        if (userInfo?.questionnaire?.currentLetter === true && userInfo?.questionnaire?.businessQuestionnaire === true) {
            // Finds the index of "Engagement Letter" and set the step to the next index
            const businessIndex = steps.findIndex(stepObj => stepObj.label === "Business Questionnaire");
            if (businessIndex !== -1 && businessIndex < steps.length - 1) {
                setStep(businessIndex + 1);
            }
        }
        if (userInfo?.questionnaire?.currentQuestionnaire === true && userInfo?.questionnaire?.currentLetter === true) {
            // Finds the index of "Engagement Letter" and set the step to the next index
            const questionnaireIndex = steps.findIndex(stepObj => stepObj.label === "Personal Questionnaire");
            if (questionnaireIndex !== -1 && questionnaireIndex < steps.length - 1) {
                setStep(questionnaireIndex + 1);
            }
        }

      
    }, [userInfo, steps]);


    return (
        <Box id="client-application-wrapper">
            {/* {!clientType ? (
                <Box className='client-type-app-wrapper'>
                    <Typography component="h4">
                        Please choose your client type:
                    </Typography>
                    <Box className='client-type-button-container'>
                        <Button className="client-type-button personal" onClick={() => handleClientType('personal')}>
                            Personal Client
                        </Button>
                        <Button className="client-type-button both" onClick={() => handleClientType('both')}>
                           Personal & Business Client
                        </Button>
                    </Box>
                </Box>

            ) : ( */}
                <Grid container flexDirection="column" sx={{ width: '100%' }} spacing={4}>
                    <Grid item>
                        <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
                            {steps.map((stepObj) => (
                                <Step key={stepObj.label}>
                                    <StepLabel StepIconComponent={(props) => <ColorlibStepIcon {...props} icon={stepObj.icon} />}>
                                        {stepObj.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
               
                    <Grid item>
                        {getComponentForStep(steps[step])}
                    </Grid>
                </Grid>
            {/* )} */}
        </Box>
    );
}

export default GeneralMergedApplication;
