import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import homepageBanner from '../../assets/homepage-banner.png';
import TaxPrepService from '../../assets/homepage/tax-prep-service.png';
import ConsultingService from '../../assets/homepage/consulting-service.png';
import OtherService from '../../assets/homepage/other-service.png';
import Check from '../../assets/homepage/check.png';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FreeQuoteContainer from "../screens/FreeQuoteComponent";

import { faq } from '../../utils/faq.js'
import '../../styles/landing.css'


const Landing = () => {

  	return (
		<Box id="landing-page">
			<Box className="main-banner">
				<Container>
					<Grid container alignItems="center">
						<Grid item sm={6} xs={12} className="heroTextContainer">
							<Box>
								<Typography component="h1" align="center">
									Welcome To 
									<Typography component="span" color="primary">TaxBox</Typography>
								</Typography>
								<Typography component="p">
									We have taken the complexity out and made sure your experience is simple and stress free. We made it easy for you to contact us with all your tax related questions as well!
								</Typography>
							</Box>
						</Grid>
						<Grid item sm={6} xs={12} className="hero-image-container">
							<img
								className="hero-image"
								src={homepageBanner}
								alt="TaxBox Banner"
							/>
						</Grid>
					</Grid>
				</Container>
			</Box>
			{/* Services section */}
			<Grid container className="service-items-wrapper" justifyContent='center' mb={4}>
				<Container>
					<Grid container className="service-item-container item-1" >
						<Grid item sm={5.5} xs={12}>
							<img src={TaxPrepService} />
						</Grid>
						<Grid item sm={6} xs={12} pr={2} pl={2}>
							<Typography component="h3">Tax Preparation</Typography>
							<Typography component="p" className="service-item-desc">From guiding individuals through their tax obligations to providing comprehensive tax solutions for businesses, estates, and gifts, our Tax Preparation services are here to simplify the complex and help you achieve your financial goals.</Typography>
							<Grid container mt={2}>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Individuals</Typography>
									</Grid>
								</Grid>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Businesses</Typography>
									</Grid>
								</Grid>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Estates</Typography>
									</Grid>
								</Grid>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Gifts</Typography>
									</Grid>
								</Grid>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Other</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid container mt={4} justifyContent="flex-start" pr={4}>
								<Grid item>
									<Button
										component={Link}
										to="/services/tax-preparation"
									>
										Learn More
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
				<Box className="with-background">
					<Container>
						<Grid container className="service-item-container item-2">
							<Grid item sm={6} xs={12}>
								<Typography component="h3">Consulting Services</Typography>
								<Typography component="p" className="service-item-desc">Looking for expert guidance in tax planning, budgeting, retirement options, and beyond? Our Consulting services are tailored to meet your specific needs, providing valuable insights and strategies to enhance your financial stability and success.</Typography>
								<Grid container mt={2}>
									<Grid item container xs={6} className="bullet-point">
										<Grid item>
											<img src={Check}/>
										</Grid>
										<Grid item>
											<Typography component="p">Tax planning</Typography>
										</Grid>
									</Grid>
									<Grid item container xs={6} className="bullet-point">
										<Grid item>
											<img src={Check}/>
										</Grid>
										<Grid item>
											<Typography component="p">Retirement options</Typography>
										</Grid>
									</Grid>
									<Grid item container xs={6} className="bullet-point">
										<Grid item>
											<img src={Check}/>
										</Grid>
										<Grid item>
											<Typography component="p">Budgeting</Typography>
										</Grid>
									</Grid>
									<Grid item container xs={6} className="bullet-point">
										<Grid item>
											<img src={Check}/>
										</Grid>
										<Grid item>
											<Typography component="p">Other</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid container mt={4} justifyContent="flex-start" pr={4}>
									<Grid item>
										<Button
											component={Link}
											to="/services/consulting"
										>
											Learn More
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item sm={5.5} xs={12}>
								<img src={ConsultingService} />
							</Grid>
						</Grid>
					</Container>
				</Box>
				<Container>
					<Grid container className="service-item-container item-3">
						<Grid item sm={5.5} xs={12} >
							<img src={OtherService} />
						</Grid>
						<Grid item sm={6} xs={12}>
							<Typography component="h3">Other Services</Typography>
							<Typography component="p" className="service-item-desc">Looking for expert guidance in tax planning, budgeting, retirement options, and beyond? Our Consulting services are tailored to meet your specific needs, providing valuable insights and strategies to enhance your financial stability and success.</Typography>
							<Grid container mt={2}>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Bookkeeping</Typography>
									</Grid>
								</Grid>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Sales taxes</Typography>
									</Grid>
								</Grid>
								<Grid item container xs={6} className="bullet-point">
									<Grid item>
										<img src={Check}/>
									</Grid>
									<Grid item>
										<Typography component="p">Representation in an audit</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid container mt={4} justifyContent="flex-start" pr={4}>
								<Grid item>
									<Button
										component={Link}
										to="/services/other"
									>
										Learn More
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			{/* Free Quote section */}
			<FreeQuoteContainer />
			{/* FAQ section */}
			<Grid container className="faq-section" mb={10}>
				<Grid item sm={4} xs={12} mb={3} pl={2} pr={2}>
             		<Typography component="h2">
					 	Still have More Questions?
            		</Typography>
				</Grid>
				<Grid item sm={6} xs={12}>
					{faq.map((item, index) => (
          				<Accordion className="faq-item">
          					<AccordionSummary
            					expandIcon={<ExpandMoreIcon />}
            					aria-controls="panel1a-content"
            					id="panel1a-header"
          					>
            					<Typography component="h4">{item.question}</Typography>
          					</AccordionSummary>
          					<AccordionDetails>
            					<Typography component="p">
              						{item.answer}
            					</Typography>
          					</AccordionDetails>
        				</Accordion>
        			))}
				</Grid>
			</Grid>
		</Box>
  	)
}

export default Landing;