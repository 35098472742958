import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import download from 'downloadjs';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';


const ViewFreeQuote = ({ open, setOpen, currentItem, userToken, setChildChanges }) => {

    const [errorMsg, setErrorMsg] = useState('');
    const [isComplete, setIsComplete] = useState(false);

	const handleClose = () => {
		setOpen(false);
        setErrorMsg('');
	}

    useEffect(() => {
        if(currentItem) {
            setIsComplete(currentItem.complete)
        }
    }, [currentItem])

    const toggleCompleteStatus = async () => {
        try {
            const updatedStatus = !isComplete; 
            const response = await axios.put(`/api/admin/free-quote/${currentItem._id}`, {
                complete: updatedStatus
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            });
            setChildChanges((prevChanges) => !prevChanges);

            setIsComplete(updatedStatus); 
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

	return (
		<Dialog
			aria-labelledby="admin-free-quote-modal"
        	aria-describedby="admin-free-quote-modal"
            id="admin-free-quote-modal"
        	open={open}
			onClose={handleClose}
            fullWidth
            maxWidth="sm"
		>
			<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }} >
                <Grid container justifyContent="space-between" alignItems="center" className="edit-dialog-header">
                    <Grid item>
                        <Chip color="warning" label="View Mode" />
                    </Grid>
					<Grid item>
						<IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
					</Grid>
				</Grid>
                {currentItem ? (
                    <>
                    <Grid container mt={4}>
                        <Grid container item xs={10}>
                            <Grid item xs={12} className="info-item">
                                <Typography component="h6">
                                    First Name: 
                                </Typography>
                                <Typography component="p">
                                    {currentItem?.firstName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="info-item">
                                <Typography component="h6">
                                    Last Name: 
                                </Typography>
                                <Typography component="p">
                                    {currentItem?.lastName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="info-item">
                                <Typography component="h6">
                                    Email: 
                                </Typography>
                                <Typography component="p">
                                    {currentItem?.email}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="info-item">
                                <Typography component="h6">
                                    Interested in: 
                                </Typography>
                                <Typography component="p">
                                    {currentItem?.userType}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="info-item">
                                <Typography component="h6">
                                    Business Name: 
                                </Typography>
                                <Typography component="p">
                                    {currentItem?.businessName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="info-item-lg">
                                <Typography component="h6">
                                    Message: 
                                </Typography>
                                <Typography component="p">
                                    {currentItem?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={5}>
                            <Button onClick={toggleCompleteStatus} className={isComplete ? 'pending-btn' : 'complete-btn'}>
                                {isComplete ? 'Mark as Pending' : 'Mark as Complete'}
                            </Button>
                        </Grid>
                    </Grid>
                    </>

                ) : null}
			</Container>
		</Dialog>
	)
};

export default ViewFreeQuote;