export const makeAvatar = (name) => {
  const hashCode = s => Math.abs(s.split('').reduce((a, b) => ((a << 5) - a) + b.charCodeAt(0), 0));
  const colorIndex = hashCode(name) % 15; // use modulo to limit the index to the number of available colors
  const colors = [
        "#FF8C1F",
        "#ED127C",
        "#E6008F",
        "#FF692E",
        "#008CFF",
        "#00AAFF",
        "#FFA050",
        "#D50069",
        "#2E5DFF",
        "#FFBF7F",
        "#B3004A",
        "#0054B4",
        "#FFD1A6",
        "#7F0023",
        "#003366"
    ];
  const color = colors[colorIndex];
  
  return {
    style: {
      backgroundColor: color,
    },
    children: `${name.trim().toUpperCase().split(' ')[0][0]}${name.trim().toUpperCase().split(' ')[1][0]}`,
  };
};
