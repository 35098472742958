import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';


import { useFetchData } from '../../../../hooks/useFetchData';

import LoadingGrid from '../../../ui/Loading';
import ClientRefundsForm from './ClientRefundsForm';
import DeleteRefund from './DeleteRefund';
import EmptyDataComponent from '../../../ui/EmptyDataComponent';


const ClientRefunds = () => {
    const { userToken } = useSelector((state) => state.user);
    const location = useLocation();
    const { id } = useParams();
    const currentPath = location.pathname;
    const endpoint = currentPath.startsWith('/admin/merge/client-page/')
    ? `/api/client-page/refunds/${id}?userType=2`
    : `/api/client-page/refunds/${id}?userType=1`;
    const [childChanges, setChildChanges] = useState(false);
	const [loading, data, error] = useFetchData(endpoint, userToken, childChanges);
    const [open, setOpen] = useState(false);
    const [isDeleteMode, setDeleteMode] = useState(false);
    const [currentItem, setCurrentItem] = useState(null)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleDeleteOpen = (item) => {
		setCurrentItem(item);
    	setDeleteMode(true)
 	}

    const handleDeleteClose = () => {
        setDeleteMode(false);
        setCurrentItem(null);
    }
    
    return (
        <>
            <Box id="refunds-wrapper">
                <Grid container justifyContent="space-between" alignItems="center" className='refunds-header'>
                    <Grid item>
                        <Typography component="h1" variant='h1'>
                            Refunds/Amounts Due
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button className='add-refund-btn' onClick={handleOpen}>
                            Add New
                        </Button>
                    </Grid>
                </Grid>
                <Box>
                    {loading && <LoadingGrid />}
                    {error && (
                        <Typography>
                            Error. Could not retrieve data.
                        </Typography>
                    )}
                    {data && data.length === 0 && (
                        <EmptyDataComponent msg="No refunds found. All refunds will be displayed here." />
                    )}
                    {data && data.length > 0 && (
                        <Grid container spacing={2}>
                            {data.map((item, index) => {

                                const formattedAmount = new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(Math.abs(item.amount));

                                // Check if the amount is negative and format accordingly
                                const displayAmount = item.amount < 0 ? `(${formattedAmount})` : formattedAmount;

                                return (
                                    <Grid item xs={12} sm={12} md={6} key={item._id}>
                                        <Card mb={2} className={`refund-item ${parseFloat(item.amount) < 0 ? 'negative' : 'positive'}`}>
                                            <Grid container sx={{ m: 0, p: 2 }} justifyContent="space-between" alignItems="center">
                                                <Grid item container xs={11}>
                                                    <Grid item xs={12}>
                                                        <Typography variant='subtitle1'>
                                                            Jurisdiction:
                                                            <Typography component="span">
                                                                {item.jurisdiction}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant='subtitle1'>
                                                            Year:
                                                            <Typography component="span">
                                                                {item.taxYear}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant='subtitle1'>
                                                            Amount:
                                                            <Typography component="span">
                                                                {displayAmount}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    {parseFloat(item.amount) > 0 ? (
                                                        <Grid item xs={12}>
                                                            <Typography variant='subtitle1'>
                                                                Link:
                                                                <Link href={item.link} target="_blank">
                                                                    <Typography component="span">
                                                                        {item.link}
                                                                    </Typography>
                                                                </Link>
                                                            </Typography>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={() => handleDeleteOpen(item)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )}
                </Box>
            </Box>
            <ClientRefundsForm
                id={id} 
                open={open}
                setOpen={setOpen}
                setChildChanges={setChildChanges}
                userType={currentPath.startsWith('/admin/merge/client-page/') ? "2" : "1"}
            />
            <DeleteRefund 
                open={isDeleteMode}
                setOpen={handleDeleteClose}
                currentItem={currentItem}
                userToken={userToken}
                setChildChanges={setChildChanges}
            />
        </>
    )
}

export default ClientRefunds;