import { createSlice } from '@reduxjs/toolkit';
import { fetchAdminClientDetails } from '../actions/adminClientActions';

const initialState = {
    clientDetails: null,
    loading: false,
    error: null
  };
  
  const adminClientSlice = createSlice({
    name: 'adminClient',
    initialState,
    reducers: {
      // any synchronous reducers here
    },
    extraReducers: {
      [fetchAdminClientDetails.pending]: (state) => {
        state.loading = true;
        state.error = null;
      },
      [fetchAdminClientDetails.fulfilled]: (state, { payload }) => {
        state.loading = false;
        state.clientDetails = payload;
      },
      [fetchAdminClientDetails.rejected]: (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    }
});
  
export default adminClientSlice.reducer;