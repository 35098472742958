import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { useFetchData } from '../../../../hooks/useFetchData';

import GeneralClientFolders from '../../../general-client-components/general-client-documents/general-client-folders';
import GeneralClientFiles from '../../../general-client-components/general-client-documents/general-client-files';


const PersonalDocuments = () => {

    const { userToken, userInfo } = useSelector((state) => state.user);
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/c/dashboard/personal/files', userToken, childChanges);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        if(data) {
            console.log(data)
        }
    }, [data])
    

    useEffect(() => {
        if (data) {
            const folderNames = data.files.map((item) => item.name);
            setFolders(folderNames);
        }
    }, [data]);

    return (
        <>
            {data && !selectedFolder ? (
                <GeneralClientFolders 
                    data={data?.files} 
                    isDocumentUploadActive={data?.userStatus}
                    setSelectedFolder={setSelectedFolder} 
                    userToken={userToken}
                    folders={folders}
                    setChildChanges={setChildChanges}
                    userInfo={userInfo}
                    userType="1"
                    loading={loading}
                />
            ) : (
                <GeneralClientFiles 
                    data={data?.files} 
                    selectedFolder={selectedFolder} 
                    setSelectedFolder={setSelectedFolder} 
                    userToken={userToken} 
                />
               
            )} 
        </>
    )
}

export default PersonalDocuments;