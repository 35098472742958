import { useState } from 'react';
import axios from 'axios';

export const useDeleteData = (url, userToken) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [deleted, setDeleted] = useState(false);

    const handleDelete = async () => {
        setDeleteLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };

        try {
            const response = await axios.delete(url, config);
            if (response.status === 200) {
                setDeleted(true);
            } else {
                setDeleteError('Error deleting item');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setDeleteError(error.response.data.error);
            } else {
                setDeleteError('Error occurred. Please try again later.');
            }
        } finally {
            setDeleteLoading(false);
        }
    };

    return [handleDelete, deleted, setDeleted, deleteError, deleteLoading];
};