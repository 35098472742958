import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PaidIcon from '@mui/icons-material/Paid';
import Collapse from '@mui/material/Collapse';
import WorkIcon from '@mui/icons-material/Work';
import MailIcon from '@mui/icons-material/Mail';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Tooltip from '@mui/material/Tooltip';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const ClientClosedMenu = ({handleDrawer, checkIsActive, setShowSubmenu, showSubmenu, userInfo}) => {

    return (
        <>
            <DrawerHeader>
                <IconButton onClick={handleDrawer}>
                    <ChevronRightIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List className="list-closed">
                <ListItem sx={{ display: 'block' }}>
                    <Tooltip title="My account" placement="right-start">
                        <ListItemButton 
                            component={Link} 
                            to='/dashboard/my-account'
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: 2.5,
                                pr: "10px",
                                pl: "10px"
                            }}
                            className={`list-group-item ${checkIsActive('/dashboard/my-account') ? 'active' : ''}`}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: '0',
                                    justifyContent: 'center',
                                }}
                            >
                                <AccountBoxIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
                <ListItem sx={{ display: 'block' }}>
                    <Tooltip title="History" placement="right-start">
                        <ListItemButton 
                            component={Link} 
                            to='/dashboard/history'
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: 2.5,
                                pr: "10px",
                                pl: "10px"
                            }}
                            className={`list-group-item ${checkIsActive('/dashboard/history') ? 'active' : ''}`}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: '0',
                                    justifyContent: 'center',
                                }}
                            >
                                <HistoryIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
                <Divider />
                <ListItem sx={{ display: 'block' }}>
                    <Tooltip title="Documents" placement="right-start">
                        <ListItemButton 
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: 2.5,
                                pr: "10px",
                                pl: "10px"
                            }}
                            onClick={() => setShowSubmenu(!showSubmenu)}
                            
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: '0',
                                    justifyContent: 'center',
                                }}
                            >
                                <FileCopyIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
                {showSubmenu && (
                    <Collapse in={showSubmenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem sx={{pl: '40px'}}>
                                <Tooltip title="Personal Documents" placement="right-start">
                                    <ListItemButton 
                                        component={Link} 
                                        to='/dashboard/documents/personal'
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'center',
                                            px: 2.5,
                                            pr: "10px",
                                            pl: "10px"
                                        }}
                                        className={`list-group-item ${checkIsActive('/dashboard/documents/personal') ? 'active' : ''}`}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: '0',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <PersonIcon />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                            {!!userInfo && userInfo?.businesses.length > 0 && userInfo.businesses.map((business, index) => (
                                <React.Fragment>
                                    <ListItem sx={{pl: '40px'}}>
                                        <Tooltip title={business.businessName} placement="right-start">
                                            <ListItemButton 
                                                component={Link} 
                                                to={'documents/business/' + business.id}
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: 'center',
                                                    px: 2.5,
                                                    pr: "10px",
                                                    pl: "10px"
                                                }}
                                                className={`list-group-item ${checkIsActive(`/dashboard/documents/business/${business.id}`) ? 'active' : ''}`}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: '0',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <WorkIcon />
                                                </ListItemIcon>
                                            </ListItemButton>
                                        </Tooltip>
                                    </ListItem>
                                </React.Fragment>
                            ))}
                        </List>
                    </Collapse>
                )}
                <Divider />
                <ListItem sx={{ display: 'block' }}>
                    <Tooltip title="Refunds" placement="right-start">
                        <ListItemButton 
                            component={Link} 
                            to='/dashboard/refunds'
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: 2.5,
                                pr: "10px",
                                pl: "10px"
                            }}
                            className={`list-group-item ${checkIsActive('/dashboard/refunds') ? 'active' : ''}`}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: '0',
                                    justifyContent: 'center',
                                }}
                            >
                                <PaidIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
                <ListItem sx={{ display: 'block' }}>
                    <Tooltip title="Invite" placement="right-start">
                        <ListItemButton 
                            component={Link} 
                            to='/dashboard/invite'
                            sx={{
                                minHeight: 48,
                                justifyContent: 'center',
                                px: 2.5,
                                pr: "10px",
                                pl: "10px"
                            }}
                            className={`list-group-item ${checkIsActive('/dashboard/invite') ? 'active' : ''}`}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: '0',
                                    justifyContent: 'center',
                                }}
                            >
                                <MailIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </Tooltip>
                </ListItem>
            </List>
        </>
    )
    
}

export default ClientClosedMenu;