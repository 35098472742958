import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FreeQuoteContainer from "./FreeQuoteComponent";
import OtherService from '../../assets/homepage/other-service.png';
import QuickbooksLogo from '../../assets/logos/quickbook-cropped.png';
import WaveLogo from '../../assets/logos/wave-llogo-cropped.png'

import PricingGrid from "./PricingGrid";

import useScrollToTop from "../../hooks/useScrollToTop"

const OtherServices = () => {
    useScrollToTop();
    return (
        <Box id="other-services-page" mb={10}>
            <Grid container mt={5} mb={7}>
				<Grid item>
					<Typography component="h1">
						Other Services
					</Typography>
				    </Grid>
			</Grid>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={5} className="intro-wrapper">
                <Grid item xs={6}>
                    <Typography component="p">
                    Our goal is to become your one‐stop shop for all kinds of services for your business or yourself. We believe that once someone knows you then they are best suited to help you with everything else in regards to your financial situation and your financial goals.
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <img src={OtherService} className="banner-img" />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={5}>
                <Grid item xs={10} mb={3}>
                    <Typography component="h2" mb={1}>
                        Bookkeeping
                    </Typography>
                    <Typography component="p">
                        We support two platforms for our bookkeeping services which are
                        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1, verticalAlign: 'middle' }}>
                            <img src={QuickbooksLogo} alt="QuickBooks" style={{ height: 24, marginRight: 8 }} />  
                        </Box>
                        and
                        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 1, verticalAlign: 'middle' }}>
                            <img src={WaveLogo} alt="Wave" style={{ height: 24, marginRight: 8 }} />
                            .
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={12} container justifyContent="center" columnGap={3} rowGap={3}>
                    <Grid item xs={3} className="bookkeeping-item">
                        <Typography component="h3">
                            Full stack bookkeeper
                        </Typography>
                        <Typography component="p">
                            We are your bookkeeper through and through for a monthly fee. This entails transaction management, reconciliation, financial reporting, budgeting, invoice creation and collection, and so much more.
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="bookkeeping-item">
                        <Typography component="h3">
                            Catch-up bookkeeping
                        </Typography>
                        <Typography component="p">
                            Suits those who have not been keeping records, or were using excel or some other software to track their business transactions, but simply out grew that method.
                        </Typography>
                    </Grid>
                    <Grid item xs={3} className="bookkeeping-item">
                        <Typography component="h3">
                            Clean-up bookkeeping
                        </Typography>
                        <Typography component="p">
                            Suits those who manage their own books, but just want to make sure they are reporting things correctly.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={10} className="representation-container">
                <Grid item xs={10}>
                    <Typography component="h2" mb={2}>
                        Representation
                    </Typography>
                    <Typography component="p">
                        We will represent you during an audit and resolve any notices that you may have received from the IRS or any other jurisdiction.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={10} mb={10}>
                <Grid item xs={10} mb={3}>
                    <Typography component="h2" mb={1}>
                        QuickBooks provider
                    </Typography>
                    <Typography component="p">
                        We can actually provide QuickBooks to you at a discount as opposed to you getting it
                        from them directly. Our rates depend on the plan you choose and all the taxes are already included:
                    </Typography>
                </Grid>
                <PricingGrid />
            </Grid>
            <FreeQuoteContainer />


        </Box>
    )
}

export default OtherServices;