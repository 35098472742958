import React, {useState} from "react";
import { Link } from "react-router-dom";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import PersonalTaxes from "./PersonalTaxes";
import BusinessTaxes from "./BusinessTaxes";
import FreeQuoteContainer from "./FreeQuoteComponent";

import useScrollToTop from "../../hooks/useScrollToTop"

import '../../styles/services.css'

const TaxPreparation = () => {
    useScrollToTop();
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Box id="tax-preparation-page" mb={10}>
                <Grid container mt={5} mb={7}>
				    <Grid item>
					    <Typography component="h1">
						    Tax Preparation
					    </Typography>
				    </Grid>
			    </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <TabContext value={value}>
                            <Box sx={{ width: '100%', position: 'relative', zIndex: 999 }}>
                                <TabList
                                    onChange={handleChange}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                >
                                    <Tab className="tab-button" value="1" label="Personal Taxes"/>
                                    <Tab className="tab-button" value="2" label="Business Taxes"/>
                                    {/* <Tab value="3" label="Documents"/> */}
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <PersonalTaxes />
                            </TabPanel>
                            <TabPanel value="2">
                                <BusinessTaxes />
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
                <FreeQuoteContainer />
            </Box>
    </>
    )
}

export default TaxPreparation;