import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import DoneIcon from '@mui/icons-material/Done';
import WorkIcon from '@mui/icons-material/Work';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import GeneralBusinessEngagementLetter from '../general-application-components/GeneralBusinessEngagementLetter';
import GeneralBusinessForm from '../general-application-components/GeneralBusinessForm';
import GeneralSuccessApplicationMessage from '../general-application-components/GeneralSuccessApplicationMessage';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <EmailIcon />,
        2: <WorkIcon />,
        3: <DoneIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['Engagement Letter', 'Business Information', 'Done'];

const GeneralBusinessApplication = () => {

    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        files: null,
        businesses: [{}],
    });
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const { userInfo, userToken } = useSelector((state) => state.user)
    const navigate = useNavigate();

    const handleFileData = (binaryData) => {
        setFormData((prevData) => ({ ...prevData, files: binaryData }));
    };

    const handleSubmit = async () => {
        setLoading(true)

        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type' : 'multipart/form-data'
            }
        }
       
        const data = new FormData();
    
        data.append('file', formData.files);
        data.append('businesses', JSON.stringify(formData.businesses));
    
        try {
            const response = await axios.post('/api/client/business-application', data, config);
    
            if (response.status === 200) {
                setStep(step + 1);
                setFormData({
                    files: null,
                    businesses: [{}],
                });
                setSubmitError('');
            } else {
                console.error('Error:', response.statusText);
                setSubmitError('Could not save a business to your account. Please, try again later.')
            }
        } catch (error) {
            console.error('There was an error!', error);
        } finally {
            setLoading(false)
        }
    };

    const skipBusinessApplication = async () => {

        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            }
        }

        try {
            setLoading(true);
            const response = await axios.post('api/c/dashboard/business/decline', { view: 2 }, config);
            if(response.data && response.data.message === 'Success.') {
                navigate('/dashboard')
            }
        } catch (err) {
            setSubmitError('Failed to add business.');
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box id="business-application-wrapper">
            <Grid container flexDirection="column" sx={{ width: '100%' }} spacing={4}>
                <Grid item>
                    <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                {step !== 2 && (
                     <Grid item container justifyContent="flex-end" xs={11}>
                        <Grid item>
                            <Button className='cancel-business-application' onClick={skipBusinessApplication}>
                                Cancel Application
                            </Button>
                        </Grid>
                    </Grid>

                )}
               
                <Grid item>
                {step === 0 && (
                        <GeneralBusinessEngagementLetter 
                            setStep={setStep}
                            handleFileData={handleFileData}
                        />
                    )}
                
                    {step === 1 && (
                        <GeneralBusinessForm 
                            setStep={setStep}
                            formData={formData}
                            setFormData={setFormData}
                            handleSubmit={handleSubmit}
                            loading={loading}
                            submitError={submitError}
                        />
                    )}
                    {step === 2 && (
                        <GeneralSuccessApplicationMessage />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default GeneralBusinessApplication;