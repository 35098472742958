import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SuccessIcon from "../../../../assets/icons/check.png"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const returns = ['1040', '1040-X', '1120', '1120-X', '1120-S', '1041', '990', '709', '706', '1065', '1065-X', 'other']

const AddReturnType = ({open, setNewOpen, userToken, id, currentReturnType, setCurrentReturnType, userType}) => {

	const [updated, setUpdated] = useState(false);
	const [error, setError] = useState(''); 
	const [returnTypes, setReturnTypes] = useState(
        currentReturnType.length > 0 
            ? currentReturnType.map(returnType => {
                if(returns.includes(returnType.taxForm)) {
                    return { returnType: returnType.taxForm, otherReturnType: '' }
                } else {
                    return { returnType: "other", otherReturnType: returnType.taxForm }
                }
            }) 
            : [{ returnType: '', otherReturnType: '' }]
    );


	const handleInputChange = (e, index) => {
		e.stopPropagation();
		const { name, value } = e.target;
		const list = [...returnTypes];
		list[index][name] = value;
		setReturnTypes(list);
	};

	const handleRemoveClick = index => {
		const list = [...returnTypes];
		list.splice(index, 1);
		setReturnTypes(list);
	};

	const handleAddClick = (e) => {
		e.stopPropagation();
		setReturnTypes([...returnTypes, { returnType: '', otherReturnType: '' }]);
	};

	const addReturn = async (data) => {
		const config = {
		  	headers: {
				Authorization: `Bearer ${userToken}`,
		  	}
		};
	  
		try {
		  const response = await axios.post(`/api/admin/client/return/${id}`, data, config);
		  const { error, data: responseData } = response.data;
	  
		  if (error) {
				setError(error);
				setUpdated(false);
		  } else {
				setError(false);
				setUpdated(true);
				setCurrentReturnType(responseData)
		 	}
		} catch (e) {
		  	setError(e.response?.data?.error);
		}
	};

	const submitForm = (e) => {
		e.preventDefault();
		e.stopPropagation();
		addReturn({returnTypes, user_type: userType});
		setNewOpen(true);
	};

	const handleClose = (e) => {
		e.stopPropagation();
		setNewOpen(false);
		setReturnTypes(
			currentReturnType.length > 0 
				? currentReturnType.map(returnType => {
					if(returns.includes(returnType.taxForm)) {
						return { returnType: returnType.taxForm, otherReturnType: '' }
					} else {
						return { returnType: "other", otherReturnType: returnType.taxForm }
					}
				})
				: [{ returnType: '', otherReturnType: '' }]
		);
		setError(null);
		setUpdated(false);
	};

	return (
		<Dialog
			aria-labelledby="add-new-return-modal"
			aria-describedby="add-new-return-modal"
			id="admin-return-dialog"
			open={open}
			// onClose={handleClose}
			fullWidth
		>
			<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
				<Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header" mb={3}>
					<Grid item>
						<Chip color="success" label={currentReturnType ? "Update Tax Return" : "Add Tax Return"} />
					</Grid>
					<Grid item>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
				{!updated ? (
					<>
						{error && <Typography className='dialog-error-msg' color="secondary" variant='body1' textAlign="center">{error}</Typography>}
						<Box id='client-tax-return' component="form">
							{returnTypes.map((x, i) => {
  								return (
    								<Grid container mb={3} key={i}>
										<Grid item xs={11} container>
											<Grid item xs={12} mb={1}>
												<FormControl fullWidth required>
													<InputLabel id="return-type-select-label">Return Type</InputLabel>
													<Select
														name="returnType"
														value={x.returnType}
														fullWidth
														size="small"
														label="Return Type"
														onChange={e => handleInputChange(e, i)}
														onClose={(e) => e.stopPropagation()}
													>
														{returns.map(option => (
															<MenuItem value={option} key={option+10}>
																{option}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											{(x.returnType === 'other')&& (
												<Grid item xs={12} mb={2}>
													<TextField
													label="Other Return Type"
													name="otherReturnType"
													fullWidth
													size="small"
													value={x.otherReturnType}
													onChange={e => handleInputChange(e, i)}
													onClick={(e) => e.stopPropagation()}
													/>
												</Grid>
											)}
										</Grid>
										{returnTypes.length > 1 && i !== 0 && 
											<Grid item xs={1}>
												<IconButton
													color="error"
													onClick={(e) => {
														e.stopPropagation();
														handleRemoveClick(i);
													}}
												>
														<HighlightOffIcon />
												</IconButton>
											</Grid>
										}
										{returnTypes.length - 1 === i && 
											<Grid item xs={12}>
												<IconButton onClick={handleAddClick}>
													<AddCircleOutlineIcon />
												</IconButton>
											</Grid>
										}
									</Grid>
  								);
							})}
							<Grid item xs={12} container justifyContent="flex-end" mt={2}>
								<Grid item>
									<Button className='submit-return-btn' type="button" onClick={submitForm}>Add</Button>
								</Grid>
							</Grid>
						</Box>
					</>
				) : (
					<Box className='success-msg'>
						<Grid container flexDirection="column" alignItems="center" justifyContent="center">
							<Grid item mb={2}>
								<img src={SuccessIcon} />
							</Grid>
							<Grid item>
								<Typography>
									Success! Tax Return data has been saved.
								</Typography>
							</Grid>
						</Grid>
                    </Box>
				)}
			</Container>
		</Dialog>
	)
};

export default AddReturnType;