import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const MergedClientClosedMenu = ({ handleDrawer, id, userInfo, clientMenu, checkIsActive }) => {

    return (
        <>
            <DrawerHeader>
                <IconButton onClick={handleDrawer}>
                    <ChevronRightIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List className="list-closed">
            {clientMenu.map((item, index) => (
                    <ListItem sx={{ display: 'block' }}>
                        <Tooltip title={item.menuItem} placement="right-start">
                            <ListItemButton 
                                component={Link} 
                                to={`${item.path}/${id}`}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'center',
                                    px: 2.5,
                                    pr: "10px",
                                    pl: "10px"
                                }}
                                className={`list-group-item ${checkIsActive(item.path) ? 'active' : ''}`}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: '0',
                                        justifyContent: 'center',
                                    }}
                                >
                                     {item.menuIcon}
                                </ListItemIcon>
                            </ListItemButton>
                        </Tooltip>
                    </ListItem>
                ))}
            </List>
        </>
    )
};


export default MergedClientClosedMenu;