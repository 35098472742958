import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios'

import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import AccountImg from '../../assets/auth/account1.png';

import { useFetchData } from '../../hooks/useFetchData';

import './auth.css';

const currentYear = new Date(Date.now()).getFullYear();

const ClientAccountOptions = () => {

    const { userInfo, userToken } = useSelector((state) => state.user);
    const [loading, data, error] = useFetchData('/api/c/dashboard/client-accounts', userToken);

    const navigate = useNavigate();

    // useEffect(() => {
	// 	if(userInfo) {
	// 		console.log(userInfo)
	// 	}
	// }, [userInfo])

    const mergedAccountRedirect = (user, currentYear, id) => {
        let route = "";
    
        if (user.mergedAccount === 2 && user.activeMergedAccountId === id) {
            route = user.completedYearFromMergedAccount !== currentYear ? "/merged-client-application" : "/dashboard/merge/my-account";
        } else if (user.mergedAccount === 2) {
            route = "/dashboard/merge/my-account";
        } else if (user.mergedAccount === 3) {
            route = "/dashboard/merge/my-account";
        }
    
        // Append the id only if the route is "/dashboard/merge/my-account"
        if (route === "/dashboard/merge/my-account" && id) {
            route = `${route}/${id}`;
        }
    
        return route;
    };
      
    const individualAccountRedirect = (user, currentYear) => {
        if (user.mergedAccount === 3) {

            if (user.completedYear !== currentYear) {
                return "/profile";
            }

            return "/dashboard";

        } else if (user.mergedAccount === 2) {

          return "/dashboard";

        }
    };

    const handleMergedAccountClick = (id) => {
        const redirectTo = mergedAccountRedirect(userInfo, currentYear, id);
        navigate(redirectTo);
    };
      
      const handleIndividualAccountClick = () => {
        const redirectTo = individualAccountRedirect(userInfo, currentYear);
        navigate(redirectTo);
    };

    return (
        <>
            <Box id="accounts-screen">
                <Grid container className="grid-container" justifyContent="space-around">
                    <Grid item className="left-container">
                        <img src={AccountImg} />
                    </Grid>
                    <Grid item className="left-container">
                        <Box className='choose-account-wrapper'>
                            <Grid container>
                                <Grid item>
                                    <Typography variant="h1">
                                        Choose Account
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className='btn-wrapper' spacing={2}>
                                <Grid item xs={12}>
                                    <Box onClick={handleIndividualAccountClick} className='account-btn'>
                                        <Grid container alignItems="center" justifyContent="space-between" className="account-option">
                                            <Grid item>
                                                <Typography component="h6">
                                                    Personal Account
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <ChevronRightIcon />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                {data && data.mergedAccounts.length > 0 
                                && [...data.mergedAccounts].sort((a, b) => b.active - a.active) // Sorting, active ones come first
                                .map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Box onClick={() => handleMergedAccountClick(item.id)} className='account-btn'>
                                            <Grid container alignItems="center" justifyContent="space-between" className="account-option">
                                                <Grid item>
                                                    <Typography component="h6">
                                                        {item.user1Name} & {item.user2Name} | {item.active ? 'Active' : 'Inactive'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <ChevronRightIcon />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>  
            </Box>
        </>
    )
}

export default ClientAccountOptions;