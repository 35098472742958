import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import ErrorImg from '../../../assets/icons/error.png'


import { useFetchData } from '../../../hooks/useFetchData';

import MergeAccountBusinesses from './MergeAccountBusinesses';
import DialogSuccessMsg from '../../ui/DialogSuccessMsg';

import './mergeAcc.css'

const MergeAccounts = ({ userToken }) => {

    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/admin/merge/clients', userToken, childChanges);
    const [clients, setClients] = useState([]);
    const [businesses, setBusinesses] = useState([])
    const [selectedClient1, setSelectedClient1] = useState(null);
    const [selectedClient2, setSelectedClient2] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [submittedData, setSubmittedData] = useState(false);


    useEffect(() => {
        if(data) {
            setClients(data)
        }
    }, [data])

    // useEffect(() => {
    //     if(data) {
    //         console.log(data)
    //     }
    // }, [data])

    // useEffect(() => {
    //     if(selectedClient2) {
    //         console.log(selectedClient2)
    //     }
    // }, [selectedClient2])

    const checkForMergedAccount = () => {
        if (selectedClient1 && selectedClient2) {
            return selectedClient1.otherUserIds.includes(selectedClient2.id) || 
            selectedClient2.otherUserIds.includes(selectedClient2.id);
        }
        return false;
    };

    const options = clients.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option
        }
    });

    const handleClient1Change = (event, value) => {
        setSelectedClient1(value);
        updateBusinesses(value, selectedClient2);
    };

    const handleClient2Change = (event, value) => {
        setSelectedClient2(value);
        updateBusinesses(selectedClient1, value);
    };

    const updateBusinesses = (client1, client2) => {
        let combinedBusinesses = [];

        if (client1 && client1.businesses) {
            combinedBusinesses = combinedBusinesses.concat(client1.businesses);
        }

        if (client2 && client2.businesses) {
            combinedBusinesses = combinedBusinesses.concat(client2.businesses);
        }

        const uniqueBusinesses = combinedBusinesses.filter((business, index, self) => 
            index === self.findIndex((b) => b._id === business._id)
        );
        setBusinesses(uniqueBusinesses);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoadingSubmit(true);
        const mergedData = {
            user1: {
                name: selectedClient1.name,
                email: selectedClient1.email,
                userId: selectedClient1.id 
            },
            user2: {
                name: selectedClient2.name,
                email: selectedClient2.email,
                userId: selectedClient2.id 
            },
        };

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                '/api/admin/merge/clients',
                mergedData,
                config
            );
            if (response.data.error) {
                setErrorMsg(response.data.error);
            } else {
                setSubmittedData(true);
                setErrorMsg('');
                setSelectedClient1(null);
                setSelectedClient2(null);
                setChildChanges((prevChanges) => !prevChanges);
            }
        } catch (error) {
            console.log(error)
            if (error && error.response && error.response.data && error.response.data.error) {
                setErrorMsg(error.response.data.error)
            } else {
                setErrorMsg('Something went wrong. Please, try again later.')
            }
        } finally {
            setLoadingSubmit(false);
        }
    };

    const handleClose = () => {
        setSubmittedData(false)
    }

    const handleCloseError = () => {
        setErrorMsg('')
    }

    const isOptionEqualToValue = (option, value) => option.id === value?.id;

    const getOptionDisabled = (option) =>
    option.id === selectedClient1?.id || option.id === selectedClient2?.id;

    return (
        <>
            <Box>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item container xs={6} className="clients-container">
                            <Grid item xs={12}>
                                <Typography component="h2">Choose Clients</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete 
                                    id="client1"
                                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.name}
                                    sx={{ width: 500 }}
                                    size="small"
                                    disabled={loading}
                                    onChange={handleClient1Change}
                                    value={selectedClient1}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    getOptionDisabled={getOptionDisabled}
                                    renderOption={(props, option, { inputValue, selected }) => (
                                        <Box {...props}>
                                            <Typography component="p" className='merge-client-name'>{option.name}</Typography>
                                            <Typography component="p" className='merge-client-email'>{option.email}</Typography>
                                        </Box>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={loading ? "Loading..." : "Choose client"} fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete 
                                    id="client2"
                                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.name}
                                    sx={{ width: 500 }}
                                    size="small"
                                    disabled={!selectedClient1}
                                    value={selectedClient2}
                                    onChange={handleClient2Change}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    getOptionDisabled={getOptionDisabled}
                                    renderOption={(props, option, { inputValue, selected }) => (
                                        <Box {...props}>
                                            <Typography component="p" className='merge-client-name'>{option.name}</Typography>
                                            <Typography component="p" className='merge-client-email'>{option.email}</Typography>
                                        </Box>
                                    )}
                                    renderInput={(params) => <TextField {...params} label={loading ? "Loading..." : "Choose client"} fullWidth />}
                                />
                            </Grid>
                            <Grid item sx={{ width: 500 }} container className='merge-btn-container' justifyContent="end">
                                <Grid item>
                                    <Button 
                                        type="submit" 
                                        className='merge-acc-btn'
                                        disabled={loadingSubmit}
                                    >
                                        {loadingSubmit ? (
                                            <>
                                                Submit&nbsp;
                                                <CircularProgress size={16} color="inherit" />
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                            {
    checkForMergedAccount() && 
    <div className="warning-message">
        These clients already have a merged account. A new account will not be created but the old one will be restored.
    </div>
}
                            {selectedClient1 && (
                                <Grid item container className='spouse-info' sx={{ width: 500 }}>
                                    <Grid item xs={12}>
                                        <Typography component="h4">{selectedClient1.name}'s Spouse Information:</Typography>
                                    </Grid>
                                    <Grid item xs={12} className='item'>
                                        <Typography component="h5">Email:</Typography>
                                        <Typography component="p">{selectedClient1.spouseEmail ? selectedClient1.spouseEmail : 'Not Provided'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className='item'>
                                        <Typography component="h5">Name:</Typography>
                                        <Typography component="p">{selectedClient1.spouseName ? selectedClient1.spouseName : 'Not Provided'}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {selectedClient2 && (
                                <Grid item container className='spouse-info' sx={{ width: 500 }}>
                                    <Grid item xs={12}>
                                        <Typography component="h4">{selectedClient2.name}'s Spouse Information:</Typography>
                                    </Grid>
                                    <Grid item xs={12} className='item'>
                                        <Typography component="h5">Email:</Typography>
                                        <Typography component="p">{selectedClient2.spouseEmail ? selectedClient2.spouseEmail : 'Not Provided'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className='item'>
                                        <Typography component="h5">Name:</Typography>
                                        <Typography component="p">{selectedClient2.spouseName ? selectedClient2.spouseName : 'Not Provided'}</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {selectedClient1 && selectedClient2 && businesses.length > 0 && (
                            <Grid item xs={6} container className='merge-businesses'>
                                <Grid item xs={12}>
                                    <Typography component="h2"> Client Businesses </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className='desc-msg' component="p"> To make changes for business, please proceed to "Businesses" tab</Typography>
                                </Grid>
                                <MergeAccountBusinesses 
                                    businesses={businesses}
                                    selectedClient1={selectedClient1}
                                    selectedClient2={selectedClient2}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            {submittedData && (
                <Dialog
                    aria-labelledby="success-merge-accounts"
        	        aria-describedby="success-merge-accounts"
				    id='success-merge-accounts'
        	        open={!!submittedData}
			        onClose={handleClose}
                    fullWidth
				    maxWidth="sm"
                >
                    <Grid container justifyContent="flex-end">
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
                    <Container className="success-merge-accounts" sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                        <DialogSuccessMsg msg="Accounts merged successfully"/>
                    </Container>
                </Dialog>
            )}
            {errorMsg && (
                <Dialog
                aria-labelledby="success-merge-accounts"
                aria-describedby="success-merge-accounts"
                id='success-merge-accounts'
                open={!!errorMsg}
                onClose={handleCloseError}
                fullWidth
                maxWidth="sm"
            >
                <Grid container justifyContent="flex-end" px={2} py={1}>
                    <Grid item>
                        <IconButton onClick={handleCloseError}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Container className="success-merge-accounts" sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                    <Grid container justifyContent="center" mb={2}>
                        <Grid item>
                            <img src={ErrorImg}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Typography component="p" sx={{color: '#333'}}>
                                These accounts cannot be merged.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="p" sx={{color: '#333'}}>
                                {errorMsg}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            )}
        </>
    )
}

export default MergeAccounts;
