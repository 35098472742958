import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


const PersonalInfo = ({ clientInfo }) => {

    const handleEmailClick = (event) => {
        event.stopPropagation();
    };
    
    return (
        <>
            {clientInfo.user_type === 1 ? (
                <Box className="section-content individual-client">
                    <div className="field">
                        <label htmlFor="firstName">First Name:</label>
                        <span id="firstName" style={{textTransform: 'capitalize'}}>{clientInfo.firstName}</span>
                    </div>
                    <div className="field">
                        <label htmlFor="lastName">Last Name:</label>
                        <span id="lastName" style={{textTransform: 'capitalize'}}>{clientInfo.lastName}</span>
                    </div>
                    <div className="field">
                        <label htmlFor="email">Email:</label>
                        <span id="email">
                            <a href={`mailto:${clientInfo.email}`} onClick={handleEmailClick}>{clientInfo.email}</a>
                        </span>
                    </div>
                    <div className="field">
                        <label htmlFor="phone">Phone Number:</label>
                        <span id="phone">{clientInfo.phone}</span>
                    </div>
                    {(clientInfo.mergedAccount === 1 && clientInfo.maritalDetails.spouseEmail) && (
                         <div className="field">
                            <label htmlFor="phone">Spouse's Email for merge:</label>
                            <span id="phone">{clientInfo.maritalDetails.spouseEmail}</span>
                        </div>
                    )}
                </Box>
            ) : (
                <Grid container>
                    <Grid item xs={6}>
                        <Box className="section-content">
                            <div className="field">
                                <label htmlFor="firstName">First Name:</label>
                                <span id="firstName">{clientInfo.user1.firstName}</span>
                            </div>
                            <div className="field">
                                <label htmlFor="lastName">Last Name:</label>
                                <span id="lastName">{clientInfo.user1.lastName}</span>
                            </div>
                            <div className="field">
                                <label htmlFor="email">Email:</label>
                                <span id="email">
                                    <a href={`mailto:${clientInfo.user1.email}`} onClick={handleEmailClick}>{clientInfo.user1.email}</a>
                                </span>
                            </div>
                            <div className="field">
                                <label htmlFor="phone">Phone Number:</label>
                                <span id="phone">{clientInfo.user1.phone}</span>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="section-content">
                            <div className="field">
                                <label htmlFor="firstName">First Name:</label>
                                <span id="firstName">{clientInfo.user2.firstName}</span>
                            </div>
                            <div className="field">
                                <label htmlFor="lastName">Last Name:</label>
                                <span id="lastName">{clientInfo.user2.lastName}</span>
                            </div>
                            <div className="field">
                                <label htmlFor="email">Email:</label>
                                <span id="email">
                                    <a href={`mailto:${clientInfo.user2.email}`} onClick={handleEmailClick}>{clientInfo.user2.email}</a>
                                </span>
                            </div>
                            <div className="field">
                                <label htmlFor="phone">Phone Number:</label>
                                <span id="phone">{clientInfo.user2.phone}</span>
                            </div>
                        </Box>
                    </Grid>
                </Grid>

            )}
        </>
    )
}

export default PersonalInfo;