import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';


import Loading from '../../ui/Loading';
import PaginationControlled from '../../ui/Pagination';
import { useFetchData } from '../../../hooks/useFetchData';
import { useYearsFilter } from '../../../hooks/useYearsFilter';
import QueViewer from './QuestionnaireDialog';
import EmptyDataComponent from '../../ui/EmptyDataComponent';
// import DocView from './DocViewer';

import QueIcon from '../../../assets/icons/clipboard.png'


const GeneralQuestionnaireView = () => {

    const { userToken } = useSelector((state) => state.user);
    const location = useLocation();
    const currentPath = location.pathname;
    const endpoint = currentPath.startsWith('/dashboard/history')
    ? '/api/c/dashboard/ques?userType=1'
    : '/api/c/dashboard/ques?userType=2';
    const [loading, data, error] = useFetchData(endpoint, userToken);
    const {
        years,
        selectedYear,
        handleSelectChange,
        filteredList,
    } = useYearsFilter(data);
    const [open, setOpen] = useState(false);
    const [currentQue, setCurrentQue] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(9);

    const setActive= (que, index) => {
        setCurrentQue(que);
        setOpen(true);
        // setCurrentIndex(index);
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredList ? filteredList.slice(indexOfFirstRecord, indexOfLastRecord) : [];
    const nPages = filteredList ? Math.ceil(filteredList.length / recordsPerPage) : 0;



    return(
        <>
            <Box id="questionnaire-client-wrapper">
                <Grid container mb={loading ? 3 : 0}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant='h1'>
                            Questionnaires
                        </Typography>
                    </Grid>
                </Grid>
                <Box>
                    {loading && (
                        <Skeleton mt={3} variant="rounded" width={326} height={116} animation="wave" />
                    )}
                    {error && (
                        <Typography component="h2" variant="h2">
                            An error occurred.
                        </Typography>
                    )}
                    {data && data.length === 0 && (
                        <EmptyDataComponent msg="Looks like you haven't filled out any questionnaires yet. Completed questionnaires will be displayed in this section." />
                    )}
                    {currentRecords && currentRecords.length > 0 && (
                        <>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedYear}
                                                label="Year"
                                                onChange={handleSelectChange}
                                                size='small'
                                            >
                                                {years ? (
                                                    years
                                                    .sort((a, b) => a + b)
                                                    .map((item, i) => (
                                                        <MenuItem key={i} value={item}>{item}</MenuItem>
                                                    ))
                                                ) : null}
                                            </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container columnGap={3} rowGap={2} className='questionnaire-items-container'>
                                {currentRecords.map((item, i) => (
                                    <Grid
                                        item
                                        key={i}
                                        xs={3}
                                        className="questionnaire-item"
                                        onClick={() => setActive(item, i)}
                                    >
                                        <Box>
                                            <img src={QueIcon} />

                                        </Box>
                                        <Box className='content-box'>
                                            <Typography>
                                                {`Tax Questionnaire ${item.taxYear}`}
                                            </Typography>
                                            <Button>
                                                View
                                            </Button>
                                        </Box>   
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid container justifyContent="center" mt={6}>
                                <Grid item>
                                    <PaginationControlled
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage} 
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Box>
                <QueViewer 
                    open={open}
                    setOpen={setOpen}
                    currentQue={currentQue}
                />
            </Box>
        </>

    )
}

export default GeneralQuestionnaireView;