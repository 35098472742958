import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';

const CopyFiles = ({ open, setOpen, setChildChanges, selectedFileNames, selectedFiles, setSelectedFileNames, setSelectedFiles }) => {

    const { userToken } = useSelector((state) => state.user);
    const [isUpdated, setIsUpdated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState('');
    const [yearError, setYearError] = useState('')
    const modalRef = useRef();

    // useEffect(() => {
    //     if(year) {
    //         console.log(year)
    //     }
    // }, [year])

	const handleClose = () => {
        if(isUpdated) {
            setChildChanges((prevChanges) => !prevChanges);
            setSelectedFiles([]);
            setSelectedFileNames([])
        }
		setOpen(false);
        setIsUpdated(false);
        setError('');
        setYear('');
        setYearError('');
	}

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,4}$/.test(value)) {
            setYear(value);
        }
    };

    useEffect(() => {
        if (year.length > 0 && year.length !== 4) {
            setYearError('Year must be a 4-digit number');
        } else {
            setYearError('');
        }
    }, [year]);

    const postData = async (e) => {
		e.preventDefault();
		setLoading(true);
		const unanswered = [];

        if(!yearError) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                };
                const data = {
                    files: selectedFiles,
                    year: year
                };
                // console.log(data)
                const response = await axios.post(
                    '/api/client-page/files/duplicate',
                    data,
                    config
                );
                if (response.data.error) {
                    setError(response.data.error);
                } else {
                    setIsUpdated(true);
                    setError('');
                }
            } catch (error) {
                console.log('Error')
                setError('Error')
            }
        } else {
            // markUnansweredQuestions();
            setError('Please, provide answers for all questions')
        }
		setLoading(false);
	};

	return (
		<Dialog
			aria-labelledby="admin-doc-edit-modal"
        	aria-describedby="admin-doc-edit-modal"
            id="admin-doc-edit-modal"
        	open={open}
			onClose={handleClose}
            fullWidth
            maxWidth="md"
		>
			<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }} ref={modalRef}>
                <Grid container justifyContent="space-between" alignItems="center" className="edit-dialog-header">
                    <Grid item>
                        <Chip color="warning" label="Duplicate Mode" />
                    </Grid>
					<Grid item>
						<IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
					</Grid>
				</Grid>
                <Grid container justifyContent="center"> 
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                color: '#333',
                                fontWeight: '500',
                                fontSize: '1.2rem',
                                textAlign: 'center'
                            }}
                        >
                            Duplicate selected files for a different year
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontFamily: 'Poppins',
                                color: '#777',
                                fontWeight: '400',
                                fontSize: '1rem',
                                textAlign: 'center'
                            }}
                        >
                            This action will create exact copies of the selected files in the designated year's folder. 
                        </Typography>
                    </Grid>

                </Grid>
                {error && <Typography className='dialog-error-msg'>Could not update the file information. Please try again later.</Typography>}
                {isUpdated ? (
                    <Box className="admin-doc-modal">
                        <DialogSuccessMsg msg="Changes saved successfully." />
                    </Box>
                ) : (
                    <>
                        <Grid container sx={{width: '90%', margin: '2rem auto'}}>
                            {selectedFileNames.map((item, index) => (
                                <Grid item xs={12} key={index} mb={1}>
                                    <Typography
                                        sx={{
                                            fontSize: '1.1rem',
                                            fontFamily: 'Poppins',
                                            color: '#333'
                                        }}
                                    >
                                        {index + 1}. {item.file_name}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                        <Box component="form" onSubmit={postData} sx={{width: '90%', margin: '0 auto'}}>
                            <Grid container mb={5}>
                                <Grid item xs={5} mb={2}>
                                    <TextField 
                                        label="Year" 
                                        fullWidth 
                                        required 
                                        size="small"
                                        value={year}
                                        name="year" 
                                        onChange={handleChange} 
                                        error={yearError}
                                        helperText={yearError}
                                    />   
                                </Grid> 
                                <Grid item sx={{width: '90%', margin: '0 auto'}} container justifyContent="flex-end">
                                    <Grid item>
                                        <Button 
                                            className='submit-button' 
                                            type="submit"
                                            disabled={loading || !!yearError}
                                        >
                                            {loading ? (
                                                <>
                                                    Save&nbsp;
                                                    <CircularProgress size={18} color="inherit" />
                                                </>
                                            ) : (
                                                'Save'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
			</Container>
		</Dialog>
	)
};

export default CopyFiles;