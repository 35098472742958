import axios from 'axios';
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAdminClientDetails = createAsyncThunk(
    'adminClient/fetchDetails',
    async ({ clientId, userType }, { getState, rejectWithValue }) => {
      try {
        const { user } = getState();
        const endpoint = userType === 1 
                        ? `/api/client-page/client/${clientId}` 
                        : `/api/client-page/merged-client/${clientId}`;
        const response = await axios.get(endpoint, {
          headers: { Authorization: `Bearer ${user.userToken}` }
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response ? error.response.data : error.message);
      }
    }
);