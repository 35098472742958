import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Autocomplete from '@mui/material/Autocomplete';

import { refundsTypes } from '../../../../utils/refunds';

// const refunds = ['Federal', 'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', ' District of Columbia', 'Florida', 'Georgia', 'Hawaii']

const TaxRefundFields = ({ handleChange, taxRefunds, setTaxRefunds, index, errors, updateFieldError }) => {
    const [formData, setFormData] = useState({
        jurisdiction: null,
        link: '',
        taxYear: '',
        amount: '',
    });

    const validateLink = (link) => {
        const URLPattern = new RegExp(/^(https?:\/\/)?(([\da-z.-]+)\.([a-z.]{2,6})|([\d.]+))([\/\w.-]*)*\/?$/);
        return URLPattern.test(link);
    };
    
    const validateAmount = (amount) => {
        const amountPattern = /^-?\d+(.\d+)?$/;
        return amountPattern.test(amount);
    };
    
    const validateTaxYear = (taxYear) => {
        const yearPattern = /^\d{4}$/;
        return yearPattern.test(taxYear);
    };

    const handleFormChange = (event, value) => {
        let fieldError = '';

        if (event?.target?.name) {
            const { name, value } = event.target;

            switch(name) {
                case 'link':
                    if (!validateLink(value)) {
                        fieldError = 'Invalid URL format';
                    }
                    break;
                case 'amount':
                    if (!validateAmount(value)) {
                        fieldError = 'Not a valid amount';
                    }
                    break;
                case 'taxYear':
                    if (!validateTaxYear(value)) {
                        fieldError = 'Enter a 4-digit year';
                    }
                    break;
                default:
                    break;
            }
            
            // Update the error for this specific field using the parent's function.
            updateFieldError(name, fieldError, index); 
            
            setFormData(prevData => ({ ...prevData, [name]: value }));
            handleChange({ [name]: value }, index);
        } 
        // The else part is untouched as it seems fine.
        else { 
            const selectedOption = refundsTypes.find(opt => opt?.longName === value?.longName || null);
            if (selectedOption) {
                setFormData(prevData => ({ ...prevData, jurisdiction: selectedOption.longName }));
                handleChange({ jurisdiction: selectedOption.longName }, index);
            }
        }
    };

    const handleRemoveClick = () => { 
        const updatedTaxRefunds = [...taxRefunds];
        updatedTaxRefunds.splice(index, 1);
        setTaxRefunds(updatedTaxRefunds);
    };

    return (
        <Grid container spacing={2} alignItems="center" mb={2}>
            <Grid item xs={3}>
                <Autocomplete 
                    id="jurisdiction"
                    options={refundsTypes}
                    getOptionLabel={(option) => option.longName}
                    groupBy={(option) => option.firstLetter}
                    isOptionEqualToValue={(option, value) => option.longName === value.longName}
                    size="small"
                    onChange={handleFormChange}
                    value={refundsTypes.find(opt => opt.longName === formData.jurisdiction) || null}
                    renderInput={(params) => <TextField {...params} label="Jurisdiction" fullWidth />}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="link"
                    label="Link"
                    variant="outlined"
                    value={formData.link}
                    onChange={handleFormChange}
                    fullWidth
                    size="small"
                    error={!!errors.link}
                    helperText={errors.link}
                />
            </Grid>
            <Grid item xs={2}>
            <TextField
                label="Amount"
                name="amount"
                value={formData.amount}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                variant="outlined"
                onChange={handleFormChange}
                fullWidth
                size="small"
                required
                error={!!errors.amount}
                helperText={errors.amount}
            />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    name="taxYear"
                    label="Tax Year"
                    variant="outlined"
                    value={formData.taxYear}
                    onChange={handleFormChange}
                    fullWidth
                    size="small"
                    required
                    error={!!errors.taxYear}
                    helperText={errors.taxYear}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={handleRemoveClick}>
                    <HighlightOffIcon color="error" />
                </IconButton>
            </Grid>
        </Grid>
    )
};

export default TaxRefundFields;