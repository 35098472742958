import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { useFetchData } from '../../../../hooks/useFetchData';
import Folders from '../GeneralDoc/Folders';
import ClientFiles from '../GeneralDoc/ClientFiles';

const ClientDocs = ({ mergedAccount }) => {

    const { userToken } = useSelector((state) => state.user);
    const location = useLocation();
    const { id } = useParams();
    const currentPath = location.pathname;
    const endpoint = currentPath.startsWith('/admin/merge/client-page/')
    ? `/api/client-page/personal/documents/${id}?userType=2`
    : `/api/client-page/personal/documents/${id}?userType=1`;
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData(endpoint, userToken, childChanges);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folders, setFolders] = useState([]);
    

    useEffect(() => {
        if (data && data.files) {
          const folderNames = data.files.map((item) => item.name);
          setFolders(folderNames);
        }
    }, [data]);

    return (
        <>
            {loading && <p>Loading...</p>}
            {data && !selectedFolder ? (
                <Folders 
                    data={data?.files} 
                    setSelectedFolder={setSelectedFolder} 
                    userToken={userToken}
                    folders={folders}
                    id={id}
                    setChildChanges={setChildChanges}
                    userType={currentPath.startsWith('/admin/merge/client-page/') ? "2" : "1"}
                    mergedAccount={mergedAccount}
                    isDocumentUploadActive={data?.userStatus}
                />
            ) : (
                <ClientFiles 
                    data={data?.files} 
                    selectedFolder={selectedFolder} 
                    setSelectedFolder={setSelectedFolder} 
                    userToken={userToken} 
                    // businesses={businesses} 
                    folders={folders}
                    setChildChanges={setChildChanges}
                />
               
            )} 
        </>
    )
}

export default ClientDocs;