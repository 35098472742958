import React, { useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import MailIcon from '@mui/icons-material/Mail';
import PeopleIcon from '@mui/icons-material/People';
import QuizIcon from '@mui/icons-material/Quiz';
import ArticleIcon from '@mui/icons-material/Article';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MergeIcon from '@mui/icons-material/Merge';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import './admin.css'

const drawerWidth = 240;

const adminMenu = [
    {
        menuItem: 'Clients List',
        menuIcon : <PeopleIcon />,
		path: '/admin-profile/clients-list'
    },
    {
        menuItem: 'Invite',
        menuIcon : <MailIcon />,
		path: '/admin-profile/invite'
    },
    {
        menuItem: 'Questionnaire',
        menuIcon : <QuizIcon />,
		path: '/admin-profile/questionnaire'
    },
    {
        menuItem: 'Engagement Letter',
        menuIcon : <ArticleIcon />,
		path: '/admin-profile/engagement-letter'
    },
	{
        menuItem: 'Folders',
        menuIcon : <FolderSpecialIcon />,
		path: '/admin-profile/folders'
    },
    {
        menuItem: 'Free Quote',
        menuIcon: <RequestQuoteIcon />,
		path: '/admin-profile/free-quote'
    },
    {
      menuItem: 'Merge/Split Accounts',
      menuIcon: <MergeIcon />,
	  path: '/admin-profile/account-integrations'
    }
]

const openedMixin = (theme) => ({
	width: drawerWidth,
   	transition: theme.transitions.create('width', {
    	easing: theme.transitions.easing.sharp,
    	duration: theme.transitions.duration.enteringScreen,
  	}),
  	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
 	transition: theme.transitions.create('width', {
   		easing: theme.transitions.easing.sharp,
    	duration: theme.transitions.duration.leavingScreen,
  	}),
  	overflowX: 'hidden',
  	width: `calc(${theme.spacing(7)} + 1px)`,
  	[theme.breakpoints.up('sm')]: {
    	width: `calc(${theme.spacing(8)} + 1px)`,
  	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AdminView() {
 	const [open, setOpen] = useState(true);
	const [currentIndex, setCurrentIndex] = useState(0);
	const location = useLocation();

	const handleDrawer = () => {
		setOpen(!open);
	};

	const setActiveQuestion = (index) => {
		setCurrentIndex(index);
	}

	useEffect(() => {
		// Find the index of the menu item that matches the current path
		const activeItemIndex = adminMenu.findIndex(item => location.pathname.includes(item.path));
		if (activeItemIndex !== -1) {
		  setCurrentIndex(activeItemIndex);
		}
	}, [location]);

  	return (
    	<Box sx={{ display: 'flex' }} id="drawer-container">
      		<Drawer variant="permanent" open={open}>
				<DrawerHeader>
					<IconButton onClick={handleDrawer}>
					{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</DrawerHeader>
        		<Divider />
				<List className={open ? "list-open" : 'list-closed'}>
					{adminMenu.map((item, index) => (
						<ListItem 
							key={item.menuItem} 
							sx={{ display: 'block' }}
							onClick={() => setActiveQuestion(index)}
						>
							{open ? (
								<Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
									<ListItemButton
										sx={{
											minHeight: 48,
											justifyContent: open ? 'initial' : 'center',
											px: 2.5,
										}}
										className={
											"list-group-item " + (index === currentIndex ? "active" : "")
										}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: open ? 3 : 'auto',
												justifyContent: 'center',
											}}
										>
											{item.menuIcon}
										</ListItemIcon>
										{item.menuItem === 'Engagement Letter' || item.menuItem === 'Merge/Split Accounts' ? (
											<Tooltip title={item.menuItem} placement="right-start">
												<ListItemText primary={item.menuItem} sx={{ opacity: open ? 1 : 0 }} />
											</Tooltip>
										) : (
											<ListItemText primary={item.menuItem} sx={{ opacity: open ? 1 : 0 }} />
										)}
									</ListItemButton>
								</Link>
							) : (
								<Tooltip title={item.menuItem} placement="right-start">
									<Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
									<ListItemButton
										sx={{
											minHeight: 48,
											justifyContent: 'center',
											px: 2.5,
										}}
										className={
											"list-group-item " + (index === currentIndex ? "active" : "")
										}
									>
										<ListItemIcon
											sx={{
												minWidth: 0,
												justifyContent: 'center',
											}}
										>
											{item.menuIcon}
										</ListItemIcon>
										<ListItemText primary={item.menuItem} sx={{ opacity: 0 }} />
									</ListItemButton>
									</Link>
								</Tooltip>
							)}
						</ListItem>
					))}
				</List>
      		</Drawer>
      		<Box component="main" sx={{ flexGrow: 1, p: 3 }} className="admin-dashboard">
			  <Outlet />
      		</Box>
    	</Box>
  	);
}