import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import EmptyDataImg from '../../assets/search-not-found.png'


const EmptyDataComponent = ({ msg }) => {

    return (
        <Box id="empty-data-box">
            <Grid container justifyContent="center">
                <Grid item>
                    <img src={EmptyDataImg} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={2}>
                <Grid item>
                    <Typography component="h2">{msg}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default EmptyDataComponent;