import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../actions/authActions';
import { logout } from '../../reducers/authReducers';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';


import '../../styles/header.css'

import logoDesktop from '../../assets/TaxBox-logo-desktop.png'
import logoMobile from '../../assets/TaxBox-logo-mobile.png'

const currentYear = new Date(Date.now()).getFullYear();

const Navbar = () => {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [clientLink, setClientLink] = React.useState('profile');
	const [mergedClientLinks, setMergedClientLinks] = useState([]);
	const [currentPageIndex, setCurrentPageIndex] = useState(0)
	const navigate = useNavigate();

	useEffect(() => {
		if(anchorElNav) {
			console.log('anchorElNav', anchorElNav)
		}
	}, [anchorElNav])


	useEffect(() => {
		if(anchorEl) {
			console.log('anchorEl', anchorEl)
		}
	}, [anchorEl])

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handlePageIndex = (index) => {
		setCurrentPageIndex(index)
	}

	const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

  	const { userInfo, userToken } = useSelector((state) => state.user)
  	const dispatch = useDispatch();

	// useEffect(() => {
	// 	if(userInfo) {
	// 		console.log(userInfo)
	// 	}
	// }, [userInfo])

	useEffect(() => {
		if(userToken) {
			dispatch(getUserDetails())
		}
	}, [userToken, dispatch])

  	useEffect(() => {
		if(userInfo && userInfo.view === 4) {
			setClientLink('profile')
		} else {
			setClientLink('dashboard')
		}
  	}, [userInfo])

	useEffect(() => {
		if (userInfo && userInfo.mergedAccounts) {
			const activeAccount = userInfo.mergedAccounts.find(account => account.active);
			const hasMultipleAccounts = userInfo.mergedAccounts.length > 1;
	
			if (hasMultipleAccounts) {
				const links = activeAccount
					? [
						{
							label: `${activeAccount.user1Name} & ${activeAccount.user2Name}`,
							link: userInfo.completedYearFromMergedAccount === currentYear
								? `/dashboard/merge/my-account/${activeAccount.id}`
								: '/merged-client-application'
						},
						{ label: 'View All Accounts', link: '/client-accounts' }
					]
					: [{ label: 'View All Accounts', link: '/client-accounts' }];
	
				setMergedClientLinks(links);
			} else if (userInfo.mergedAccounts.length === 1) {
				// Only one account, active or not
				const account = userInfo.mergedAccounts[0];
				const link = !activeAccount
				? `/dashboard/merge/my-account/${account.id}`
				: userInfo.completedYearFromMergedAccount === currentYear
					? `/dashboard/merge/my-account/${account.id}`
					: '/merged-client-application';

			setMergedClientLinks([{
				label: `${account.user1Name} & ${account.user2Name}`,
				link: link
			}]);
			}
		}
	}, [userInfo]);


  	const routes = [
		{
			name: "Home",
			link: "/",
			activeIndex: 0,
		},
		{
			name: "Services",
			link: "/services",
			activeIndex: 1,
		},
    	{ 
			name: "About Us", 
			link: "/about", 
			activeIndex: 2 
		},
    	{ 
			name: "Contact Us", 
			link: "/contact", 
			activeIndex: 3 
		}
  	];

	const handleLogOut = () => {
		dispatch(logout());
		navigate('/')
	}

  	return (
    	<>
      		<AppBar position="static">
        		<Container maxWidth="xl">
          			<Toolbar disableGutters>
						<Box component={Link} to="/" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
							<img component={Link} to="/" src={logoDesktop} className="desktop-logo" />
						</Box>
				
						{/* Mobile Menu */}
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}
							>
								{routes.map((route, index) => (
									<MenuItem key={index + 20} component={Link} to={route.link} onClick={handleCloseNavMenu}>
										<Typography textAlign="center">{route.name}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>
						{/* End of Hamburger Menu */}

						<Box component={Link} to="/" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
							<img component={Link} to="/" src={logoMobile} className="mobile-logo" />
						</Box>
						<Box sx={{ flexGrow: 0,  display: { xs: 'flex', md: 'none' } }}>
							{(userToken && userInfo && userInfo.firstName) ? (
								<>
									<Tooltip title="Open User Menu">
										<IconButton 
											variant="outlined" 
											color="primary" 
											endicon={<KeyboardArrowDownIcon />}
											onClick={handleOpenUserMenu}
										>
											<Avatar>
												{userInfo.firstName.substring(0, 1)}
											</Avatar>
										</IconButton>
									</Tooltip>
									<Menu
										sx={{ mt: '45px', display: { xs: 'flex', md: 'none' } }}
										id="menu-appbar"
										anchorEl={anchorElUser}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}
										MenuListProps={{
											onMouseLeave: handleCloseUserMenu
										}}
									>
										<MenuItem component={Link} to={userInfo.role === 'user' ? `/${clientLink}` : '/admin-profile'} onClick={handleCloseUserMenu}>
											<PersonIcon />
											<Typography textAlign="center">Dashboard</Typography>
										</MenuItem>
										<MenuItem component={Link} to={userInfo.role === 'user' ? '/settings' : '/'} onClick={handleCloseUserMenu}>
											<Typography textAlign="center">Settings</Typography>
										</MenuItem>
										<MenuItem 
											onClick={handleLogOut}
										>
											<Typography textAlign="center">Logout</Typography>
										</MenuItem>
									</Menu>
								</>	
							) : (
								<Button
									component={Link}
									to="login"
									variant='contained'
									color="secondary"
									onClick={() => props.setValue(5)}
								>
									Log In
								</Button>
							)}
						</Box>

						{/* Desktop Menu */}
						<Box className='main-menu-wrapper' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} onMouseLeave={handleMenuClose}>
							{routes.map((route, index) => (
								route.name !== "Services" ? (
									<Button
										component={Link}
										to={route.link}
										key={index}
										sx={{ my: 2, color: 'black', display: 'block' }}
										onClick={() => handlePageIndex(index)}
										className={index === currentPageIndex ? 'active' : ''}
									>
										{route.name}
									</Button>
								) : (
									<div key={index}>
										<Button
											onMouseEnter={handleMenuOpen}
											sx={{ my: 2, color: 'black', display: 'block' }}
											className={currentPageIndex === 1 ? 'active' : ''}
										>
											{route.name}
										</Button>
										<Menu
											anchorEl={anchorEl}
											open={Boolean(anchorEl)}
											onClose={handleMenuClose}
											// keepMounted
											MenuListProps={{
												onMouseLeave: handleMenuClose,
											}}
										>
											<MenuItem component={Link} to="/services/tax-preparation" onClick={handleMenuClose}>Tax Preparation</MenuItem>
											<MenuItem component={Link} to="/services/consulting" onClick={handleMenuClose}>Consulting Services</MenuItem>
											<MenuItem component={Link} to="/services/other" onClick={handleMenuClose}>Other Services</MenuItem>
										</Menu>
									</div>
								)
							))}
						</Box>
						{/* End Of Desktop Menu */}

						<Box sx={{ flexGrow: 0,  display: { xs: 'none', md: 'flex' } }}>
							{(userToken && userInfo && userInfo.firstName) ? (
								<>
									<Tooltip title="Open Menu">
										<Button 
											variant="outlined" 
											color="primary" 
											endicon={<KeyboardArrowDownIcon />}
											onClick={handleOpenUserMenu}
											className="user-button"
										>
											Hello, {userInfo.firstName}
										</Button>
									</Tooltip>
									<Menu
									sx={{ mt: '45px' }}
										id="menu-appbar"
										className='personal-menu'
										anchorEl={anchorElUser}
										anchorOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										keepMounted
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right',
										}}
										open={Boolean(anchorElUser)}
										onClose={handleCloseUserMenu}
										MenuListProps={{
											onMouseLeave: handleCloseUserMenu
										}}
									>
										<MenuItem 
											component={Link} 
											to={userInfo.role === 'user' ? `/${clientLink}` : '/admin-profile'} 
											onClick={handleCloseUserMenu}
										>
											<PersonIcon />
											<Typography textAlign="center">Main Dashboard</Typography>
										</MenuItem>
										{userInfo.mergedAccount !== 1 && mergedClientLinks.map(({ label, link }, index) => (
											<MenuItem 
												key={index}
												component={Link} 
												to={link}
												onClick={handleCloseUserMenu}
											>
												<PeopleAltIcon />
												<Typography textAlign="center">{label}</Typography>
											</MenuItem>
										))}
										<MenuItem component={Link} to={userInfo.role === 'user' ? '/settings' : '/admin/settings'} onClick={handleCloseUserMenu}>
											<SettingsIcon />
											<Typography textAlign="center">Settings</Typography>
										</MenuItem>
										<MenuItem 
										onClick={handleLogOut}
										>
											<LogoutIcon />
											<Typography textAlign="center">Logout</Typography>
										</MenuItem>
									</Menu>
								</>
							) : (
								<Button
									component={Link}
									to="login"
									variant='contained'
									color="secondary"
									onClick={() => props.setValue(5)}
								>
									Log In
								</Button>
							)}
						</Box>
					</Toolbar>
        		</Container>
      		</AppBar>
    	</>
  	)
}

export default Navbar;