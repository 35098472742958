import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { maskEin } from '../../../../../utils/maskEin';

import SuccessIcon from '../../../../../assets//icons/check.png'
import { Button } from '@mui/material';

import { fetchAdminClientDetails } from '../../../../../actions/adminClientActions';

const AddBusiness = ({ open, setOpen, userToken, id }) => {

    const dispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [newBusiness, setNewBusiness] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = (event) => {
        event.stopPropagation();
        setOpen(false);
        setNewBusiness({});
        setError('');
        setLoading(false);
        setIsSubmitted(false);
    }

    useEffect(() => {
        if(isSubmitted) {
            dispatch(fetchAdminClientDetails({ clientId: id, userType: 1 }));
        }
    }, [isSubmitted])

    const handleChange = (event) => {
        event.stopPropagation();
        let value = event.target.value
        if (event.target.name === "ein") {
            maskEin(event);
            value = event.target.value; 
        }
        setNewBusiness((prevBusiness) => ({
            ...prevBusiness,
            [event.target.name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

        newBusiness.status = 0;
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                `/api/client-page/business/add/${id}`,
                newBusiness,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setIsSubmitted(true);
                setError('');
            }
        } catch (error) {
            console.log('Error')
            setError(error.response.data.error)
        } finally {
            setLoading(false);
        }
    }
    
    return (
        <Dialog
            aria-labelledby="add-new-business-modal"
            aria-describedby="add-new-business-modal"
            id="admin-business-dialog"
            open={open}
            onClick={(e) => e.stopPropagation()}
            // onClose={handleClose}
            fullWidth
        >
            <Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                <Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header" mb={3}>
					<Grid item>
						<Chip color="success" label="Add Business" />
					</Grid>
					<Grid item>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
                {!isSubmitted ? (
                    <>
                        {error && <Typography className='dialog-error-msg' color="secondary" variant='body1' textAlign="center">{error}</Typography>}
                        <Box component="form" onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="Business Name"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="businessName"
                                        required
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.businessName || ''}
                                     />
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="EIN"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="ein"
                                        required
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.ein || ''}
                                     />
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="DBA"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="dba"
                                        helperText="Doing Business As"
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.dba || ''}
                                     />
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="FKA"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="fka"
                                        helperText="Formerly Known As"
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.fka || ''}
                                     />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button 
                                        type="submit" 
                                        className='submit-btn'
                                        onClick={(e) => e.stopPropagation()}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <>
                                                Save&nbsp;
                                                <CircularProgress size={18} color="inherit" />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <Box className='success-msg'>
						<Grid container flexDirection="column" alignItems="center" justifyContent="center">
							<Grid item mb={2}>
								<img src={SuccessIcon} />
							</Grid>
							<Grid item>
								<Typography>
									Success! New Business has been saved.
								</Typography>
							</Grid>
						</Grid>
                    </Box>
                )}
            </Container>
        </Dialog>
    )
}

export default AddBusiness;