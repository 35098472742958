import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import AddReturnType from './AddReturnType';
import { Typography } from '@mui/material';


const ReturnType = ({ clientInfo, userToken, id, currentReturnType, setCurrentReturnType }) => {

    const [isAddReturnOpen, setAddReturnOpen] = useState(false);

    const handleReturnOpen = (event) => {
        event.stopPropagation();
    	setAddReturnOpen(true)
 	}
    
    return (
        <>
            <Box className="section-content return-type-container">
                <div>
                    {currentReturnType.length > 0 ? (
                        <Grid container justifyContent="space-between">
                            <Grid item container alignItems="center" columnGap={2} xs={10}>
                                {currentReturnType.map((returnType, index) => (
                                    <Grid item xs={1} key={index} className='return-type-item'>
                                        <Typography component="p">{returnType.taxForm}</Typography>
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleReturnOpen}>
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ) : (
                        <Button type="button" className='open-return-btn' onClick={handleReturnOpen}>
                            Add Return Type
                        </Button>
                    )}  
                </div>
                <AddReturnType
                    open={isAddReturnOpen}
                    setNewOpen={setAddReturnOpen}
                    userToken={userToken}
                    id={id}
                    currentReturnType={currentReturnType}
                    setCurrentReturnType={setCurrentReturnType}
                    userType={clientInfo.user_type}
                />
            </Box>
        </>
    )
}

export default ReturnType;