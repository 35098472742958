import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormLabel from "@mui/material/FormLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from "@mui/material/FormControl";

import { groups } from '../../../utils/constants';

const QuestionnaireDialog = ({ open, setOpen, currentQue }) => {
	
	const handleClose = () => {
		setOpen(false);
	}

	return (
		<Dialog
			aria-labelledby="que-viewer-modal"
        	aria-describedby="que-viewer-modal"
        	open={open}
			onClose={handleClose}
            fullWidth
            maxWidth="lg"
			id="questionnaire-view-dialog"
		>
			<Container sx={{ m: 0, p: 2 }}>
                <Grid container justifyContent="end">
					<Grid item>
						<IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
					</Grid>
				</Grid>
                {currentQue ? (
                    <>
                        <Grid container justifyContent="center">
					        <Grid item xs={11}>
						        <Typography component="h5" textAlign="center">
                                    {`Tax Questionnaire ${currentQue.year}`}
                                </Typography>
					        </Grid>
				        </Grid>
				        <Grid container>
                            <Grid item xs={12}>
                            {groups.map((group, i) => (
								<Box key={group}>
									<Typography className="group-heading" variant="h5">
										{group}
									</Typography>
									<Grid container mb={5} spacing={2}>
										{currentQue.questionnaire
										.map((item, index) => ({ ...item, index }))
										.filter((item) => item.group.toLowerCase() === group.toLowerCase())
                                        .sort((que1, que2) => que1.order - que2.order)
										.map(({ index, ...item }) => {
											if (item.type === 'input') {
												return (
													<Grid item xs={8} key={item.text}>
														<FormControl 
															fullWidth
                                                            disabled
														>
															<FormLabel>
																{item.text}
															</FormLabel>
															<TextField
																fullWidth
																multiline
                                                                disabled
																rows={3}
																name={item.text}
																value={item.answer}
															/>
														</FormControl>
                                            		</Grid>
                                        		);
                                    		} else {
                                        		return (
                                            		<Grid item container xs={8} key={item.text}>
														<FormControl disabled>
															<FormLabel>
																{item.text}
															</FormLabel>
															<RadioGroup
																name={item.text}
																value={item.answer}
																row
															>
																<FormControlLabel
																	value="yes"
																	control={<Radio />}
																	label="Yes"
																/>
																<FormControlLabel
																	value="no"
																	control={<Radio />}
																	label="No"
																/>
															</RadioGroup>
														</FormControl>
														{item.subQuestion &&
														item.subQuestion.length > 0 &&
                                                        item &&
                                                        item.answer === 'yes' &&
														 (
															<>
																{item.subQuestion.map((item, i) => {
																	if (item.subQuestionType === 'input') {
																		return (
																			<Grid
																				item
																				xs={12}
																				key={item.subQuestionText}
																			>
																				<FormControl 
																					fullWidth
                                                            						disabled
																				>
																					<FormLabel className="subquestion-label">
																						{item.subQuestionText}
																					</FormLabel>
																					<TextField
																						disabled
																						fullWidth
																						className="subquestion"
																						name={item.subQuestionText}
																						value={item.subQuestionText}
																						multiline
																						rows={3}
																					/>
																				</FormControl>
																			</Grid>
																		);
																	} else {
																		return (
																			<Grid
																				item
																				xs={10}
																				key={item.subQuestionText}
																			>
																				<FormControl disabled>
																					<FormLabel className="subquestion-label">
																						{item.subQuestionText}
																					</FormLabel>
																					<RadioGroup
																						className="subquestion"
																						defaultValue="general"
																						name={item.subQuestionText}
																						value={item.subQuestionAnswer}
																						row
																					>
																						<FormControlLabel
																							value="yes"
																							control={<Radio />}
																							label="Yes"
																						/>
																						<FormControlLabel
																							value="no"
																							control={<Radio />}
																							label="No"
																						/>
																					</RadioGroup>
																				</FormControl>
																			</Grid>
																		);
																	}
																})}
															</>
														)}
													</Grid>
												);
											}
										})}
									</Grid>
								</Box>
							))}
                        
                            </Grid>
                        </Grid>
                    </>

                ) : (
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography component="h5">Cannot open this questionnaire</Typography>
                        </Grid>
                    </Grid>

                )}
			</Container>
		</Dialog>
	)
};

export default QuestionnaireDialog;