import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import DoneIcon from '@mui/icons-material/Done';
import TaxPrepService from '../../assets/homepage/tax-prep-service.png';

import futureIcon from '../../assets/icons/services-future.png'
import partnersIcon from '../../assets/icons/services-partners.png'
import successIcon from '../../assets/icons/services-success.png'

const BusinessTaxes = () => {
    return (
        <Box id="individual-taxes">
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={5} className="intro-wrapper">
                <Grid item xs={6}>
                    <Typography component="p">
                        Whether you just started your business or have been in the game for a few years you know that having your own business has many perks, but taxes is definitely not one of them. So, we have your back in regards to taxes and with anything else you may need.
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <img src={TaxPrepService} className="banner-img" />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" columnGap={3}>
                <Grid item xs={3} className="individual-taxes-item">
                    <img src={partnersIcon} />
                    <Typography component="h3">
                        We are Your Partner
                    </Typography>
                    <Typography component="p">
                        Think of us as your CFO for hire. We make sure all your financials are correct and the business is taking all the tax benefits and growing.
                    </Typography>
                </Grid>
                <Grid item xs={3} className="individual-taxes-item">
                    <img src={futureIcon} />
                    <Typography component="h3">
                        Future Proofing
                    </Typography>
                    <Typography component="p">
                        Taxes and circumstances are always changing and we will make sure your business doesn't face any unnecessary surprises with proper tax planning and any other consulting you may require.
                    </Typography>
                </Grid>
                <Grid item xs={3} className="individual-taxes-item">
                    <img src={successIcon} />
                    <Typography component="h3">
                        Building Trust
                    </Typography>
                    <Typography component="p">
                        Like you we care about your business and your success because your success is our success. That is why we will never recommend something that we will not do ourselves.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" className="list-container" mt={5}>
                <Grid item xs={12} mb={3}>
                    <Typography component="h2">
                        Some of the individual tax returns that we handle
                    </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                    <Grid item xs={10} className="business-list-wrapper">
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Sole Proprietorships
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Partnerships
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    C Corporations
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    S Corporations
                                </ListItemText>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Exempt Organizations
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Estate & Trusts
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Everything in between – sales taxes, Commercial Rent Tax (CRT), and etc.
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default BusinessTaxes;