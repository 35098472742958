import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';


const LoadingList = ({type}) => {

    if(type === 'application') {
        return (
            <Grid container spacing={3} ml={2} mt={3} mr={2} mb={5}>
                {Array.from({ length: 5 }).map((_, index) => (
                    <Grid item xs={11} key={index}>
                        <Skeleton variant="rounded" height={200} />
                    </Grid>
                ))}
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={3} ml={2} mt={3} mr={2} mb={5}>
                {Array.from({ length: 8 }).map((_, index) => (
                    <Grid item xs={11} key={index}>
                        <Skeleton variant="rounded" height={50} />
                    </Grid>
                ))}
            </Grid>
        )
    }
}

export default LoadingList;