import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import UploadIcon from '../../../assets/icons/upload.png';
import CloseIcon from '@mui/icons-material/Close';

import { fileIcons, getFileExtension } from '../../../utils/fileIcons.js'

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000;

const convertNestedObjectToArray = (nestedObj) =>
  	Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const GeneralAddFilesFields = ({
  	label,
  	updateFilesCb,
  	maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  	...otherProps
}) => {

  	const fileInputField = useRef(null);
  	const [files, setFiles] = useState({});
  	const [dragState, setDragState] = useState(false);

  	const handleUploadBtnClick = (e) => {
    	e.preventDefault();
    	fileInputField.current.click();
  	};

  	const addNewFiles = (newFiles) => {
    	for (let file of newFiles) {
      		if (file.size < maxFileSizeInBytes) {
        		if (!otherProps.multiple) {
          			return { file };
        		}
        		files[file.name] = file;
      		}
    	}
    	return { ...files };
  	};

  	const callUpdateFilesCb = (files) => {
    	const filesAsArray = convertNestedObjectToArray(files);
    	updateFilesCb(filesAsArray);
  	};

  	const handleNewFileUpload = (e) => {
    	const { files: newFiles } = e.target;
    	if (newFiles.length) {
      		let updatedFiles = addNewFiles(newFiles);
      		setFiles(updatedFiles);
      		callUpdateFilesCb(updatedFiles);
    	}
  	};

  	const handleDrop = (e) => {
    	e.preventDefault();
    	e.stopPropagation();
    	const { files: newFiles } = e.dataTransfer;
    	if (newFiles.length) {
      		let updatedFiles = addNewFiles(newFiles);
      		// handleNewFileUpload(updatedFiles)
      		setFiles(updatedFiles);
      		callUpdateFilesCb(updatedFiles);
    	}
    	setDragState(false);
  	}

  	const handleDrag = (e, state) => {
    	e.preventDefault();
    	e.stopPropagation();
    	setDragState(state);
  	}

  	const removeFile = (fileName) => {
    	delete files[fileName];
    	setFiles({ ...files });
    	callUpdateFilesCb({ ...files });
  	};

  	return (
     	<>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Box 
						className={!dragState ? 'dropZone' : 'dropZone overDropZone'}
						onDrop={handleDrop}
						onDragOver={(e) => handleDrag(e, true)}
						onDragLeave={(e) => handleDrag(e, false)}
						onDragEnter={(e) => handleDrag(e, false)}
					>
						<Box className='text-wrapper'>
							<img src={UploadIcon} />
							<Typography>Drag&Drop your files here</Typography>
							<Typography>or</Typography>
							<Button
								onClick={handleUploadBtnClick}
							>
								Browse Files
							</Button>
						</Box>
						<input
							type="file"
							multiple
							ref={fileInputField}
							onChange={handleNewFileUpload}
							title=""
							value=""
							{...otherProps}
							className="hidden-file"
						/>
					</Box>
				</Grid>
				<Grid item xs={6} className="preview-files">
					<List>
						{Object.keys(files).map((fileName, index) => {
							let file = files[fileName];
							return (
								<ListItem
									key={fileName}
									secondaryAction={
										<IconButton edge="end" aria-label="delete" onClick={() => removeFile(fileName)}>
											<CloseIcon />
										</IconButton>
									}
									className="admin-uploaded-file"
								>
									<ListItemAvatar className='file-icon'>
										<img src={fileIcons[getFileExtension(file.name)] || fileIcons.default} alt="file icon" className='file-icon' />
									</ListItemAvatar>
									<ListItemText
										primary={file.name}
									/>
								</ListItem>         
							);
						})}
					</List>
				</Grid>
			</Grid>
        </>
  	)
};

export default GeneralAddFilesFields;