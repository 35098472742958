import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work';
import Typography from "@mui/material/Typography";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const ClientOpenedMenu = ({ clientMenu, userInfo, handleDrawer, setShowSubmenu, showSubmenu, checkIsActive }) => {

    return (
        <>
            <DrawerHeader className="client-profile-drawer-header">
                <Typography className="single-user-page">
                    Personal Dashboard
                </Typography>
                <IconButton onClick={handleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List className="list-open">
                {clientMenu.map((item, index) => {
                    if (item.menuItem === 'Documents') {
                        return (
                            <React.Fragment key={item.menuItem}>
                                <Divider />
                                <ListItem>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            pr: "10px",
                                            pl: "10px"
                                        }}
                                        onClick={() => setShowSubmenu(!showSubmenu)}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 2,
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {item.menuIcon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.menuItem} sx={{ display: "block" }} />
                          
                                        <IconButton>
                                            <ExpandMoreIcon />
                                        </IconButton>
                                  
                                    </ListItemButton>
                                </ListItem>
                                {item.menuItem === 'Documents' && showSubmenu && (
                                    <Collapse in={showSubmenu} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem sx={{pl: '40px'}}>
                                                <Link to='documents/personal' style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                                                
                                                    <ListItemButton 
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: 'initial',
                                                            px: 2.5,
                                                            pr: "10px",
                                                            pl: "10px"
                                                        }}
                                                        className={`list-group-item ${checkIsActive('/dashboard/documents/personal') ? 'active' : ''}`}
                                                    > 
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: 2,
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <PersonIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Personal" sx={{ display: "block"}} />
                                                    </ListItemButton>
                                                </Link>
                                            </ListItem>
                                            {!!userInfo && userInfo?.businesses.length > 0 && userInfo.businesses.map((business, index) => (
                                                <ListItem sx={{pl: '40px'}}>
                                                    <Link to={'documents/business/' + business.id} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
                                                
                                                <ListItemButton 
                                                    sx={{
                                                        minHeight: 48,
                                                        justifyContent: 'initial',
                                                        px: 2.5,
                                                        pr: "10px",
                                                        pl: "10px"
                                                    }}
                                                    className={`list-group-item ${checkIsActive(`/dashboard/documents/business/${business.id}`) ? 'active' : ''}`}
                                                
                                                > 
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: 0,
                                                            mr: 2,
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <WorkIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={business.businessName} sx={{ display: "block"}} />
                                                </ListItemButton>
                                            </Link>

                                                </ListItem>
                                            ))}
                                        </List>
                                    </Collapse>
                                )}
                                <Divider />
                            </ React.Fragment>
                        );
                    } else {
                        return (
                            <ListItem>
                                <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }} key={item.menuItem}>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'initial',
                                            px: 2.5,
                                            pr: "10px",
                                            pl: "10px"
                                        }}
                                        className={`list-group-item ${checkIsActive(item.path) ? 'active' : ''}`}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: 2,
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {item.menuIcon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.menuItem} sx={{ display: "block" }} />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        );
                    }
                })}
            </List>
        </>
    )
}

export default ClientOpenedMenu;