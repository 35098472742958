import axios from 'axios';
import { createAsyncThunk } from "@reduxjs/toolkit";

export const registerUser = createAsyncThunk (
  	'auth/register',
  	async({ firstName, lastName, email, phone, password, businessName, password2, token, isMarried, fileSeparateReturns, spouseAccount, spouseEmail}, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			}

			const body = {
				firstName: firstName,
				lastName : lastName,
				email: email,
				phone: phone,
				businessName: businessName,
				password: password,
  				password2: password2,
        		token: token,
				maritalDetails : {
					isMarried: isMarried,
					fileSeparateReturns: fileSeparateReturns,
					spouseAccount: spouseAccount,
					spouseEmail: spouseEmail
				}
				
			}

			await axios.post(
				'/api/auth/register',
				body,
				config
			)
		} catch (error) {
			if(error.response && error.response.data) {
				return rejectWithValue(error.response.data)
			} else {
				return rejectWithValue(error.message)
			}
		}
  	}
)

export const verifyEmail = createAsyncThunk(
	'auth/verifyEmail',
	async (email, { rejectWithValue }) => {
	  try {
		// Send a request to your backend to verify the email
		const response = await axios.post('/api/auth/verify-email', { email });
  
		// If the email exists in your system, return true
		return response.data;
	  } catch (error) {
		// Handle the error appropriately
		return rejectWithValue(error.response ? error.response.data : error.message);
	  }
	}
);

export const loginUser = createAsyncThunk(
	'auth/login',
	async ({ email, password }, { rejectWithValue }) => {
	  try {
		const response = await axios.post('/api/auth/login', { email, password });
		return response.data;
	  } catch (error) {
		return rejectWithValue(error.response ? error.response.data : error.message);
	  }
	}
);

export const verifyOtp = createAsyncThunk(
    'auth/verifyOtp',
    async ({ email, otp }, { rejectWithValue }) => {
        try {
            const response = await axios.post('/api/auth/verify-otp', { email, otp });

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const getUserDetails = createAsyncThunk(
  'user/getUserDetails',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${user.userToken}`
        }
      }
      const { data } = await axios.get(`/api/user/profile`, config)
      return data
    } catch (error) {
        if(error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message)
        } else {
          return rejectWithValue(error.message)
        }
    }
  }
)