import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";

import { useFetchData } from '../../../../hooks/useFetchData';

import GeneralClientFolders from '../../../general-client-components/general-client-documents/general-client-folders';
import GeneralClientFiles from '../../../general-client-components/general-client-documents/general-client-files';


const BusinessDocuments = () => {

    const { userToken } = useSelector((state) => state.user);
    const { id } = useParams();
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData(`/api/c/dashboard/business/files/${id}`, userToken, childChanges);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        if (data) {
            console.log(data)
            const folderNames = data.files.map((item) => item.name);
            setFolders(folderNames);
          }
    }, [data, id]);

    // Change view if business menu item changes
    useEffect(() => {
        if(id) {
            setSelectedFolder(null)
        }
    }, [id])


    return (
        <>
            <Box>
                {loading && <p>Loading...</p>}
                {!!data && !selectedFolder ? (
                    <GeneralClientFolders 
                        data={data?.files} 
                        businessName={data?.businessName}
                        businessId={id}
                        userToken={userToken}
                        folders={folders}
                        setSelectedFolder={setSelectedFolder} 
                        setChildChanges={setChildChanges}
                        isDocumentUploadActive={data?.userStatus}
                        loading={loading}
                    />
                ) : (
                    <GeneralClientFiles 
                        data={data?.files} 
                        selectedFolder={selectedFolder} 
                        setSelectedFolder={setSelectedFolder} 
                        userToken={userToken} 
                        folders={folders}
                        setChildChanges={setChildChanges}
                    />
                )} 
            </Box>
        </>

    )
}

export default BusinessDocuments;