import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {Link} from 'react-router-dom'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';


import { capitalize } from '@mui/material';
import { makeAvatar } from '../../../utils/makeAvatar';
import EmptyDataComponent from '../../ui/EmptyDataComponent';
import PaginationControlled from '../../ui/Pagination';

const MergedClientsList = ({ data, searchQue }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(9);

    const searchedList = !searchQue
    ? data
    : data.filter((client) =>
        client.user1.name.toLowerCase().includes(searchQue.toLowerCase()) ||
        client.user2.name.toLowerCase().includes(searchQue.toLowerCase())
    );

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = searchedList ? searchedList.slice(indexOfFirstRecord, indexOfLastRecord) : [];
    const nPages = searchedList ? Math.ceil(searchedList.length / recordsPerPage) : 0;

    if (!currentRecords) {
        return (
            <Grid container mt={4}>
                <Grid item>
                    <Typography component="h3">Server Error.</Typography>
                </Grid>
            </Grid>
        )
    }

    if (currentRecords && currentRecords.length === 0) {
        return (
            <EmptyDataComponent msg="It looks like you haven't merged any clients yet. Once you do, you'll see them listed here." />
        )
    }

    return (
        <Grid container className='clients-container' justifyContent="start" spacing={4}>
            {currentRecords && currentRecords
            .map((client, index) => (
                <Grid container justifyContent="center" alignItems="center" item key={client.id} xs={12} sm={12} md={6} lg={4}>
                    <Grid item>
                        <Link to={"/admin/merge/client-page/" + client.id}>
                            <Paper className="box">
                                <Grid container alignItems="center" spacing={2} className="paper-header">
                                    <Grid item>
                                        <Avatar className={client.active ? 'active-avatar' : 'archived-avatar'}>
                                            {client?.active ? (
                                                <CheckIcon />
                                            ) : (
                                                <CloseIcon />
                                            )}
                                        </Avatar>
                                    </Grid>
                                    <Grid item className='name-container'>
                                        <Typography variant="subtitle1">{capitalize(client.user1.name)}</Typography>
                                        <Typography variant="subtitle1">{capitalize(client.user2.name)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className='paper-business-container' mt={2}>
                                    <Grid item xs={12} mb={1}>
                                        <Typography variant="subtitle2">Businesses:</Typography>
                                    </Grid>
                                    <Grid item container spacing={1} flexWrap="wrap" wrap='wrap'>
                                        {client.businesses && client.businesses.length > 0 ? (
                                            client.businesses.map((business, index) => (
                                                <Grid item key={business._id}>
                                                    <Chip label={business.businessName} variant="outlined" />
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item>
                                                <Typography variant="body1" mt={2}>No businesses found for this account</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container className='paper-return-container'>
                                    <Grid item>
                                        <Typography variant="subtitle2" mt={2}>
                                            Return Type: 
                                            {client.returnType && client.returnType.length > 0 ? 
                                                client.returnType.map((returnType, index) => 
                                                    <span key={index}>{returnType.taxForm}{index !== client.returnType.length - 1 ? ', ' : ''}</span>
                                                ) : '-'
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
            ))}
            <Grid container justifyContent="center" mt={4}>
                <Grid item>
                    <PaginationControlled
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage} 
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MergedClientsList;