import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

import LoadingGrid from '../../../ui/Loading';
import EmptyDataComponent from '../../../ui/EmptyDataComponent';
import BusinessSettingsModal from './BusinessSettingsModal';
import PaginationControlled from '../../../ui/Pagination';

import { useFetchData } from "../../../../hooks/useFetchData";

const BusinessAccountIntegration = ({ userToken, searchQue }) => {

    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/admin/businesses/all', userToken, childChanges);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(9);


    const handleBusinessClick = (business) => {
        setSelectedBusiness(business);
        setIsModalOpen(true);
    };

    
    const searchedList = !searchQue
    ? data
    : data.filter(business => 
        business.businessName.toLowerCase().includes(searchQue.toLowerCase()) 
	)

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = searchedList ? searchedList.slice(indexOfFirstRecord, indexOfLastRecord) : [];
    const nPages = searchedList ? Math.ceil(searchedList.length / recordsPerPage) : 0;

    if (loading) {
        return <LoadingGrid />;
    }

    if(data && data.length === 0) {
        return (
            <EmptyDataComponent msg="It looks like you do not have any businesses yet. Once you do, you'll see them listed here." />
        )
    }

    return (
        <Box id="businesses-integration-wrapper">
            <Grid container className='businesses-container' justifyContent="start" spacing={4}>
                {currentRecords && currentRecords.map((business, index) => {
                    const activeUsers = business.users.filter(user => user.active === true);

                    const frozenUsers = business.users.filter(user => user.active === false);
                    return (
                        <Grid container justifyContent="center" alignItems="center" item key={business._id} xs={12} sm={12} md={6} lg={4}>
                            <Grid item>
                                <Paper className="box" onClick={() => handleBusinessClick(business)}>
                                    <Grid container alignItems="center" spacing={2} className="paper-header">
                                        <Grid item>
                                        </Grid>
                                        <Grid item className='name-container'>
                                            <Typography variant="subtitle1">{business.businessName}</Typography>
                                            <Typography variant="body1">{business.ein}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='paper-clients-container' mt={2}>
                                        <Grid item container spacing={1} flexWrap="wrap" wrap='wrap'>
                                            {activeUsers.map((user, index) => (
                                                <Grid item key={user.user} xs={12} className='clients-item'>
                                                    <Typography variant="body1" mt={2}>{user.firstName} {user.lastName}</Typography>
                                                    {user.permissions === 'upload' ? (
                                                        <>
                                                            <Chip label="View" className='view-chip' variant="outlined"/>
                                                            <Chip label="Upload" className='upload-chip' variant="outlined"/>
                                                        </>
                                                    ) : (
                                                        <Chip label="View" className='view-chip' variant="outlined"/>
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                        {frozenUsers.length > 0 && (
                                            <Grid item container className='paper-frozen-clients-container' spacing={1} mt={1} flexWrap="wrap" wrap='wrap'>
                                                <Typography variant="h6">Frozen Accounts</Typography>
                                                {frozenUsers.map((user, index) => (
                                                    <Grid item key={user.user} xs={12} className='clients-item'>
                                                        <Typography variant="body1">{user.firstName} {user.lastName}</Typography>
                                                        <Chip label="View" className='view-chip' variant="outlined"/>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    );
                })}
                <Grid container justifyContent="center" mt={4}>
                    <Grid item>
                        <PaginationControlled
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage} 
                        />
                    </Grid>
                </Grid>
            </Grid>
            <BusinessSettingsModal
                open={isModalOpen}
                setOpen={setIsModalOpen}
                business={selectedBusiness}
                userToken={userToken}
                setChildChanges={setChildChanges}
                view="general"
            />
        </Box>
    )
}

export default BusinessAccountIntegration;