import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import DoneIcon from '@mui/icons-material/Done';

import timeIcon from '../../assets/icons/services-time.png'
import moneyIcon from '../../assets/icons/services-money.png'
import communicationIcon from '../../assets/icons/services-communication.png'
import TaxPrepService from '../../assets/homepage/tax-prep-service.png';

const PersonalTaxes = () => {
    return (
        <Box id="individual-taxes">
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={5} className="intro-wrapper">
                <Grid item xs={5}>
                    <img src={TaxPrepService} className="banner-img" />
                </Grid>
                <Grid item xs={6}>
                    <Typography component="p">
                        You can definitely prepare your own taxes, but chances are you might have questions, miss certain things, or maybe your tax situation is extremely complex. That is where we come in and guarantee that you:
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" columnGap={3}>
                <Grid item xs={3} className="individual-taxes-item">
                    <img src={timeIcon} />
                    <Typography component="h3">
                        Save Time
                    </Typography>
                    <Typography component="p">
                        We do everything for you once you provide us with your documents. So this way you are free to do other, and more enjoyable things!
                    </Typography>
                </Grid>
                <Grid item xs={3} className="individual-taxes-item">
                    <img src={communicationIcon} />
                    <Typography component="h3">
                        Year-Round Support
                    </Typography>
                    <Typography component="p">
                        Just because your tax return was filed doesn't mean we will ghost you. We are always here to answer any questions or resolve any issues.
                    </Typography>
                </Grid>
                <Grid item xs={3} className="individual-taxes-item">
                    <img src={moneyIcon} />
                    <Typography component="h3">
                        No Hidden Fees
                    </Typography>
                    <Typography component="p">
                        We don't charge you based on your refund. You only pay one flat fee and we will always inform you when, and if, our fees change.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" className="list-container" mt={5}>
                <Grid item xs={12} mb={3}>
                    <Typography component="h2">
                        Some of the individual tax returns that we handle
                    </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                    <Grid item xs={10}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Domestic Taxes - individuals who are US citizens, Green Card holders, and anyone else who meets the residency test.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Foreign Taxes - individuals who are in the US via a visa and do not meet the residency test, or you are just a foreign individual who has to pay US taxes due to various reasons.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Dual Status - individuals who are foreign and moved to the US, or domestic and moved out of the US.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Foreign Bank Account Reporting (FBAR) - individuals who have bank accounts outside of the US.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Gift Taxes
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <DoneIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    Estate & Trusts
                                </ListItemText>
                            </ListItem>
                        </List>

                    </Grid>

                </Grid>

            </Grid>
        </Box>
    )
}

export default PersonalTaxes;