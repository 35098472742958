import React, {useEffect, useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material'
import theme from './components/ui/Theme'
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import About from "./components/screens/About";
import FreeQuote from "./components/screens/Estimate";
import Services from "./components/screens/Services";
import TaxPreparation from "./components/screens/TaxPreparation";
import Consulting from "./components/screens/Consulting";
import OtherServices from "./components/screens/OtherServices";
import ContactUs from "./components/screens/Contact";
import NotFound from "./components/screens/NotFound";

// AUTH COMPONENTS
import RegisterScreen from "./components/auth/RegisterScreen";
import RequestReset from "./components/auth/RequestReset";
import ResetPassword from "./components/auth/ResetPassword";
import ClientAccountOptions from "./components/auth/ClientAccounts";
import Login from "./components/auth/Login2/Login";

// PROTECTED ROUTES
import ProtectedRoute from "./routing/ProtectedRoute";
import AdminProtectedRoute from "./routing/AdminProtectedRoute";
import ApplicationProtectedRoute from "./routing/ApplicationProtectedRouter";
import MergedApplicationProtectedRoute from "./routing/MergedApplicationProtectedRoute";

// CLIENT COMPONENTS
import GeneralBusinessApplication from "./components/general-client-components/general-business-application";
import ClientRoutes from "./components/client/ClientDashboard/ClientRoutes";
import ClientSettings from "./components/client/ClientSettings";
import GeneralClientApplication from "./components/general-client-components/general-client-application";

// MERGED CLIENT COMPONENTS
import MergedClientRoutes from "./components/client/MergedClientDashboard/MergedClientRoutes";
import GeneralMergedApplication from "./components/general-client-components/general-merged-application";

// ADMIN COMPONENTS
import AdminRoutes from "./components/admin/AdminRoutes";
import AdminClientPageRoutes from "./components/admin/ClientPage/AdminClientPageRoutes";
import AdminMergeClientPageRoutes from "./components/admin/ClientPage/AdminMergeClientPageRoutes";


import './App.css';
import './styles/client-dashboard.css'

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navbar/>
        <main id="main" className="container content">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/free-quote" element={<FreeQuote />} />
            <Route path="/services" element={<Services/>} />
            <Route path="/services/tax-preparation" element={<TaxPreparation/>} />
            <Route path="/services/consulting" element={<Consulting/>} />
            <Route path="/services/other" element={<OtherServices/>} />
            <Route path="/register/:token" element={<RegisterScreen />} />
            <Route path="/login" element={<Login />} />
            <Route path="/client-accounts" element={<ClientAccountOptions />} />
            <Route path="/reset" element={<RequestReset />} />
            <Route path="/reset/:token" element={<ResetPassword />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/dashboard/*" element={<ClientRoutes />} />
              <Route path="/dashboard/merge/*" element={<MergedClientRoutes />} />
              <Route path="/settings" element={<ClientSettings />} />
            </Route>
            <Route element={<ApplicationProtectedRoute />}>
              <Route path="/profile" element={<GeneralClientApplication />} />
            </Route>
            <Route element={<MergedApplicationProtectedRoute/>}>
              <Route path="/merged-client-application" element={<GeneralMergedApplication />} />
            </Route>
            
            <Route element={<AdminProtectedRoute />}>
              <Route path="/admin-profile/*" element={<AdminRoutes />} />
              <Route path="/admin/*" element={<AdminClientPageRoutes />} />
              <Route path="/admin/merge/*" element={<AdminMergeClientPageRoutes />} />
              <Route path="/admin/settings" element={<ClientSettings />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
   </ThemeProvider>
  )
}

export default App;