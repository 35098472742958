import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import homepageBanner from '../../assets/services1.png'
import freeQuoteBanner from '../../assets/services2.png'


// import ButtonArrow from "./ui/ButtonArrow";
// import customSoftwareIcon from "../assets/Custom Software Icon.svg";
// import mobileAppsIcon from "../assets/mobileIcon.svg";
// import websitesIcon from "../assets/websiteIcon.svg";

// const useStyles = makeStyles(theme => ({
//   specialText: {
//     fontFamily: "Pacifico",
//     color: theme.palette.common.orange
//   },
//   subtitle: {
//     marginBottom: "1em"
//   },
//   icon: {
//     marginLeft: "2em",
//     [theme.breakpoints.down("xs")]: {
//       marginLeft: 0
//     }
//   },
//   serviceContainer: {
//     marginTop: "10em",
//     [theme.breakpoints.down("sm")]: {
//       padding: 25
//     }
//   },
//   learnButton: {
//     ...theme.typography.learnButton,
//     fontSize: "0.7rem",
//     height: 35,
//     padding: 5,
//     [theme.breakpoints.down("sm")]: {
//       marginBottom: "2em"
//     }
//   }
// }));
import '../../styles/services.css'

const Services = (props) => {

  	return (
		<Box id="services-page">
			<Grid container mt={5} mb={10}>
				<Grid item>
					<Typography component="h1">
						Our Services
					</Typography>
				</Grid>
			</Grid>
			<Grid container alignItems="center" justifyContent="space-evenly" spacing={3} pt={5} pb={5} className="has-background">
				<Grid item xs={5}>
					<img src={freeQuoteBanner}/>
				</Grid>
				<Grid item xs={5} >
					<Typography component="h3">
						Tax Preparation
					</Typography>
					<Typography component="p">
						We provide tax preparation services for a variety of individual and business clients. TaxBox can help you file your taxes on time, while providing unparalleled service at competitive rates. 
					</Typography>
					<Grid container justifyContent="flex-start">
						<Grid item xs={5}>
							<Button
								className="link-button"
								component={Link}
								to='tax-preparation'
							>
								Learn More
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid container alignItems="center" justifyContent="space-evenly" spacing={3} pt={5} pb={5}>
				<Grid item xs={5}>
					<Typography component="h3">
						Consulting
					</Typography>
					<Typography component="p">
						We advise on all sorts of tax issues, big and small. Evaluating financial and life-planning decisions with an eye toward tax is essential. 
					</Typography>
					<Grid container justifyContent="flex-start">
						<Grid item xs={5}>
							<Button
								className="link-button"
								component={Link}
								to='consulting'
							>
								Learn More
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={5}>
					<img src={homepageBanner}/>
				</Grid>
			</Grid>
			<Grid container alignItems="center" justifyContent="space-evenly" spacing={3} pt={5} pb={5} className="has-background">
				<Grid item xs={5}>
					<img src={freeQuoteBanner}/>
				</Grid>
				<Grid item xs={5}>
					<Typography component="h3">
						Other
					</Typography>
					<Typography component="p">
						We advise on all sorts of tax issues, big and small. Evaluating financial and life-planning decisions with an eye toward tax is essential. 
					</Typography>
					<Grid container justifyContent="flex-start">
						<Grid item xs={5}>
							<Button
								className="link-button"
								component={Link}
								to='other'
							>
								Learn More
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>

  	)
    // <Grid container direction="column">
    //   <Grid
    //     item
    //     style={{
    //       marginLeft: "5em",
    //       marginTop: "2em"
    //     }}
    //   >
    //     <Typography
    //       align={undefined}
    //       variant="h2"
    //       gutterBottom
    //     >
    //       Services
    //     </Typography>
    //   </Grid>
    //   <Grid item>
    //     {" "}
    //     {/*-----iOS/Android Block-----*/}
    //     <Grid
    //       container
    //       direction="row"
    //       justify={"flex-end"}
    //       style={{ marginTop:"5em" }}
    //     >
    //       <Grid
    //         item
    //         style={{
    //           textAlign:  undefined,
    //           width: "35em"
    //         }}
    //       >
    //         <Typography variant="h4">iOS/Android App Development</Typography>
    //         <Typography variant="subtitle1">
    //           Extend Functionality. Extend Access. Increase Engagement.
    //         </Typography>
    //         <Typography variant="subtitle1">
    //           Integrate your web experience or create a standalone app
    //           {matchesSM ? null : <br />}with either mobile platform.
    //         </Typography>
    //         <Button
    //           component={Link}
    //           to="/mobileapps"
    //           variant="outlined"
          
    //           onClick={() => {
    //             props.setValue(1);
    //             props.setSelectedIndex(2);
    //           }}
    //         >
    //           <span style={{ marginRight: 10 }}>Learn More</span>
    //           <ButtonArrow
    //             width={10}
    //             height={10}
    //             fill={theme.palette.common.blue}
    //           />
    //         </Button>
    //       </Grid>
    //       <Grid item style={{ marginRight: "5em" }}>
    //         <img
      
    //           alt="mobile phone icon"
    //           src={homepageBanner}
    //           width="250em"
    //         />
    //       </Grid>
    //     </Grid>
    //   </Grid>
    //   <Grid item>
    //     {" "}
    //     {/*-----Custom Software Block-----*/}
    //     <Grid
    //       container
    //       direction="row"
    //       justify={undefined}
      
    //     >
    //       <Grid
    //         item
    //         style={{
    //           marginLeft:  "5em",
    //           textAlign: undefined
    //         }}
    //       >
    //         <Typography variant="h4">Custom Software Development</Typography>
    //         <Typography variant="subtitle1" >
    //           Save Energy. Save Time. Save Money.
    //         </Typography>
    //         <Typography variant="subtitle1">
    //           Complete digital solutions, from investigation to{" "}
    //           <span className={classes.specialText}>celebration.</span>
    //         </Typography>
    //         <Button
    //           component={Link}
    //           to="/customsoftware"
    //           variant="outlined"
          
    //           onClick={() => {
    //             props.setValue(1);
    //             props.setSelectedIndex(1);
    //           }}
    //         >
    //           <span style={{ marginRight: 10 }}>Learn More</span>
    //           <ButtonArrow
    //             width={10}
    //             height={10}
    //             fill={theme.palette.common.blue}
    //           />
    //         </Button>
    //       </Grid>
    //       <Grid item>
    //         <img
          
    //           alt="custom software icon"
    //           src={customSoftwareIcon}
    //         />
    //       </Grid>
    //     </Grid>
    //   </Grid>

    //   <Grid item>
    //     {" "}
    //     {/*-----Websites Block-----*/}
    //     <Grid
    //       container
    //       direction="row"
    //       justify={"flex-end"}
      
    //       style={{ marginBottom: "10em" }}
    //     >
    //       <Grid
    //         item
    //         style={{
    //           textAlign: undefined,
    //           width: "35em"
    //         }}
    //       >
    //         <Typography variant="h4">Website Development</Typography>
    //         <Typography variant="subtitle1" className={classes.subtitle}>
    //           Reach More. Discover More. Sell More.
    //         </Typography>
    //         <Typography variant="subtitle1">
    //           Optimized for Search Engines, built for speed.
    //         </Typography>
    //         <Button
    //           component={Link}
    //           to="/websites"
    //           variant="outlined"
    //           className={classes.learnButton}
    //           onClick={() => {
    //             props.setValue(1);
    //             props.setSelectedIndex(3);
    //           }}
    //         >
    //           <span style={{ marginRight: 10 }}>Learn More</span>
    //           <ButtonArrow
    //             width={10}
    //             height={10}
    //             fill={theme.palette.common.blue}
    //           />
    //         </Button>
    //       </Grid>
    //       <Grid item style={{ marginRight: "5em" }}>
    //         <img
          
    //           alt="website icon"
    //           src={websitesIcon}
    //           width="250em"
    //         />
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Grid>
}

export default Services;