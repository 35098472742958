import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmail, loginUser } from "../../../actions/authActions";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";

import LoginImage from '../../../assets/auth/sign-in.png';
import OtpImage from '../../../assets/auth/otp-verify.png'

import LoginComponent from './LoginComponent';
import OtpComponent from './OtpComponent';

function Login() {
    const dispatch = useDispatch();
    const [otpSent, setOtpSent] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');


    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(loginUser({ email, password }));
            if(result.payload.message === "OTP sent to email. Please verify to continue.") {
                setOtpSent(true);
            }
            if(result.error) {
                setLoginError(result.payload.message)
            }
            console.log(result)
        }catch (error) {
            console.log('hello')
            alert(error.message); // Or handle the error in another way
        }
    };

    return (
        <Box id="login-screen">
            <Grid container className='grid-container'>
                <Grid item className="image-container">
                    {otpSent ? (
                        <img className='otp-img' src={OtpImage} />
                    ) : (
                        <img className='login-img' src={LoginImage} />
                    )}
                   
                </Grid>
                <Grid item container className="form-container">
                    {otpSent ? (
                        <OtpComponent 
                            email={email} 
                            handlePasswordSubmit={handlePasswordSubmit} 
                        />
                    ) : (
                        <LoginComponent 
                            loginError={loginError} 
                            setOtpSent={setOtpSent} 
                            email={email} 
                            setEmail={setEmail} 
                            password={password} 
                            setPassword={setPassword} 
                            handlePasswordSubmit={handlePasswordSubmit} 
                        />
                    )}

                </Grid>

            </Grid>

        </Box>
    )


//   if (otpSent) {
//     return <OtpComponent email={email} handlePasswordSubmit={handlePasswordSubmit} />;
//   }

//   return <LoginComponent loginError={loginError} setOtpSent={setOtpSent} email={email} setEmail={setEmail} password={password} setPassword={setPassword} handlePasswordSubmit={handlePasswordSubmit} />;
}

export default Login;