import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

import AddBusinessUser from './AddNewBusinessUser';

import { fetchAdminClientDetails } from '../../../../actions/adminClientActions';

const CORRECT_PASSWORD = '12345';
//Temporary state for active users
//Temporary state for frozen users
let previousActiveStates = {};
let previousFrozenStates = {};
let hasInactiveUser;


import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';

const BusinessSettingsModal = ({ business, open, setOpen, userToken, setChildChanges, view, id }) => {

    const dispatch = useDispatch();
    const [updatedBusiness, setUpdatedBusiness] = useState(business);
	const [isSubmitted, setSubmitted] = useState(false)
  	const [error, setError] = useState(null); 
	const [loading, setLoading] = useState(false);
    const [activeUsers, setActiveUsers] = useState([]);
    const [frozenUsers, setFrozenUsers] = useState([]);
    const [newUsers, setNewUsers] = useState([]);

    useEffect(() => {
        if(isSubmitted && id && view === 'account') {
            dispatch(fetchAdminClientDetails({ clientId: id, userType: 1 }));
        }
    }, [isSubmitted])


    useEffect(() => {
        if(business) {
            setUpdatedBusiness(business);
            hasInactiveUser = business && business?.users && business.users.some(user => user.active === false);
        }
    }, [business])

    // useEffect(() => {
    //     if(activeUsers) {
    //         console.log('Active users', activeUsers)
    //     }
    // }, [activeUsers])

    // useEffect(() => {
    //     if(newUsers) {
    //         console.log('New users', newUsers)
    //     }
    // }, [newUsers])

    // useEffect(() => {
    //     if(frozenUsers) {
    //         console.log('Frozen Users', frozenUsers)
    //     }
    // }, [frozenUsers])

    useEffect(() => {
        if (business && business.users) {
            const active = business.users.filter(user => user.active === true);
            const frozen = business.users.filter(user => user.active === false);

            setActiveUsers(active);
            setFrozenUsers(frozen);
        }
    }, [business]);

   


    const handleActiveUserPasswordChange = (userId, newPassword) => {
        const userPreviousState = previousActiveStates[userId];
    
        if (newPassword === CORRECT_PASSWORD) {
            if (!userPreviousState) {
                // Save the current state for this user before making changes
                previousActiveStates[userId] = {
                    activeUsers: [...activeUsers],
                    frozenUsers: [...frozenUsers],
                    updatedBusinessUsers: [...updatedBusiness.users]
                };
            }
    
            // Move user from active to frozen
            const updatedActiveUsers = activeUsers.filter(user => user.user !== userId);
            const userToFreeze = activeUsers.find(user => user.user === userId);
            if (userToFreeze) {
                setFrozenUsers([...frozenUsers, { ...userToFreeze, active: false, permissions: 'view' }]);
                setActiveUsers(updatedActiveUsers);
    
                // Update permissions in updatedBusiness.users
                setUpdatedBusiness(prevState => ({
                    ...prevState,
                    users: prevState.users.map(user => 
                        user.user === userId ? { ...user, permissions: 'view' } : user
                    )
                }));
            }
        } else {
            if (userPreviousState) {
                // Revert to the previous state if the password is no longer correct
                setActiveUsers(userPreviousState.activeUsers);
                setFrozenUsers(userPreviousState.frozenUsers);
                setUpdatedBusiness({ ...updatedBusiness, users: userPreviousState.updatedBusinessUsers });
    
                // Reset the previousState for this user as the changes are reverted
                delete previousActiveStates[userId];
            }
        }
    };

    const handleFrozenUserPasswordChange = (userId, newPassword) => {
        const userPreviousState = previousFrozenStates[userId];
    
        if (newPassword === CORRECT_PASSWORD) {
            if (!userPreviousState) {
                // Save the current state for this user before making changes
                previousFrozenStates[userId] = {
                    activeUsers: [...activeUsers],
                    frozenUsers: [...frozenUsers],
                    updatedBusinessUsers: [...updatedBusiness.users]
                };
            }
    
            // Move user from frozen to active
            const updatedFrozenUsers = frozenUsers.filter(user => user.user !== userId);
            const userToActivate = frozenUsers.find(user => user.user === userId);
            if (userToActivate) {
                setActiveUsers([...activeUsers, { ...userToActivate, active: true }]);
                setFrozenUsers(updatedFrozenUsers);
            }
        } else {
            if (userPreviousState) {
                // Revert to the previous state if the password is no longer correct
                setActiveUsers(userPreviousState.activeUsers);
                setFrozenUsers(userPreviousState.frozenUsers);
                setUpdatedBusiness({ ...updatedBusiness, users: userPreviousState.updatedBusinessUsers });
    
                // Reset the previousState for this user as the changes are reverted
                delete previousFrozenStates[userId];
            }
        }
    };

    const handleCheckboxChange = (userId, permission) => {
        let updatedUsers = [...updatedBusiness.users];
    
        // Find the user and update their permissions
        updatedUsers = updatedUsers.map(user => {
            if (user.user === userId) {
                if (permission === 'upload' && user.permissions === 'upload') {
                    // If "upload" is already checked and it's clicked again, set permissions to "view"
                    return {
                        ...user,
                        permissions: 'view'
                    };
                } else {
                    // Otherwise, set the permissions to the clicked checkbox
                    return {
                        ...user,
                        permissions: permission
                    };
                }
            }
            return user;
        });
    
    
        setUpdatedBusiness({
            ...updatedBusiness,
            users: updatedUsers
        });

        if (activeUsers.some(user => user.user === userId)) {
            let updatedActiveUsers = activeUsers.map(user => {
                if (user.user === userId) {
                    return { ...user, permissions: updatedUsers.find(u => u.user === userId).permissions };
                }
                return user;
            });
    
            setActiveUsers(updatedActiveUsers);
        }
    };


    const hasAtLeastOneUpload = () => {
        return activeUsers.length > 0 && activeUsers.some(user => user.permissions === 'upload');
    };

    const handleClose = (e) => {
        e.stopPropagation();
        if(view === 'general') {
            setChildChanges((prevChanges) => !prevChanges);
        }
        setOpen(false);
        setError(null);
        setLoading(null);
        setSubmitted(false);
        setUpdatedBusiness(business);
        setNewUsers([]);
        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!hasAtLeastOneUpload()) {
            setError("Business must have at least one user with 'upload' permission.");
            return;
        }
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const data = {
                activeUsers: activeUsers,
                frozenUsers: frozenUsers,
                newUsers: newUsers
            }

            const response = await axios.put(
                `/api/admin/businesses/settings/${business._id}`,
                data,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setSubmitted(true);
                setError('');
            }
        } catch (error) {
            console.log(error)
            setError('Error')
        } finally {
            setLoading(false);
        }
    };


  	return (
		<>
			<Dialog
				aria-labelledby="business-settings-modal"
        	    aria-describedby="business-settings-modal"
				id='business-settings-modal'
        	    open={open}
			    onClose={handleClose}
                fullWidth
				maxWidth="md"
                onClick={(e) => e.stopPropagation()}
			>
				<Container className="new-question-container" sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="add-que-header" mb={3}>
						<Grid item>
							<Chip color="success" label="Settings" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseRoundedIcon />
							</IconButton>
						</Grid>
					</Grid>
					{isSubmitted ? (
                        <DialogSuccessMsg msg="Success! Business has been updated." />
                    ) : (
                        business && updatedBusiness ? (
                            <>
                                {error && <Typography className='dialog-error-msg' textAlign="center">{error}</Typography>}
                                
                                <Grid container className='business-name'>
                                    <Grid item xs={12}>
                                        <Typography component="h2">
                                            <Typography component="span">
                                                {business.businessName}
                                            </Typography>
                                             User Settings
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Box className='active-users'>
                                    <Typography className='heading' component="p">Active clients</Typography>
                                    <Grid container spacing={2}>
                                        {updatedBusiness.users.filter(user => user.active).map((user, index) => (
                                            <Grid item container xs={12} alignItems="center" spacing={2} key={user.user}>
                                                <Grid item>
                                                    <Typography>{user.firstName} {user.lastName}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox 
                                                                checked={user.permissions === 'view' || user.permissions === 'upload'} 
                                                                onChange={() => handleCheckboxChange(user.user, 'view')}
                                                                disabled={frozenUsers.some(frozenUser => frozenUser.user === user.user)}
                                                            />
                                                        }
                                                        label="View"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                       control={
                                                        <Checkbox 
                                                            checked={user.permissions === 'upload'} 
                                                            onChange={() => handleCheckboxChange(user.user, 'upload')}
                                                            disabled={frozenUsers.some(frozenUser => frozenUser.user === user.user)}
                                                        />
                                                    }
                                                    label="Upload"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField 
                                                        size='small'
                                                        label="Password" 
                                                        onChange={(e) => handleActiveUserPasswordChange(user.user, e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormLabel>Freeze</FormLabel>
                                                    <Switch
                                                        name="freeze"
                                                        disabled={true}
                                                        checked={frozenUsers.some(frozenUser => frozenUser.user === user.user)}
                                                    />
                                                </Grid>
                                                {frozenUsers.some(frozenUser => frozenUser.user === user.user) && (
                                                    <Grid className='warning' item xs={12} pt={1}>
                                                        <Typography className='warning-msg'>Note: {user.firstName} {user.lastName} will no longer be able to view new documents on their dashboard.</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                {hasInactiveUser && (
                                    <Box mt={2} className='active-users'>
                                        <Typography className='heading' component="p">Frozen clients</Typography>
                                         <Grid container spacing={2}>
                                         {updatedBusiness.users.filter(user => !user.active).map((user, index) => (
                                            <Grid item container xs={12} alignItems="center" spacing={2} key={user.user}>
                                                <Grid item>
                                                    <Typography>{user.firstName} {user.lastName}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox 
                                                                checked={user.permissions === 'view' || user.permissions === 'upload'} 
                                                                onChange={() => handleCheckboxChange(user.user, 'view')}
                                                                disabled={frozenUsers.some(frozenUser => frozenUser.user === user.user)}
                                                            />
                                                        }
                                                        label="View"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormControlLabel
                                                       control={
                                                            <Checkbox 
                                                                checked={user.permissions === 'upload'} 
                                                                onChange={() => handleCheckboxChange(user.user, 'upload')}
                                                                disabled={frozenUsers.some(frozenUser => frozenUser.user === user.user)}
                                                            />
                                                        }
                                                        label="Upload"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField 
                                                        size='small'
                                                        label="Password"
                                                        onChange={(e) => handleFrozenUserPasswordChange(user.user, e.target.value)}

                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormLabel>Freeze</FormLabel>
                                                    <Switch
                                                        name="freeze"
                                                        checked={frozenUsers.some(frozenUser => frozenUser.user === user.user)}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                {activeUsers.some(frozenUser => frozenUser.user === user.user) && (
                                                    <Grid item className='warning' xs={12} pt={1}>
                                                        <Typography className='warning-msg'>Note: {user.firstName} {user.lastName} will now have access to all documents on their dashboard.</Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        ))}

                                         </Grid>
                                    </Box>
                                )}
                                <Box mt={2} className='active-users'>
                                    <Typography className='heading' component="p">New Clients</Typography>
                                    {activeUsers.length <= 2 ? (
                                        <AddBusinessUser 
                                            userToken={userToken} 
                                            businessId={business._id}
                                            updatedBusiness={updatedBusiness}
                                            setNewUsers={setNewUsers}
                                            newUsers={newUsers}
                                            activeUsers={activeUsers}
                                        />

                                    ) : (
                                        <Typography
                                            className="new-clients-msg"
                                        >
                                            You have reached the maximum number of clients that can be associated with this business.
                                        </Typography>
                                    )} 
                                </Box>
                                <Grid item xs={12} container justifyContent="flex-end">
                                    <Grid item>
                                        <Button 
                                            className='save-business-btn' 
                                            type="submit" 
                                            disabled={loading}
                                            onClick={handleSubmit}
                                        >
                                            {loading ? (
                                                <>
                                                    Submit&nbsp;
                                                    <CircularProgress size={16} color="inherit" />
                                                </>
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        ) : null
                    )}
					
				</Container>
			</Dialog>
		</>
  )
}

export default BusinessSettingsModal;

// const handleCheckboxChange = (userId, permission) => {
//     let updatedUsers = [...updatedBusiness.users];

//     // Find the user and update their permissions
//     updatedUsers = updatedUsers.map(user => {
//         if (user.user === userId) {
//             if (permission === 'upload' && user.permissions === 'upload') {
//                 // If "upload" is already checked and it's clicked again, set permissions to "view"
//                 return {
//                     ...user,
//                     permissions: 'view'
//                 };
//             } else {
//                 // Otherwise, set the permissions to the clicked checkbox
//                 return {
//                     ...user,
//                     permissions: permission
//                 };
//             }
//         }
//         return user;
//     });


//     setUpdatedBusiness({
//         ...updatedBusiness,
//         users: updatedUsers
//     });
// };


// const handlePasswordChange = (password, userId) => {

//     if (password === "12345") {
//         if (activeUsers.some(user => user.user === userId)) {
//             // If the user is in the activeUsers array, freeze them
//             handleSwitchChange(userId, true);
//         } else if (frozenUsers.some(user => user.user === userId)) {
//             // If the user is in the frozenUsers array, unfreeze them
//             handleSwitchChange(userId, false);
//         }
//     } else {
//         if (activeUsers.some(user => user.user === userId)) {
//             // If the user is in the activeUsers array, freeze them
//             handleSwitchChange(userId, false);
//         } else if (frozenUsers.some(user => user.user === userId)) {
//             // If the user is in the frozenUsers array, unfreeze them
//             handleSwitchChange(userId, true);
//         }

//     }
// };

// const handleSwitchChange = (userId, freeze) => {
//     let updatedFrozenUsers = [...updatedBusiness.frozenUsers];
//     let updatedUsers = [...updatedBusiness.users];

//     if (freeze) {
//         if (!updatedFrozenUsers.some(frozenUser => frozenUser.user === userId)) {
//             // If the user is not frozen, add them to the frozenUsers array
//             updatedFrozenUsers.push({ user: userId });
//         }

//         // Change the permissions of the user to "view" if they had "upload"
//         updatedUsers = updatedUsers.map(user => {
//             if (user.user === userId && user.permissions === 'upload') {
//                 return {
//                     ...user,
//                     permissions: 'view'
//                 };
//             }
//             return user;
//         });

//     } else {
//         // If the user is already frozen, remove them from the frozenUsers array
//         updatedFrozenUsers = updatedFrozenUsers.filter(frozenUser => frozenUser.user !== userId);
//     }

//     setUpdatedBusiness({
//         ...updatedBusiness,
//         frozenUsers: updatedFrozenUsers,
//         users: updatedUsers
//     });
// };
