import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import SuccessIcon from '../../../assets/icons/check.png'


const AddEngLetter = ({ userToken, open, setNewLetterOpen, setLetter, setChildChanges }) => {

    const [paragraphs, setParagraphs] = useState([{ paragraph: '' }]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const insertParagraph = (index) => {
        const newParagraphs = [...paragraphs];
        newParagraphs.splice(index, 0, { paragraph: '' });
        setParagraphs(newParagraphs);
    };

    const removeParagraph = (index) => {
        const newParagraphs = [...paragraphs];
        newParagraphs.splice(index, 1);
        setParagraphs(newParagraphs);
    };

    const handleParagraphChange = (index, value) => {
        const newParagraphs = [...paragraphs];
        newParagraphs[index].paragraph = value;
        setParagraphs(newParagraphs);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };

            const data = { text: paragraphs };

            const response = await axios.post(
                '/api/admin/letter',
                data,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setIsSubmitted(true);
                setError('');
                setLetter(paragraphs)
            }
        } catch (error) {
            console.log('Error')
            setError('Error')
        } finally {
            setLoading(false);
        }
    }

    const handleClose = () => {
        if(isSubmitted) {
            setChildChanges((prevChanges) => !prevChanges);
        }
        setNewLetterOpen(false);
        setIsSubmitted(false);
        setError('');
        setLoading(false);
        setParagraphs([{ paragraph: '' }]);
    }


    return (
        <>
            <Dialog
                aria-labelledby="add-eng-letter-modal"
        	    aria-describedby="add-eng-letter-modal"
        	    open={open}
			    onClose={handleClose}
                fullWidth
                maxWidth="md"
                id='add-eng-letter-modal'

            >
                <Container sx={{ m: 0, p: 2 }}>
                    <Grid container alignItems="center" justifyContent="space-between" mb={3}>
                        <Grid item>
							<Chip color="success" label="Add Letter" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseRoundedIcon />
							</IconButton>
						</Grid>
					</Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            {!isSubmitted ? (
                                <>
                                    <Grid container justifyContent="center" mb={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='body1'>Add New Engagement Letter</Typography>
                                        </Grid>
                                        {error && <Typography className='dialog-error-msg' color="secondary" variant='body1' textAlign="center">{error}</Typography>}
					                </Grid>
                                    <Box component="form" onSubmit={handleSubmit}>
                                        {paragraphs.map((item, i) => (
                                            <Box key={i}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Typography variant='body2'>Paragraph #{i + 1}</Typography>
                                                    <IconButton onClick={() => insertParagraph(i + 1)}>
                                                        <AddIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => removeParagraph(i)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    required
                                                    value={item.paragraph}
                                                    onChange={(e) => handleParagraphChange(i, e.target.value)}
                                                />
                                            </Box>
                                        ))}
                                        <Grid container justifyContent="flex-end" mt={2}>
                                            <Grid item>
                                                <Button 
                                                    className='admin-submit-button' 
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <>
                                                            Save&nbsp;
                                                            <CircularProgress size={18} color="inherit" />
                                                        </>
                                                    ) : (
                                                        'Save'
                                                    )}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            ) : (
                                <Box className='success-msg'>
                                    <Grid container flexDirection="column" alignItems="center" justifyContent="center">
                                        <Grid item mb={2}>
                                            <img src={SuccessIcon} />
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                Success! Engagement Letter has been saved.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </>
    )
}

export default AddEngLetter;