import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

import SuccessAlert from '../../../ui/SuccessAlert';


const PersonalSettings = ({ userToken, personalInfo, setPersonalInfo }) => {

    const [editMode, setEditMode] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleEditMode = (e) => {
        e.preventDefault();
        setEditMode(!editMode)
    }   

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const config = {
            headers: {
              Authorization: `Bearer ${userToken}`,
            }
        }
        try {
            const updatedUser =  axios.post(`/api/client/settings`, personalInfo, config);
            setUpdated(true);
            setLoading(false);
            setEditMode(!editMode)
        } catch (err) {
            setError(true);
            setLoading(false);
        }
    }  

    const handleChange = (e) => {
        setPersonalInfo({
            ...personalInfo,
            [e.target.name]: e.target.value
        });
    }

    return (
        <>
            <Container>
                <Grid container justifyContent="space-between" mb={5}>
                    <Grid item>
                        <Typography component="h3">
                            Personal Information
                        </Typography>
                    </Grid>
                    <Grid item>
                        {!editMode ? 
                            <Button onClick={handleEditMode} className="edit-button" startIcon={<EditIcon />}>Edit</Button> : null
                        }
                    </Grid>
                </Grid>
                {personalInfo && (
                    <Box component="form" onSubmit={handleSubmit}>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={6} container>
                                <Grid item xs={12} mb={3}>
                                    <TextField 
                                        fullWidth 
                                        name="firstName"
                                        required 
                                        size='small'
                                        value={personalInfo.firstName}
                                        label="First Name"
                                        disabled={!editMode}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} mb={3}>
                                    <TextField 
                                        fullWidth 
                                        name="email"
                                        required 
                                        size='small'
                                        value={personalInfo.email}
                                        label="Email"
                                        disabled={!editMode}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} container>
                                <Grid item xs={12} mb={3}>
                                    <TextField 
                                        fullWidth 
                                        name="lastName"
                                        required 
                                        size='small'
                                        value={personalInfo.lastName}
                                        label="Last Name"
                                        disabled={!editMode}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} mb={3}>
                                    <TextField 
                                        fullWidth 
                                        name="phone"
                                        required 
                                        size='small'
                                        value={personalInfo.phone}
                                        label="Phone #"
                                        disabled={!editMode}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {editMode && (
                            <Grid container>
                                <Grid item xs={12} container justifyContent="flex-end" columnGap={2}>
                                    <Grid item>
                                        <Button onClick={handleEditMode} variant="contained" className='cancel-btn'>Cancel</Button>
                                    </Grid>
                                    <Grid>
                                        <Button variant="contained" className='save-btn' type='submit'>
                                            {loading ? (
                                                <>
													Save&nbsp;
													<CircularProgress size={16} color="inherit" />
												</>
                                            ) : (
                                                'Save'
                                            )}
                                        </Button>
                                    </Grid>  
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                )}
            </Container>
            {updated && <SuccessAlert show={updated} message={"Your information was successfully updated!"} />}
        </>
    )
}

export default PersonalSettings;