import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useFetchData } from '../../../hooks/useFetchData';
import GeneralSignaturePad from './GeneralSignaturePad';
import ErrorAlert from '../../ui/ErrorAlert'


const GeneralEngagementLetter = ({ userToken, handleNextStep }) => {
  	const { userInfo } = useSelector((state) => state.user);
    const [loading, data, error] = useFetchData('/api/general/letter', userToken);
  	const [letter, setLetter] = useState(null);
  	const [openSignaturePad, setOpenSignaturePad] = useState(false);
  	const [imageURL, setImageURL] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(null);

 	useEffect(() => {
    	if(data){
        	setLetter(data.text);
    	}
  	}, [data])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
                'Content-Type': 'multipart/form-data'
            }
        };
    
        const formData = new FormData();
        
        try {
            const base64 = await fetch(imageURL);
            const blob = await base64.blob();
            
            if (imageURL) {
                formData.append('files', blob);
    
                const response = await axios.post('/api/client/engagement-letter', formData, config);
    
                if (response.status === 200) {
                    handleNextStep();
                }
            }
            setIsLoading(false);
        } catch (err) {
            setIsError(err.message);
            setIsLoading(false);
        }
    };

  return (
    	<Box>
			<Grid container justifyContent="center">
				<Grid item xs={10}>
					<Box>
						<Card className='engagement-letter-container'>
							{loading && <Typography>Loading..</Typography>}
							{(letter && letter.length > 0) && (
								<Box component="form" onSubmit={handleSubmit}>
									<Grid container className='letter-header' py={2} px={2}> 
										<Grid item>
											<Typography component="h5">
												{"Dear " + userInfo?.firstName + ","}
											</Typography>
										</Grid>
									</Grid>
									<Grid container className='letter-content' py={2} px={2}> 
										{letter.map((item, index) => (
											<Grid item  mb={2} key={index}>
												<Typography component="p" key={index}>
													{item.paragraph}
												</Typography>
											</Grid>
										))}
									</Grid>
									{imageURL ? (
										<>
											<Grid container className='signature-img-container' py={2} px={2}>
												<Grid item>
													<img src={imageURL} />
												</Grid>
											</Grid>
											<Grid container justifyContent="flex-end" py={2} px={2}>
												<Grid item>
													<Button 
														type="submit" 
														className="save-engagement-btn"
                                                        disabled={isLoading}
													>
															{isLoading ? (
                                                                <>
                                                                    Submit&nbsp;
                                                                    <CircularProgress size={18} color="inherit" />
                                                                </>
                                                            ) : (
                                                                'Submit'
                                                            )}
													</Button>
												</Grid>
											</Grid>
										</>
									): (
										<Grid container justifyContent="flex-end" py={2} px={2}>
											<Grid item>
												<Button 
													type="button" 
													className="sign-button" 
													onClick={() => setOpenSignaturePad(true)}
												>
													Sign
												</Button>
											</Grid>
										</Grid>
									)}
								</Box>
							)}
						</Card>
					</Box>
				</Grid>
				<GeneralSignaturePad 
                    openSignaturePad={openSignaturePad} 
                    setOpenSignaturePad={setOpenSignaturePad} 
                    setImageURL={setImageURL}  
                />
			</Grid>
            {isError && <ErrorAlert show={isError === true} message="Sorry, we were unable to save your engagement letter. Please retry or contact support." />}
    	</Box>
  	)
}

export default GeneralEngagementLetter;