import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';

import { useFetchData } from '../../../hooks/useFetchData';

import ViewFreeQuote from './ViewFreeQuote';
import DeleteFreeQuote from './DeleteFreeQuote';
import LoadingList from '../../ui/LoadingList';
import EmptyDataComponent from '../../ui/EmptyDataComponent';

const FreeQuote = () => {

    const { userToken } = useSelector((state) => state.user)
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/admin/free-quote', userToken, childChanges);
    const [currentItem, setCurrentItem] = useState(null);
    const [isViewMode, setViewMode] = useState(false);
    const [isDeleteMode, setDeleteMode] = useState(false);

    const handleViewOpen = (item) => {
        setViewMode(true);
        setCurrentItem(item);
    }
    const handleDeleteOpen = (item) => {
        setDeleteMode(true);
        setCurrentItem(item);
    }

    return (
        <>
            <Box className='freeQuote-wrapper' id="freeQuote-wrapper">
                <Grid container>
                    <Grid item>
                        <Typography component='h1'>
                            Free Quote Requests
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container mt={3} mb={3}>
                    <Grid item xs={12}>
                        {loading ? (
                            <LoadingList />
                        ) : error ? (
                            <Typography component="p" color="error">
                                An error occurred while fetching data: {error.message}
                            </Typography>
                        ) : data ? (
                            data.length ? (
                                <List className="free-quote-list">
                                    {data.map((item, index) => (
                                        <ListItem key={index} className="quote-list-item">
                                            <Chip 
                                                label={item.complete ? 'completed' : 'pending'}
                                                className={item.complete ? 'completed' : 'pending'} 
                                            />
                                            <Typography component="p">{item.firstName} {item.lastName}</Typography>
                                            <Typography component="span">{item.date}</Typography>
                                            <ListItemSecondaryAction>
                                                <IconButton onClick={() => handleViewOpen(item)}>
                                                    <VisibilityIcon color="primary" />
                                                </IconButton>
                                                <IconButton onClick={() => handleDeleteOpen(item)}>
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <EmptyDataComponent msg="It looks like there are no free quote requests at the moment. Once you receive some, they'll be displayed here." />
                            )
                        ) : (
                            <Typography component="p" color="error">
                                An unexpected error occurred
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <ViewFreeQuote
                open={isViewMode}
                setOpen={setViewMode}
                currentItem={currentItem}
                setChildChanges={setChildChanges}
                userToken={userToken}
            />
            <DeleteFreeQuote
                open={isDeleteMode}
                setOpen={setDeleteMode}
                currentItem={currentItem}
                setChildChanges={setChildChanges}
                userToken={userToken}
            />
        </>
    )

}

export default FreeQuote;