import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { getUserDetails } from '../../../../../actions/authActions';

import { maskEin } from '../../../../../utils/maskEin';
import SuccessIcon from '../../../../../assets//icons/check.png';

const einRegex = /^\d{2}-\d{7}$/;

const AddNewBusiness = ({ open, setOpen, userToken, id, setBusinesses, setChildChanges }) => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [newBusiness, setNewBusiness] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState({
        businessName: "",
        ein: ""
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if(isSubmitted) {
            dispatch(getUserDetails());
        }
    }, [isSubmitted])

    const handleClose = (event) => {
        event.stopPropagation();
        setOpen(false);
        setNewBusiness({});
        setError('');
        setLoading(false);
        setIsSubmitted(false);
    }

    const handleChange = (event) => {
        event.stopPropagation();
        const { name } = event.target;
        let modifiedValue = event.target.value; // Use let instead of const
    
        if (event.target.name === "ein") {
            maskEin(event);
            modifiedValue = event.target.value; 
        }
    
        setNewBusiness((prevBusiness) => ({
            ...prevBusiness,
            [name]: modifiedValue,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

         // Validation
        let errors = {};
        if (!newBusiness.businessName || newBusiness.businessName.trim() === "") {
            errors.businessName = "Business Name is required.";
        }
        if (!newBusiness.ein || newBusiness.ein.trim() === "") {
            errors.ein = "EIN is required.";
        } else if (!einRegex.test(newBusiness.ein)) {
            errors.ein = "EIN format should be XX-XXXXXXX.";
        }
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            setLoading(false);
            return; // stop execution if there are validation errors
        }

        newBusiness.status = 0;
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                '/api/c/dashboard/business/add',
                newBusiness,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setIsSubmitted(true);
                setError('');
                setBusinesses((prevBusinesses) => [
                    ...prevBusinesses, 
                    { ...newBusiness, userStatus: true }
                ]);
                setChildChanges((prevChanges) => !prevChanges);
            }
        } catch (error) {
            console.log('Error')
            setError(error.response.data.error)
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog
            aria-labelledby="add-new-business-modal"
            aria-describedby="add-new-business-modal"
            id="admin-business-dialog"
            open={open}
            // onClose={handleClose}
            fullWidth
            onClick={(e) => e.stopPropagation()}
        >
            <Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                <Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header" mb={3}>
					<Grid item>
						<Chip color="success" label="Add Business" />
					</Grid>
					<Grid item>
						<IconButton onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
                {!isSubmitted ? (
                    <>
                        {error && <Typography className='dialog-error-msg' color="secondary" variant='body1' textAlign="center">{error}</Typography>}
                        <Box component="form" onSubmit={handleSubmit}>
                            <Grid container>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="Business Name"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="businessName"
                                        required
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.businessName || ''}
                                        error={!!validationErrors.businessName} 
                                        helperText={validationErrors.businessName}
                                     />
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="EIN"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="ein"
                                        required
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.ein || ''}
                                        error={!!validationErrors.ein} 
                                        helperText={validationErrors.ein}
                                     />
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="DBA"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="dba"
                                        helperText="Doing Business As"
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.dba || ''}
                                     />
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <TextField
                                        label="FKA"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        name="fka"
                                        helperText="Formerly Known As"
                                        onChange={handleChange}
                                        onClick={(e) => e.stopPropagation()}
                                        value={newBusiness.fka || ''}
                                     />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Button 
                                        type="submit" 
                                        className='submit-btn'
                                        onClick={(e) => e.stopPropagation()}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <>
                                                Save&nbsp;
                                                <CircularProgress size={18} color="inherit" />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <Box className='success-msg'>
						<Grid container flexDirection="column" alignItems="center" justifyContent="center">
							<Grid item mb={2}>
								<img src={SuccessIcon} />
							</Grid>
							<Grid item>
								<Typography>
									Success! New Business has been saved.
								</Typography>
							</Grid>
						</Grid>
                    </Box>
                )}
            </Container>
        </Dialog>
    )
}

export default AddNewBusiness;