import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import PasswordIcon from '@mui/icons-material/Password';


import PersonalSettings from './AllSettings/PersonalSettings';

import '../client.css'
import SettingsIcon from '../../../assets/settings.png';
import PasswordSettings from './AllSettings/PasswordSettings';
import BusinessSettings from './AllSettings/BusinessSettings';
import LoadingGrid from '../../ui/Loading.js'
import { Typography } from '@mui/material';

import { useFetchData } from '../../../hooks/useFetchData';

const ClientSettings = () => {

    const { userToken } = useSelector((state) => state.user);
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/client/settings', userToken, childChanges);
    const [value, setValue] = useState('1');
    const [personalInfo, setPersonalInfo] = useState(null);
    const [businessInfo, setBusinessInfo] = useState([]);
    const [businessFormValues, setBusinessFormValues] = useState({});
    const location = useLocation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        // Check if "business" query parameter exists
        const isBusiness = query.get('business');

        setValue(isBusiness ? '2' : '1');
    }, [location]);

    useEffect(() => {
        if(data) {
            setPersonalInfo({
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                email: data.email,
                role: data.role
            })
            setBusinessInfo(data.businesses)
        }
    }, [data])

    useEffect(() => {
        setBusinessFormValues(
            businessInfo.reduce((prev, curr) => ({...prev, [curr._id]: curr}), {})
        );
    }, [businessInfo]);


    if (loading) {
        return <LoadingGrid />;
    }

    return(
        <>
            {!!data && (
                <Box id="settings-page">
                    <Grid container mb={5}>
                        <Grid item xs={3}>
                            <Typography component='h1'>
                                Settings
                            </Typography>
                        </Grid>
                    </Grid>    
                    <Box>
                        <TabContext value={value}>
                            <Grid container sx={{ width: '100%' }} className='settings-container'>
                                <Grid item xs={2}>
                                    <TabList
                                        onChange={handleChange}
                                        orientation="vertical"
                                    
                                    >
                                        <Tab value="1" icon={<AccountCircleIcon />} iconPosition="start" label="Account"/>
                                        {data.role === 'user' && data.businesses.length > 0 && (
                                            <Tab value="2" icon={<WorkIcon />} iconPosition="start" label="Business"/>
                                        )}
                                        <Tab value="3" icon={<PasswordIcon />} iconPosition="start" label="Password"/>
                                    </TabList>
                                </Grid>
                                <Grid item xs={10}>
                                    <TabPanel value="1">
                                        <PersonalSettings
                                            personalInfo={personalInfo}
                                            setPersonalInfo={setPersonalInfo}
                                            userToken={userToken}
                                        />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <BusinessSettings
                                            businesses={businessInfo}
                                            userToken={userToken}
                                            setBusinessInfo={setBusinessInfo}
                                            businessFormValues={businessFormValues}
                                            setBusinessFormValues={setBusinessFormValues}
                                        />
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <PasswordSettings
                                            userToken={userToken}
                                        />
                                    </TabPanel>
                                </Grid>  
                            </Grid>
                        </TabContext>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default ClientSettings;