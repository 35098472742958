import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';

import { capitalize } from '@mui/material';

const EditFileInfo = ({ open, setOpen, currentFile, businesses, folders, setChildChanges }) => {

    const { userToken } = useSelector((state) => state.user);
    const [state, setState] = useState({});
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [isUpdated, setIsUpdated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [fileExtension, setFileExtension] = useState("");
    const modalRef = useRef();

    // useEffect(() => {
    //     if(businesses) {
    //         console.log(businesses)
    //     }
    // }, [businesses])
    const getFileNameWithoutExtension = (filename) => {
        const parts = filename.split('.');
        if (parts.length > 1) {
            parts.pop();
        }
        return parts.join('.');
    };

    useEffect(() => {
        if(currentFile && currentFile.business) {
            setSelectedBusiness(currentFile.business)
        }
    }, [currentFile])

	const handleClose = () => {
        setChildChanges((prevChanges) => !prevChanges);
		setOpen(false);
        setIsUpdated(false);
        setError('');
        setState({
            file_name: currentFile ? currentFile.file_name : '',
            folder: currentFile ? currentFile.folder : '',
            year: currentFile ? currentFile.year : '',
        });
        setSelectedBusiness('');
	}

    useEffect(() => {
        if(currentFile) {
            const fileNameWithoutExtension = getFileNameWithoutExtension(currentFile.file_name);
            const extension = currentFile.file_name.split('.').pop();
            setState({
                file_name: fileNameWithoutExtension,
                folder: currentFile ? currentFile.folder : '',
                year: currentFile ? currentFile.taxYear : '',
            })
            setFileExtension(extension);
        }
    }, [currentFile])

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState({ ...state, [name]: value });
    }

    const handleBusinessChange = (event) => {
        setSelectedBusiness(event.target.value);
    };

    const postData = async (e) => {
		e.preventDefault();
		setLoading(true);
		const unanswered = [];

        if(unanswered.length === 0) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                };
                const data = {
                    ...state,
                    file_name: state.file_name + '.' + fileExtension,
                    business: selectedBusiness
                };
                const response = await axios.post(
                    `/api/client-page/files/edit/${currentFile._id}`,
                    data,
                    config
                );
                if (response.data.error) {
                    setError(response.data.error);
                } else {
                    setIsUpdated(true);
                    setError('');
                }
            } catch (error) {
                console.log('Error')
                setError('Error')
            }
        } else {
            // markUnansweredQuestions();
            setError('Please, provide answers for all questions')
        }
		setLoading(false);
	};

	return (
		<Dialog
			aria-labelledby="admin-doc-edit-modal"
        	aria-describedby="admin-doc-edit-modal"
            id="admin-doc-edit-modal"
        	open={open}
			onClose={handleClose}
            fullWidth
            maxWidth="md"
		>
			<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }} ref={modalRef}>
                <Grid container justifyContent="space-between" alignItems="center" className="edit-dialog-header">
                    <Grid item>
                        <Chip color="warning" label="Edit Mode" />
                    </Grid>
					<Grid item>
						<IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
					</Grid>
				</Grid>
                {error && <Typography className='dialog-error-msg'>Could not update the file information. Please try again later.</Typography>}
                {isUpdated ? (
                    <Box className="admin-doc-modal">
                        <DialogSuccessMsg msg="Changes saved successfully." />
                    </Box>
                ) : (
                    <Box component="form" onSubmit={postData}>
                        <Grid container mt={5} mb={5} justifyContent="center">
                            <Grid item xs={7} mb={2}>
                                <TextField label="File Name" fullWidth required value={state.file_name} name="file_name" onChange={handleInputChange} />   
                            </Grid> 
                            <Grid item xs={7} mb={2}>
                                <TextField label="Year" fullWidth required value={state.year} name="year" onChange={handleInputChange} />   
                            </Grid> 
                            {folders && folders.length > 0 ? (
                                <Grid item xs={7} mb={2}>
                                    <TextField 
                                        select 
                                        name="folder" 
                                        label="Folder" 
                                        fullWidth 
                                        value={state.folder ? state.folder : ''}
                                        onChange={handleInputChange}
                                    >
                                        {folders.map((item) => (
                                            <MenuItem value={item} key={item}>
                                                    {capitalize(item)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> 
                            ) : null} 
                            {businesses && businesses.length > 0 ? (
                                <Grid item xs={7} mb={2}>
                                    <TextField 
                                        select 
                                        name="business" 
                                        label="Add Business" 
                                        fullWidth 
                                        value={selectedBusiness}
                                        onChange={handleBusinessChange}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {businesses.map((item) => (
                                            <MenuItem value={item._id} key={item._id}>
                                                    {item.businessName}
                                                    {item.status === 1 && 
                                                        <Chip 
                                                            label="Archived" 
                                                            color="secondary"
                                                            size="small" 
                                                            style={{ marginLeft: '10px' }} 
                                                        />
                                                    }
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid> 
                            ) : null} 
                            <Grid item xs={10} container justifyContent="flex-end">
                                <Grid item>
                                    <Button 
                                        className='submit-button' 
                                        type="submit"
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <>
                                                Save&nbsp;
                                                <CircularProgress size={18} color="inherit" />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}
			</Container>
		</Dialog>
	)
};

export default EditFileInfo;