import React, { useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import HistoryIcon from '@mui/icons-material/History';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PaidIcon from '@mui/icons-material/Paid';
import MailIcon from '@mui/icons-material/Mail';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Tooltip from '@mui/material/Tooltip';

import ClientClosedMenu from "./ClientDrawerMenu/ClosedMenu";
import ClientOpenedMenu from "./ClientDrawerMenu/OpenedMenu";


const drawerWidth = 240;

const clientMenu = [
    {
		menuItem: 'My account',
		menuIcon: <AccountBoxIcon />,
        path: '/dashboard/my-account'
	},
	{
		menuItem: 'History',
		menuIcon: <HistoryIcon />,
        path: '/dashboard/history'
	}, 
	{
		menuItem: 'Documents',
		menuIcon: <FileCopyIcon />,
        path: '/dashboard/documents'
	},
	{
		menuItem: 'Refunds',
		menuIcon: <PaidIcon />,
        path: '/dashboard/refunds'
	},
	{
		menuItem: 'Invite',
		menuIcon: <MailIcon />,
        path: '/dashboard/invite'
	}
]

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



const ClientDashboard = () => {
 	const [open, setOpen] = useState(true);
    const { userInfo, userToken } = useSelector((state) => state.user);
    const [showSubmenu, setShowSubmenu] = useState(false);
       
	const handleDrawer = () => {
		setOpen(!open);
        // if(setShowSubmenu) {
        //     setShowSubmenu(false)
        // }
	};

    const location = useLocation();

    const checkIsActive = (path) => {
        // Split the given path into pathname and search parts
        const [pathName, search = ''] = path.split('?');
    
        // Check if pathname matches
        if (location.pathname !== pathName) {
            return false;
        }
    
        // If there are query parameters in the given path, check them too
        if (search && location.search !== '?' + search) {
            return false;
        }
    
        return true;
    };

  	return (
    	<Box sx={{ display: 'flex' }} id="drawer-container">
      		<Drawer variant="permanent" open={open}>
                {open ? (
                    <ClientOpenedMenu 
                        clientMenu={clientMenu}
                        userInfo={userInfo}
                        handleDrawer={handleDrawer}
                        setShowSubmenu={setShowSubmenu}
                        showSubmenu={showSubmenu}
                        checkIsActive={checkIsActive}
                    />

                ) : (
                    <ClientClosedMenu
                        handleDrawer={handleDrawer}
                        checkIsActive={checkIsActive}
                        setShowSubmenu={setShowSubmenu}
                        showSubmenu={showSubmenu}
                        userInfo={userInfo}
                    />
                )}
      		</Drawer>
      		<Box className="client-dashboard" component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Outlet />
  			</Box>
    	</Box>
  	);
}


export default ClientDashboard;