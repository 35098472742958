import React from 'react';


const ErrorMsg = ({ msg }) => {

    return (
        <div>
            <h1>{msg}</h1>
        </div>
    )
}

export default ErrorMsg;