import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FreeTag from '../../assets/homepage/free-tag11.png'

const FreeQuoteContainer = () => {
    return (
        <Grid container className="free-quote-section" mt={2} alignItems="center" justifyContent="center">
				<img src={FreeTag} />
				<Grid item xs={12}>
					<Typography component="h3">
						GET YOUR FREE QUOTE
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography component="p">
						Who's ever turned down something free? Simply send us a message and we will be more than happy to provide an estimate for whatever you need
					</Typography>
				</Grid>
				<Grid item mt={3}>
					<Button
						component={Link}
						to="/free-quote"
						className="get-started-button"
						variant="contained"
					>
						Get Started
					</Button>

				</Grid>
			</Grid>
    )
}

export default FreeQuoteContainer;