import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useFetchData } from '../../../../hooks/useFetchData';


import PersonalInfo from './PersonalInfo';
import ReturnType from './ReturnType';
import BusinessInfo from './ClientBusiness';


const ClientProfile = ({ clientInfo, setChildChanges }) => {

    const { id } = useParams();
    const location = useLocation();
    const { userToken } = useSelector((state) => state.user);
    const { clientDetails } = useSelector((state) => state.adminClient);
    // const currentPath = location.pathname;
    // const queryParams = new URLSearchParams(location.search);
    // const userType = queryParams.get('userType');
    const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
    const [returnTypeOpen, setReturnTypeOpen] = useState(true);
    const [businessTitlesOpen, setBusinessTitlesOpen] = useState(true);
    const [currentReturnType, setCurrentReturnType] = useState(clientDetails?.returnType || [])
    const [businesses, setBusinesses] = useState(clientDetails?.businesses || [])

    // useEffect(() => {
    //     if(clientDetails) {
    //         console.log('Client Details', clientDetails)
    //     }
    // }, [clientDetails])

    useEffect(() => {
        if (clientDetails) {
            setCurrentReturnType(clientDetails.returnType || []);
            setBusinesses(clientDetails.businesses || []);
        }
    }, [clientDetails]);

    const toggleSection = (section) => {
        switch (section) {
            case 'personalInfo':
                setPersonalInfoOpen(!personalInfoOpen);
                break;
            case 'returnType':
                setReturnTypeOpen(!returnTypeOpen);
                break;
            case 'businessTitles':
                setBusinessTitlesOpen(!businessTitlesOpen);
                break;
            default:
            break;
        }
    };

    const capitalize = (str) => {
        str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <>
            <Box id='personal-info'>
                <Grid container justifyContent="space-between" alignItems="center" mb={5}>
                    <Grid item>
                        <Typography component="h1">
                            Client Profile
                        </Typography>
                    </Grid>
                    {!!clientDetails && clientDetails?.mergedAccount === 2 && (
                        <Grid item className='inactive-account-msg' mt={1}>
                                <Typography component="span">
                                    Inactive account
                                </Typography>
                                <Tooltip title="Inactive Account: Active Merged Account. This account can still have active businesses.">
                                    <HelpOutlineIcon />
                                </Tooltip>
                        </Grid>
                    )}
                    {(!!clientDetails && clientDetails.mergedAccount === 1 && clientDetails.maritalDetails.spouseEmail) && (
                         <Grid item className='inactive-account-msg' mt={1}>
                            <Typography component="span">
                                Waiting to be merged
                            </Typography>
                            <Tooltip title="Account should be merged with spouse account">
                                <HelpOutlineIcon />
                            </Tooltip>
                    </Grid>
                    )}
                </Grid>
                {!!clientDetails ? (
                    <>
                        <Box  className={!personalInfoOpen ? 'section' : 'section open'} onClick={() => toggleSection('personalInfo')}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Personal Information</Typography>
                                {!personalInfoOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={personalInfoOpen} timeout={500} unmountOnExit>
                                <PersonalInfo clientInfo={clientDetails} />
                            </Collapse>
                        </Box>
                        <Box  className={!returnTypeOpen ? 'section' : 'section open'} onClick={() => toggleSection('returnType')}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Return Type</Typography>
                                {!returnTypeOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={returnTypeOpen} timeout={500} unmountOnExit>
                                <ReturnType 
                                    clientInfo={clientDetails} 
                                    userToken={userToken} 
                                    id={id} 
                                    currentReturnType={currentReturnType}
                                    setCurrentReturnType={setCurrentReturnType}
                                />
                            </Collapse>
                        </Box>
                        {clientDetails.user_type === 1 && (

                        
                        <Box className={!businessTitlesOpen ? 'section' : 'section open'} onClick={() => toggleSection('businessTitles')}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" className="section-header">
                                <Typography component="h3">Businesses</Typography>
                                {!businessTitlesOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                            </Box>
                            <Collapse in={businessTitlesOpen} timeout={500} unmountOnExit>
                                <BusinessInfo 
                                    userToken={userToken} 
                                    id={id} 
                                    businesses={businesses}
                                    setBusinesses={setBusinesses}
                                    userType={clientDetails.mergedAccount}
                                    userId={clientDetails._id}
                                    setChildChanges={setChildChanges}
                                />
                            </Collapse>
                        </Box>
                        )}
                    </>
                ): null}
            </Box>
        </>
    )

}

export default ClientProfile;