import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


const ViewDialogContent = ({ business, onClose }) => {

    // useEffect(() => {
    //     if(business) {
    //         console.log(business)
    //     }
    // }, [business])

    return (
        <Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }} className='client-view-business-dialog'>
            <Grid container justifyContent="space-between" mb={4}>
                <Grid item>
                    <Chip 
                        color={!business.userStatus ? "error" : "success"} 
                        label={!business.userStatus ? "Not active" : "Active"} 
                        // className={business.frozen ? 'not-active-tag' : 'active-tag'} 
                    />
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
            <Grid container > 
                {!business.userStatus && (
                    <Grid item xs={12} container alignItems="center" mb={1}>
                        <Grid item xs={5}>
                            <Typography variant="h6">Deactivated since:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="p">{business.frozenAt}</Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} container alignItems="center" mb={1}>
                    <Grid item xs={5}>
                        <Typography variant="h6">Business Name:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="p">{business.businessName}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" mb={1}>
                    <Grid item xs={5}>
                        <Typography variant="h6">Business EIN:</Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="p">{business.ein}</Typography>
                    </Grid>
                </Grid>
                {business.dba && (
                    <Grid item xs={12} container alignItems="center" mb={1}>
                        <Grid item xs={5}>
                            <Typography variant="h6">DBA:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="p">{business.dba}</Typography>
                        </Grid>
                    </Grid>
                )}
                {business.fka && (
                    <Grid item xs={12} container alignItems="center" mb={1}>
                        <Grid item xs={5}>
                            <Typography variant="h6">FKA:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography component="p">{business.fka}</Typography>
                        </Grid>
                    </Grid>
                )}
                </Grid>
                {business.activeUsers.length > 0 && business.userStatus && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h5" color="textSecondary" className='other-users-title'>
                            Other Users
                        </Typography>    
                        <List className='users-list'>
                            {business.activeUsers.map((user, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={user.fullName} secondary={`Permissions: ${user.permissions}`} />
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
            </Box>
        </Container>
    )
}

export default ViewDialogContent;