import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';

import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { useFetchData } from '../../../hooks/useFetchData';

import RegClientsList from './RegClientList';
import MergedClientsList from './MergedClientList';

const ClientsList = () => {

	const { userToken } = useSelector((state) => state.user)
	const [loading, data, error] = useFetchData('/api/admin/clients/all', userToken);
  	const [searchQue, setSearchQue] = useState('');
	const [value, setValue] = useState('1');


	const handleButtonChange = (event) => {
        event.stopPropagation()
		const newValue = event.currentTarget.value;
        setValue(newValue);
        setSearchQue('');
	};

  	const handleChange = e => {
   	 	setSearchQue(e.target.value);
  	};

  	return (
		<>
      		<Box className='clientsList-wrapper' id="clientsList-wrapper">
       	 		<Grid container columnGap={3} alignItems="center" justifyContent="space-between">
          			<Grid item>
            			<Typography component='h1'>
              				Client List
            			</Typography>
          			</Grid>
          			<Grid item xs={6}>
            			<TextField 
							id="outlined-basic"
							label="Search client"
							size="small" 
							variant="outlined"
							name="search-client"
                            value={searchQue}
							InputProps={{ 
								className: 'field',
							}}
							onChange={handleChange} 
						/>
          			</Grid>
                    <Grid item>
                        <ButtonGroup className='change-view-btn-wrapper'>
                            <Tooltip title="Individual/Business Clients">
                                <Button 
                                    value="1" 
                                    onClick={handleButtonChange}
                                    className={value === '1' ? 'active' : ''}
                                >
                                        <PersonIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Merged Clients">
                                <Button 
                                    value="2" 
                                    onClick={handleButtonChange}
                                    className={value === '2' ? 'active' : ''}
                                >
                                    <PeopleAltIcon />
                                </Button>
                            </Tooltip> 
                        </ButtonGroup>

                    </Grid>
				</Grid>

                {value === '1' && (
                    <RegClientsList
                        data={data?.regularClients}
                        searchQue={searchQue}
                    />
                )}

                {value === '2' && (
                    <MergedClientsList
                        data={data?.mergedClients}
                        searchQue={searchQue}
                    />
                )}

                


      		</Box>
    	</>
  	)
};

export default ClientsList;