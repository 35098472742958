import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux";

import { useFetchData } from '../../../../hooks/useFetchData';

import Folders from '../GeneralDoc/Folders';
import ClientFiles from '../GeneralDoc/ClientFiles';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
  


const ClientBusinessDocuments = () => {

    const { userToken } = useSelector((state) => state.user);
    const { clientDetails } = useSelector((state) => state.adminClient);
    const [childChanges, setChildChanges] = useState(false);
    const { id } = useParams();
    let query = useQuery();
    let businessId = query.get("businessId");

    const [loading, data, error] = useFetchData(`/api/client-page/business/documents/${id}/${businessId}`, userToken, childChanges);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        if (data) {
          const folderNames = data.files.map((item) => item.name);
          setFolders(folderNames);
        }
    }, [data, businessId]);

    // Change view if business menu item changes
    useEffect(() => {
        if(businessId) {
            setSelectedFolder(null)
        }
    }, [businessId])
  

    return (
        <>
            <Box>
                {loading && <p>Loading...</p>}
                {!!clientDetails && data && !selectedFolder ? (
                    <Folders 
                        data={data?.files}
                        businessName={data?.businessName}
                        isDocumentUploadActive={data?.userStatus}
                        businessId={businessId}
                        setChildChanges={setChildChanges}
                        setSelectedFolder={setSelectedFolder} 
                        folders={folders}
                    />
                ) : (
                    // null
                    <ClientFiles 
                        data={data?.files} 
                        selectedFolder={selectedFolder} 
                        setSelectedFolder={setSelectedFolder} 
                        folders={folders}
                        setChildChanges={setChildChanges}
                        // businesses={clientDetails?.businesses} 
                        businessId={businessId}
                        userToken={userToken} 
                    />
                )} 
            </Box>
        </>

    )
}

export default ClientBusinessDocuments;