import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ResetPassImg from '../../assets/auth/reset-pass.png'

import './auth.css'

const RequestReset = () => {

	const [error, setError] = useState(''); 
	const [emailSent, setEmailSent] = useState(false);
	const [email, setEmail] = useState('');
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleEmailChange = (value) => {
		setEmail(value);
	
		const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		setIsEmailValid(pattern.test(value));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setEmailSent(false)
		setLoading(true)
		try {
			const result = await axios.post('/api/auth/reset', { email: email });
			if (result.status === 200) {
				setLoading(false);
				setEmailSent(true);
				setEmail('');
				setError(false);
			} else {
				setLoading(false);
				setError('Something went wrong. Please, try again later.');
			}
		} catch(e) {
			setLoading(false);
			setError('Something went wrong. Please, try again later')
		}
	}


	return (
		<>
			<Box id='reset-pass'>
				<Grid container className="grid-container" justifyContent="space-around">
					<Grid item className="left-container">
						<img src={ResetPassImg} />
					</Grid>
					<Grid item container className="right-container">
						<Box className='reset-pass-wrapper'>
							<Grid container className='reset-pass-header'>
								<Grid item xs={12}>
                        			<Typography component="h1">Forgot Password?</Typography>
                    			</Grid>
								<Grid item xs={12}>
                        			<Typography component="p">Enter the email associated with your account and we'll send an email with instructions to reset your password.</Typography>
                    			</Grid>
							</Grid>
							<Box component="form" role="form" onSubmit={handleSubmit} className='reset-form'>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<TextField 
											placeholder='Email'
											variant="outlined" 
											name="email"
											fullWidth 
											required 
											value={email}
											onChange={(e) => handleEmailChange(e.target.value)}
											error={!isEmailValid}
											helperText={!isEmailValid && "Invalid email format"}
											InputProps={{
												startAdornment: <InputAdornment position="start">
													<EmailIcon />
												</InputAdornment>,
											}}
										/>
									</Grid>
									<Grid item xs={12} container justifyContent="flex-end">
										<Grid item>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												className="reset-button"
												sx={{ mt: 1, mb: 2 }}
											>
												{loading ? (
													<>
														Submit&nbsp;
														<CircularProgress size={18} color="inherit" />
													</>
												) : (
													'Submit'
												)}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Box>
							{error && (
								<Grid container className='reset-error-container' justifyContent="center">
									<Grid item xs={8}>
										<ErrorOutlineIcon />
										<Typography component="p">{error}</Typography>
									</Grid>
								</Grid>
                        	)}
							{emailSent && (
								<Grid container className='reset-success-container' justifyContent="center">
									<Grid item xs={9}>
										<CheckIcon />
										<Typography component="p">We have sent a password recover instructions to your email.</Typography>
									</Grid>
								</Grid>
                        	)}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
  	)
}

export default RequestReset;