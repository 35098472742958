import { useState, useEffect } from 'react';
import axios from 'axios';

export const useFetchData = (endpoint, userToken, childChanges = null) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                };
                const response = await axios.get(endpoint, config);
                setData(response.data);
            } catch (err) {
                    setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
  }, [endpoint, userToken, childChanges]);

  return [loading, data, error];
};
