import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import DialogSuccessMsg from '../../ui/DialogSuccessMsg';


const groups = ['Personal Information', 'Personal Residence', 'Dependents', 'Work & Income Sources', 'Healthcare', 'Education', 
'Deductions and Credits', 'Investments', 'Retirement or Severance', 'Sale of Your Home', 'Gifts', 'Foreign Matters', 'Miscellaneous' ]

const ViewQuestion = ({ open, setViewOpen, data}) => {

    const handleClose = () => {
        setViewOpen(false)
    }

  	return (
		<>
			<Dialog
				aria-labelledby="add-new-question-modal"
        	    aria-describedby="add-new-question-modal"
				id='add-que-modal'
        	    open={open}
			    onClose={handleClose}
                fullWidth
				fullScreen
			>
				<Container className="new-question-container" sx={{ m: "0 auto", p: 2 }}>
					<Grid container justifyContent="space-between" alignItems="center" className="add-que-header" mb={3}>
						<Grid item>
							<Chip color="success" label="View Question" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseRoundedIcon />
							</IconButton>
						</Grid>
					</Grid>
					{data ? (
						<Box>
							{groups.map((group, i) => (
								<React.Fragment key={i}>
									<Typography
										sx={{
											fontFamily: "Poppins",
											color: "#333",
											bgcolor: "#0a54d41f",
											fontSize: "1.3rem",
											fontWeight: 600,
											py: "5px",
											px: "5px",
											mb: "1rem",
											mt: "1rem"
										}}
									>
										{group}
									</Typography>
									<Grid container>
										{data
											.map((item, index) => ({ ...item, index }))
											.filter((item) => item.group.toLowerCase() === group.toLowerCase())
											.sort((que1, que2) => que1.order - que2.order)
											.map(({ index, ...item }) => (
												<>
												<Grid key={index} item xs={12} container alignItems="center" gap={1} mb={1}>
													<Grid item>
														<Typography
															sx={{
																fontSize: '1.1rem',
																fontFamily: "Poppins",
																color: "#131313",
																fontWeight: 500,
															}}
														>
															{item.text}
														</Typography>
													</Grid>
													{item.new && (
														<Chip sx={{height: "24px"}} label="new" color="success"/>
													)}
													<Grid item>
														<Typography
															sx={{
																fontSize: '0.9rem',
																fontFamily: "Poppins",
																color: "#131313",
																fontWeight: 400,
															}}

														>
															({item.type === 'general' ? 'Yes/No' : 'Multiline'})
														</Typography>

													</Grid>
												</Grid>
												{item.subQuestion && item.subQuestion.length > 0 && (
													<Grid item xs={12} container>
														<Typography
															sx={{
																fontSize: '1rem',
																fontFamily: "Poppins",
																color: "#444",
																fontWeight: 600,
																fontStyle: 'italic',
																pl: "1rem"
															}}
														>
															Sub Questions:
														</Typography>
														{item.subQuestion.map((item, index) => (
															<Grid xs={12} item>
																<Typography
																	sx={{
																		fontSize: '0.9rem',
																		fontFamily: "Poppins",
																		color: "#131313",
																		fontWeight: 400,
																		pl: "1rem"
																	}}
																>
																	{index + 1}. {item.subQuestionText}
																</Typography>
															</Grid>
														))}
													</Grid>
												)}
												{item.note && (
													<Grid>
														<Typography
															sx={{
																fontSize: '1rem',
																fontFamily: "Poppins",
																color: "#BF0000",
																fontWeight: 500,
																fontStyle: 'italic',
																pl: "1rem"
															}}
														>
															Note: {item.note}
														</Typography>

													</Grid>
												)}
												</>
											))
										}
									</Grid>
								</React.Fragment>
							))}
						</Box>
					) : null}
					
                    
					
					
				</Container>
			</Dialog>
		</>
  )
}

export default ViewQuestion;