import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SuccessIcon from '../../assets/icons/check.png'

const DialogSuccessMsg = ({msg}) => {

    return (
        <Box id='success-msg-dialog'>
            <Grid container flexDirection="column" alignItems="center" justifyContent="center">
                <Grid item mb={2}>
                    <img src={SuccessIcon} />
                </Grid>
                <Grid item>
                    <Typography>
                        {msg}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default DialogSuccessMsg;