export const validateForm = (values) => {
    let errors = {};
  
    // Checking if first name is filled
    if (!values.firstName.trim()) {
      	errors.firstName = 'First name is required';
    }
  
    // Checking if last name is filled
    if (!values.lastName.trim()) {
      	errors.lastName = 'Last name is required';
    }
  
    // Checking if email is filled and valid
    if (!values.email.trim()) {
      	errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      	errors.email = 'Email address is invalid';
    }
  
    // Checking if phone is filled
    if (!values.phone.trim()) {
      	errors.phone = 'Phone number is required';
    }
  
    // Checking if password is filled
    if (!values.password.trim()) {
      	errors.password = 'Password is required';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{12,45}$/.test(values.password)) {
		errors.password = 'Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character, and should be at least 12 characters long.';
	}
  
    // Checking if confirm password is filled and matches password
    if (!values.password2.trim()) {
      	errors.password2 = 'Confirm password is required';
    } else if (values.password2 !== values.password) {
      	errors.password2 = 'Passwords must match';
    }
    
  
    return errors;
};