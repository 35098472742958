import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormLabel from "@mui/material/FormLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from "@mui/material/FormControl";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';

import { groups } from '../../../../utils/constants';

const ClientQueEdit = ({ open, setOpen, currentQue, id, userToken, setCurrentQue }) => {

    const [state, setState] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [isUpdated, setIsUpdated] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        if(currentQue) {
            console.log(currentQue)
        }
    }, [currentQue])

	
	const handleClose = () => {
		setOpen(false);
        setIsUpdated(false);
	}

    useEffect(() => {
        setLoading(true)
        if(currentQue) {
            setState(currentQue);
            setLoading(false);
        }
    }, [currentQue])

    useEffect(() => {
		if(isUpdated) {
			modalRef.current.scrollTo(
				{
					top: 0, 
					behavior: "smooth"
				}
			)
		}
	}, [isUpdated])

    const postData = async (e) => {
		e.preventDefault();
		setLoading(true);
		const unanswered = [];
		state.questionnaire.map((question) => {
            if (!question.answer) {
                unanswered.push({ question : question.text, group: question.group });
            }
	    });

        if(unanswered.length === 0) {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                };
                const response = await axios.post(
                    `/api/client-page/questionnaire/edit/${currentQue._id}`,
                    state,
                    config
                );
                if (response.data.error) {
                    setError(response.data.error);
                } else {
                    setCurrentQue(state);
                    setIsUpdated(true);
                    setError('');
                }
            } catch (error) {
                console.log('Error')
                setError('Error')
            }
        } else {
            markUnansweredQuestions();
            setError('Please, provide answers for all questions')
        }
		setLoading(false);
	};

    const markUnansweredQuestions = () => {
		const updatedQuestionnaire = state.questionnaire.map((question) => {
			if (!question.answer) {
				return {
					...question,
					unanswered: true,
				};
			} else {
				return {
					...question,
					unanswered: false,
				};
			}
		});
		setState({
			...state,
			questionnaire: updatedQuestionnaire,
		});
	};


    const handleChange = (e, mainIndex, innerIndex) => {
		const { name, value } = e.target;
		const tempQuestionnaire = [...state.questionnaire];
		if (innerIndex !== undefined) {
            if (tempQuestionnaire[mainIndex].answer === 'no') {
                tempQuestionnaire[mainIndex].subQuestion[innerIndex].subQuestionAnswer = '';
            } else {
                tempQuestionnaire[mainIndex].subQuestion[innerIndex].subQuestionAnswer = value;
            }
		} else {
		  	tempQuestionnaire[mainIndex].answer = value;
			tempQuestionnaire[mainIndex].unanswered = false;

            // Reset answers for sub questions when main question is changed to 'no'
            if (value === 'no' && tempQuestionnaire[mainIndex].subQuestion) {
                tempQuestionnaire[mainIndex].subQuestion.forEach(subQuestion => {
                    subQuestion.subQuestionAnswer = '';
                });
            }
		}
		setState({ ...state, questionnaire: tempQuestionnaire });
	};

	return (
		<Dialog
			aria-labelledby="que-viewer-modal"
        	aria-describedby="que-viewer-modal"
        	open={open}
			onClose={handleClose}
            fullWidth
            maxWidth="lg"
            id="client-que-edit-dialog"
		>
			<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }} ref={modalRef}>
                <Grid container justifyContent="space-between" alignItems="center" mb={2}>
                    <Grid item>
                        <Chip color="warning" label="Edit Mode" />
                    </Grid>
					<Grid item>
						<IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
					</Grid>
				</Grid>
                {loading? (
                    <h1>Loading</h1>
                ) : (
                    !isUpdated  ? (
                        <>
                            <Grid container justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography component="h5" textAlign="center">
                                        {`Tax Questionnaire ${currentQue?.taxYear}`}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box>
                                {isUpdated && (
                                    <Typography color="secondary" variant='body1' textAlign="center">Updated</Typography>
                                )}
                                {error && (
                                    <Typography color="secondary" variant='body1' textAlign="center">{error}</Typography>
                                )}
                            </Box>
                            <Box component="form" onSubmit={postData} className="questionnaire-form">
                                <Grid container>
                                    <Grid item xs={12}>
                                        {groups.map((group, i) => (
                                            <Box key={group}>
                                                <Typography className="group-heading" variant="h5">
                                                    {group}
                                                </Typography>
                                                <Grid container mb={5} spacing={2}>
                                                    {state.questionnaire
                                                    .map((item, index) => ({ ...item, index }))
                                                    .filter((item) => item.group.toLowerCase() === group.toLowerCase())
                                                    .sort((que1, que2) => que1.order - que2.order)
                                                    .map(({ index, ...item }) => {
                                                        if (item.type === 'input') {
                                                            return (
                                                                <Grid item xs={10} key={item.text}>
                                                                    <FormControl 
                                                                        fullWidth
                                                                        error={item.unanswered}
                                                                    >
                                                                        <FormLabel>
                                                                            {item.text}
                                                                        </FormLabel>
                                                                        <TextField
                                                                            fullWidth
                                                                            multiline
                                                                            rows={3}
                                                                            name={item.text}
                                                                            value={item.answer}
                                                                            onChange={(e) => handleChange(e, index)}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            );
                                                        } else {
                                                            return (
                                                                <Grid item xs={10} key={item.text}>
                                                                    <FormControl
                                                                        error={item.unanswered}
                                                                    >
                                                                        <FormLabel>
                                                                            {item.text}
                                                                        </FormLabel>
                                                                        <RadioGroup
                                                                            name={item.text}
                                                                            value={item.answer}
                                                                            row
                                                                            onChange={(e) => handleChange(e, index)}
                                                                        >
                                                                            <FormControlLabel
                                                                                value="yes"
                                                                                control={<Radio />}
                                                                                label="Yes"
                                                                            />
                                                                            <FormControlLabel
                                                                                value="no"
                                                                                control={<Radio />}
                                                                                label="No"
                                                                            />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                    {item.subQuestion &&
                                                                    item.subQuestion.length > 0 &&
                                                                    item.answer === 'yes' &&
                                                                    (
                                                                        <>
                                                                            {item.subQuestion.map((item, i) => {
                                                                                if (item.subQuestionType === 'input') {
                                                                                    return (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={10}
                                                                                            key={item.subQuestionText}
                                                                                            ml={2}
																				            mt={2}
                                                                                        >
                                                                                            <FormLabel className="subquestion-label">
                                                                                                {item.subQuestionText}
                                                                                            </FormLabel>
                                                                                            <TextField
                                                                                                fullWidth
                                                                                                className="subquestion"
                                                                                                name={item.subQuestionText}
                                                                                                value={item.subQuestionAnswer}
                                                                                                multiline
                                                                                                rows={3}
                                                                                                onChange={(e) =>
                                                                                                    handleChange(e, index, i)
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                    );
                                                                                } else {
                                                                                    return (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={10}
                                                                                            key={item.subQuestionText}
                                                                                            ml={2}
																				            mt={2}
                                                                                        >
                                                                                            <FormLabel className="subquestion-label">
                                                                                                {item.subQuestionText}
                                                                                            </FormLabel>
                                                                                            <RadioGroup
                                                                                                className="subquestion"
                                                                                                defaultValue="general"
                                                                                                name={item.subQuestionText}
                                                                                                value={item.subQuestionAnswer}
                                                                                                row
                                                                                                onChange={(e) =>
                                                                                                    handleChange(e, index, i)
                                                                                                }
                                                                                            >
                                                                                                <FormControlLabel
                                                                                                    value="yes"
                                                                                                    control={<Radio />}
                                                                                                    label="Yes"
                                                                                                />
                                                                                                <FormControlLabel
                                                                                                    value="no"
                                                                                                    control={<Radio />}
                                                                                                    label="No"
                                                                                                />
                                                                                            </RadioGroup>
                                                                                        </Grid>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </>
                                                                    )}
                                                                </Grid>
                                                            );
                                                        }
                                                    })}
                                                </Grid>
                                            </Box>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Button 
                                            type="submit" 
                                            className='update-client-que-btn'
                                        >
                                                 {loading ? (
                                                    <>
                                                    Save&nbsp;
                                                    <CircularProgress size={18} color="inherit" />
                                                    </>
                                                ) : (
                                                    'Save'
                                                )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <DialogSuccessMsg msg="Questionnaire successfully updated" />
                    )
                )} 
			</Container>
		</Dialog>
	)
};

export default ClientQueEdit;