import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


import { useFetchData } from '../../../hooks/useFetchData';
import LoadingGrid from '../../ui/Loading';
import SplitAccountsDialog from './SplitAccountsDialog';
import EmptyDataComponent from '../../ui/EmptyDataComponent';

const SplitAccounts = ({ userToken, searchQue }) => {

    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/admin/merged-accounts/all', userToken, childChanges);
    const [isSplitMode, setSplitMode] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    const handleSplitOpen = (item) => {
		setCurrentItem(item);
    	setSplitMode(true)
 	}
     const handleSplitClose = () => {
        setSplitMode(false);
        setCurrentItem(null);
        setChildChanges((prevChanges) => !prevChanges);
    }

    const searchedList = !searchQue
        ? data
        : data.filter((client) =>
            client.user1.firstName.toLowerCase().includes(searchQue.toLowerCase()) ||
            client.user1.lastName.toLowerCase().includes(searchQue.toLowerCase()) ||
            client.user2.firstName.toLowerCase().includes(searchQue.toLowerCase()) ||
            client.user2.lastName.toLowerCase().includes(searchQue.toLowerCase())
        );

    if (loading) {
        return <LoadingGrid />;
    }

    if(data && data.length === 0) {
        return (
            <EmptyDataComponent msg="It looks like you haven't merged any clients yet. Once you do, you'll see them listed here." />
        )
    }
    

    if (data && data.length > 0) {
        return (
            <>
                <Box id="split-merged-accounts">
                    <Grid container columnGap={2}>
                        {searchedList.map((account) => (
                            <Grid item container justifyContent="space-between" alignItems="center" xs={5} key={account._id}  className="merged-account-item">
                                <Grid item>
                                    <Box className='merged-account-info'>
                                        <Typography component="h5">Name:</Typography>
                                        <Typography component="span">{account.user1.firstName} {account.user1.lastName}</Typography>
                                    </Box>
                                    <Box className='merged-account-info'>
                                        <Typography component="h5">Name:</Typography>
                                        <Typography component="span">{account.user2.firstName} {account.user2.lastName}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                <Tooltip title="Split Accounts">
                                    <IconButton onClick={() => handleSplitOpen(account)}>
                                        <CallSplitIcon />
                                    </IconButton>
                                </Tooltip>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <SplitAccountsDialog 
                    open={isSplitMode}
                    currentItem={currentItem}
                    setCurrentItem={setCurrentItem}
                    handleClose={handleSplitClose}
                    userToken={userToken}
                />
            </>
        );
    }

}

export default SplitAccounts;