import React, { useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';

import { customTrim } from '../../../utils/custom-trim';

import DialogSuccessMsg from '../../ui/DialogSuccessMsg';




const EditFolder = ({ userToken, open, currentFolder, setEditOpen, setChildChanges }) => {

  	const [updated, setUpdated] = useState(false);
	const [error, setError] = useState('');
	const [folder, setFolder] = useState(currentFolder);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if(currentFolder) {
			setFolder(currentFolder)
		}
	}, [currentFolder]);

	useEffect(() => {
		if(folder) {
			console.log(folder)
		}
	}, [folder]);

	const handleClose = () => {
		setEditOpen(false);
		setUpdated(false);
		setFolder(currentFolder);
		setChildChanges((prevChanges) => !prevChanges);
		setError('');
	};

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFolder({ ...folder, [name]: value });
    }

	const handleCheckboxChange = (accessType) => {
        setFolder({ ...folder, access: accessType });
    };

	const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
		customTrim(folder);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.put(
                `/api/admin/folders/${folder._id}`,
                folder,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setUpdated(true);
                setError('');
            }
        } catch (error) {
            console.log('Error')
            setError('Error occurred while processing your request. Please try again later.')
        } finally {
            setLoading(false);
        }
    };

  	return (
		<>
			<Dialog
				aria-labelledby="update-folder-modal"
        	    aria-describedby="update-folder-modal"
				id='update-folder-modal'
        	    open={open}
			    onClose={handleClose}
                fullWidth
				maxWidth="md"
			>
				<Container className="update-folder-container" sx={{ m: 0, py: 3 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="update-que-header" mb={3}>
						<Grid item>
							<Chip color="success" label="Edit Folder" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseRoundedIcon />
							</IconButton>
						</Grid>
					</Grid>
					{!updated ? (
						<>
							{error && <Typography className='dialog-error-msg' textAlign="center">{error}</Typography>}
							{folder ? (
								<Grid container>
									<Grid item xs={12}>
										<Box component="form" onSubmit={handleSubmit}>
											<Grid container alignItems="center" spacing={2}>
												<Grid item xs={5}>
													<TextField
														label="Folder Name"
														multiline
														fullWidth
														required
														error={!folder.name}
														name="name"
														size="small"
														value={folder.name}
														onChange={handleChange}
														className="custom-textfield"
													/>
												</Grid>
												<Grid item xs={3}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox
                                                            checked={folder.access === 'public'}
                                                            onChange={() => handleCheckboxChange('public')}
                                                        />} 
                                                        label="Public"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox
                                                            checked={folder.access === 'private'}
															onChange={() => handleCheckboxChange('private')}
                                                        />} 
                                                        label="Private"
                                                    />
                                                </Grid>
												<Grid item xs={12} container justifyContent="flex-end" mt={5}>
													<Grid item>
														<Button 
                                                            className='update-folder-btn' 
                                                            type="submit"
                                                        >
                                                            {loading ? (
                                                                <>
                                                                    Submit&nbsp;
                                                                    <CircularProgress size={18} color="inherit" />
                                                                </>
                                                            ) : (
                                                                'Submit'
                                                            )}
                                                        </Button>
													</Grid>
												</Grid>
											</Grid>
										</Box>
									</Grid>
								</Grid>
							): null}
						</>
					) : (
						<DialogSuccessMsg msg="Success! Folder has been updated." />
					)}
				</Container>
			</Dialog>
		</>
  	)
}

export default EditFolder;