import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';


const MergedPersonalInfo = ({ clientInfo }) => {
    
    return (
        <>
   
            <Box className="section-content">
                    <Box>
                        <div className="field">
                            <label htmlFor="firstName">Name:</label>
                            <span id="firstName" style={{textTransform: 'capitalize'}}>{clientInfo.user1.firstName} {clientInfo.user1.lastName}</span>
                        </div>
                        <div className="field">
                            <label htmlFor="email">Email:</label>
                            <span id="email">
                                {clientInfo.user1.email}
                            </span>
                        </div>
                    </Box>
                    <Box>
                        <div className="field">
                            <label htmlFor="firstName">Name:</label>
                            <span id="firstName" style={{textTransform: 'capitalize'}}>{clientInfo.user2.firstName} {clientInfo.user2.lastName}</span>
                        </div>
                        <div className="field">
                            <label htmlFor="email">Email:</label>
                            <span id="email">
                                {clientInfo.user2.email}
                            </span>
                        </div>
                    </Box>
            </Box>
        </>
    )
}

export default MergedPersonalInfo;