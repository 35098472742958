import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Box from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormLabel from "@mui/material/FormLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from "@mui/material/FormControl";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import ErrorAlert from "../../ui/ErrorAlert";

import GeneralPersonalQuestionnaireModal from "./GeneralPersonalQuestionnaireModal";


function getTodayDate () {
  	const timeElapsed = Date.now();
  	const today = new Date(timeElapsed);
  	return today.toDateString();
}

const groups = ['Personal Information', 'Personal Residence', 'Dependents', 'Work & Income Sources', 'Healthcare', 'Education', 
	'Deductions and Credits', 'Investments', 'Retirement or Severance', 'Sale of Your Home', 'Gifts', 'Foreign Matters', 'Miscellaneous' ]


const GeneralPersonalQuestionnaire = ({ userToken, handleNextStep, userType }) => {

	const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  	const [error, setError] = useState(null);
  	const [loading, setLoading] = useState(true);
	const [submitLoading, setSubmitLoading] = useState(false);
  	const { userInfo } = useSelector((state) => state.user)
  	const [state, setState] = useState({
		date: getTodayDate(), 
    	email: userInfo?.email,
		questionnaire: []
	})
	const [open, setOpen] = useState(false);

  	useEffect(() => {
		if(userToken) {
			retrieveData()
		}
  	}, [userToken])

	  useEffect(() => {
		if(error) {
			console.log(error)
		}
  	}, [error])

  	const retrieveData = async () => {
		setLoading(true);
		try {
		  	const config = {
				headers: {
			  		Authorization: `Bearer ${userToken}`,
				},
		  	};
		  	const response = await axios.get(`/api/client/questionnaire?userType=${userType}`, config);
		  	const newQuestionnaire = response.data.map((question) => ({
				text: question.text,
				answer: question?.answer || '',
				group: question.group,
				type: question.type,
				order: question.order,
				note: question.note,
				new: question.new,
				unanswered: false,
				subQuestion: question.subQuestion
			  	? question.subQuestion.map((subQuestion) => ({
				  	subQuestionText: subQuestion.subQuestionText,
					subQuestionType: subQuestion.subQuestionType,
				  	subQuestionAnswer: subQuestion?.subQuestionAnswer || '',
				}))
			  	: [],
		  	}));
		  	setState(prevState => ({
				...prevState,
				questionnaire: newQuestionnaire
			}));
			setLoading(false);
		} catch (error) {
		  	console.log(error);
		}
	};

	const markUnansweredQuestions = () => {
		const updatedQuestionnaire = state.questionnaire.map((question) => {
			if (!question.answer) {
				return {
					...question,
					unanswered: true,
				};
			} else {
				return {
					...question,
					unanswered: false,
				};
			}
		});
		setState({
			...state,
			questionnaire: updatedQuestionnaire,
		});
	};

	const postData = async (e) => {
		e.preventDefault();
		setSubmitLoading(true);
		const unanswered = [];
		state.questionnaire.map((question) => {
			if (!question.answer) {
				unanswered.push({ question : question.text, group: question.group });
			}
		});

		if(unanswered.length === 0) {

			try {
				const config = {
					headers: {
						Authorization: `Bearer ${userToken}`,
					},
				};
				const response = await axios.post(
					`/api/client/questionnaire?userType=${userType}`,
					state,
					config
				);
				if (response.data.error) {
					setError(response.data.error);
				} else {
					handleNextStep();
					setState({
                        questionnaire: state.questionnaire.map((question) => ({
                            ...question,
                            answer: "",
                            subQuestions: question.subQuestions 
							? question.subQuestions.map((subQuestion) => ({
								...subQuestion,
								subQuestionAnswer: "",
							}))
							: [],
                        })),
					});
				}
			} catch (error) {
				if(error && error.response && error.response.data && error.response.data.error) {
					setError(error.response.data.error)
				} else {
					console.log(error)
					setError('Something went wrong.')
				}
			}
		} else {
			setUnansweredQuestions(unanswered)
			markUnansweredQuestions();
			setOpen(true)
		}
		setSubmitLoading(false);
	};

	const handleChange = (e, mainIndex, innerIndex) => {
		const { name, value } = e.target;
		const tempQuestionnaire = [...state.questionnaire];
		if (innerIndex !== undefined) {
		  	tempQuestionnaire[mainIndex].subQuestion[innerIndex].subQuestionAnswer = value;
		} else {
		  	tempQuestionnaire[mainIndex].answer = value;
			tempQuestionnaire[mainIndex].unanswered = false;
		}
		setState({ ...state, questionnaire: tempQuestionnaire });
	};


  	return (
		<>
        	{loading ? (
            	<p>Loading</p>
        	) : state.questionnaire ? (
            	<Box component="form" onSubmit={postData} className="questionnaire-form" id="personal-questionnaire-form" mb={2}>
					<Grid container direction="row">
						<Grid item xs={12}>
							{groups.map((group, i) => (
								<Box key={group}>
									<Typography className="group-heading" variant="h5">
										{group}
									</Typography>
									<Grid container mb={5} spacing={2}>
										{state.questionnaire
										.map((item, index) => ({ ...item, index }))
										.filter((item) => item.group.toLowerCase() === group.toLowerCase())
										.sort((que1, que2) => que1.order - que2.order)
										.map(({ index, ...item }) => {
											if (item.type === 'input') {
												return (
													<Grid item xs={10} key={item.text}>
														<FormControl 
															fullWidth
															error={item.unanswered}
														>
															<FormLabel>
																{item.text}
																{item.new && (
																	<Chip label="new" color="success"/>
																)}
															</FormLabel>
															<TextField
																fullWidth
																multiline
																rows={3}
																name={item.text}
																value={
																	state.questionnaire[index]
																	? state.questionnaire[index].answer
																	: ''
																}
																onChange={(e) => handleChange(e, index)}
															/>
														</FormControl>
                                            		</Grid>
                                        		);
                                    		} else {
                                        		return (
                                            		<Grid item xs={10} key={item.text}>
														<FormControl
															error={item.unanswered}
														>
															<FormLabel>
																{item.text}
																{item.new && (
																	<Chip label="new" color="success"/>
																)}
															</FormLabel>
															<RadioGroup
																name={item.text}
																value={
																	state.questionnaire[index]
																	? state.questionnaire[index].answer
																	: ''
																}
																row
																onChange={(e) => handleChange(e, index)}
															>
																<FormControlLabel
																	value="yes"
																	control={<Radio />}
																	label="Yes"
																/>
																<FormControlLabel
																	value="no"
																	control={<Radio />}
																	label="No"
																/>
															</RadioGroup>
														</FormControl>
														{item.note &&
														state.questionnaire[index] &&
														state.questionnaire[index].answer === 'yes' && (
															<Typography className="questionnaire-note">
																Note: {item.note}
															</Typography>
														)}
														{item.subQuestion &&
														item.subQuestion.length > 0 &&
														state.questionnaire[index] &&
														state.questionnaire[index].answer === 'yes' && (
															<>
																{item.subQuestion.map((item, i) => {
																	if (item.subQuestionType === 'input') {
																		return (
																			<Grid
																				item
																				xs={10}
																				key={item.subQuestionText}
                                                                                className="sub-question"
																			>
																				<FormLabel>
																					{item.subQuestionText} 
																				</FormLabel>
																				<TextField
																					fullWidth
																					name={item.subQuestionText}
																					value={item.subQuestionAnswer}
																					multiline
																					rows={3}
																					onChange={(e) =>
																						handleChange(e, index, i)
																					}
																				/>
																			</Grid>
																		);
																	} else {
																		return (
																			<Grid
																				item
																				xs={10}
																				key={item.subQuestionText}
                                                                                className="sub-question"
																			>
																				<FormLabel>
																					{item.subQuestionText}
																				</FormLabel>
																				<RadioGroup
																					defaultValue="general"
																					name={item.subQuestionText}
																					value={item.subQuestionAnswer}
																					onChange={(e) =>
																						handleChange(e, index, i)
																					}
																					row
																				>
																					<FormControlLabel
																						value="yes"
																						control={<Radio />}
																						label="Yes"
																					/>
																					<FormControlLabel
																						value="no"
																						control={<Radio />}
																						label="No"
																					/>
																				</RadioGroup>
																			</Grid>
																		);
																	}
																})}
															</>
														)}
													</Grid>
												);
											}
										})}
									</Grid>
								</Box>
							))}
						</Grid>
						<Grid item xs={12} container justifyContent="flex-end">
              				<Button 
                                type="submit" 
                                className="submit-questionnaire-btn"
                                disabled={submitLoading}
                            >
                              {submitLoading ? (
                                    <>
                                        Submit&nbsp;
                                        <CircularProgress size={18} color="inherit" />
                                    </>
                                ) : (
                                    'Submit'
                                )}
                            </Button>
          				</Grid>
      				</Grid>
            	</Box>
        	) : (
            	<p>No questionnaire data found.</p>
        	)}
			<GeneralPersonalQuestionnaireModal
				open={open}
				setOpen={setOpen}
				unansweredQuestions={unansweredQuestions}
				groups={groups}
			/>
			{error && <ErrorAlert show={error} message={error} />} 
    	</>
  	)
}

export default GeneralPersonalQuestionnaire;