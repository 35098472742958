import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

import GeneralAddFilesFields from './general-add-files-fields';

import DoneIcon from '../../../assets/icons/done.gif';
import DialogSuccessMsg from '../../ui/DialogSuccessMsg';



const GeneralClientAddFiles = ({ open, setOpen, userToken, businessId, businessName, setChildChanges, userType }) => {

    const [newUserFiles, setNewUserFiles] = useState({
    	userFiles: [],
  	});
    const [errorMsg, setErrorMsg] = useState('');
	const [isUploaded, setIsUploaded] = useState(false);

    const updateUploadedFiles = (files) => {
        setNewUserFiles({ ...newUserFiles, userFiles: files });
    }

    const handleClose = () => {
		setChildChanges((prevChanges) => !prevChanges);
        setOpen(false);
		setIsUploaded(false);
		setErrorMsg('');
    }

    const handleOnSubmit = async (e) => {
    	e.preventDefault();
    	try {
      		if(errorMsg === '') {
        		if(newUserFiles.userFiles.length) {
          			const formData = new FormData();
          			for (let i = 0 ; i < newUserFiles.userFiles.length ; i++) {
            			formData.append("files", newUserFiles.userFiles[i]);
          			}
          			setErrorMsg('');
					const url = businessId
					? `/api/c/dashboard/business/upload/${businessId}`
					: `/api/c/dashboard/personal/upload?userType=${userType}`;
          			await axios.post(url, formData, {
            			headers: {
              				Authorization: `Bearer ${userToken}`,
              				'Content-Type' : 'multipart/form-data'
            			}
          			})
          			.then((res) => {
            			if(res.data.error) {
                            console.log('error')
							setIsUploaded(false);
              				setErrorMsg(res.data.error)
            			} else {
							setIsUploaded(true);
            			}
          			})
        		} else {
          			setErrorMsg('Please select a file to add.');
        		}
      		} else {
        		setErrorMsg('Please enter all the field values.');
      		}
    	} catch (error) {
			console.log(error)
			setErrorMsg('Error occurred. Please try again later');
    	}
  	}

    return (
        <>
            <Dialog
        	    aria-labelledby="client-documents-upload-dialog"
        	    aria-describedby="client-documents-upload-dialog"
        	    open={open}
			    onClose={handleClose}
                fullWidth
				id="client-documents-upload-dialog"
                maxWidth="md"
      	    >
				<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header">
						<Grid item>
							<Chip color="warning" label={businessName ? `${businessName} File Upload` : 'Personal File Upload'} />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{isUploaded ? (
						<Box className="admin-doc-modal">
							<DialogSuccessMsg msg="Files uploaded successfully" />
						</Box>
					) : (
						<Box mt={5} mb={5} className="admin-doc-modal" component="form" onSubmit={handleOnSubmit}>
							<GeneralAddFilesFields label="Documents Upload" multiple updateFilesCb={updateUploadedFiles} />
							<Grid container justifyContent="flex-end" sx={{width: '95%'}}>
								<Grid item>
									<Button className='add-files-btn' type="submit"> Submit </Button>
								</Grid>
							</Grid>
						</Box>
					)}
				</Container>
      		</Dialog>
        </>
    )
}

export default GeneralClientAddFiles;