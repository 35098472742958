import { useState, useEffect, useMemo } from "react";

export function useYearsFilter(list) {
  	const [years, setYears] = useState(['All']);
  	const [selectedYear, setSelectedYear] = useState("All");


  	useEffect(() => {
    	if (list && list.length > 0) {
			const uniqueYears = [...new Set(list.map((item) => item.taxYear))].sort((a, b) => b - a);
			setYears(['All', ...uniqueYears]);

			const currentYearMinusOne = new Date().getFullYear() - 1;
			const defaultYear = uniqueYears.includes(currentYearMinusOne.toString()) ? currentYearMinusOne : uniqueYears[0] || 'All';
			setSelectedYear(defaultYear.toString());
    	}
  	}, [list]);

	const handleSelectChange = (e) => {
		setSelectedYear(e.target.value);
	};

	function getFilteredList() {
		if (selectedYear === 'All') {
			return list;
		}
		return list.filter((item) => item.taxYear.toString() === selectedYear);
  	}

  	const filteredList = useMemo(getFilteredList, [selectedYear, list]);

	return {
		years,
		selectedYear,
		handleSelectChange,
		filteredList,
	};
}