import PdfIcon from '../assets/icons/doc-icons//pdf.png';
import CsvIcon from '../assets/icons/doc-icons/csv.png';
import DocxIcon from '../assets/icons/doc-icons/docx.png';
import DocIcon from '../assets/icons/doc-icons/doc.png';
import JpgIcon from '../assets/icons/doc-icons/jpg.png';
import PngIcon from '../assets/icons/doc-icons/png.png';
import SvgIcon from '../assets/icons/doc-icons/svg.png';
import TxtIcon from '../assets/icons/doc-icons/txt.png';
import XlsIcon from '../assets/icons/doc-icons/xls.png';
import XlsxIcon from '../assets/icons/doc-icons/xlsx.png';
import XmlIcon from '../assets/icons/doc-icons/xml.png';
import ExeIcon from '../assets/icons/doc-icons/exe.png';
import JpegIcon from '../assets/icons/doc-icons/jpeg.png';

export const fileIcons = {
    pdf: PdfIcon,
    jpg: JpgIcon,
    jpeg: JpegIcon,
    svg: SvgIcon,
    png: PngIcon,
    csv: CsvIcon,
    txt: TxtIcon,
    xls: XlsIcon,
    xlsx: XlsxIcon,
    docx: DocxIcon,
    doc: DocIcon,
    xml: XmlIcon,
    exe: ExeIcon,
    default: DocIcon,
};
  
export const getFileExtension = (filename) => {
    return filename.split('.').pop();
};