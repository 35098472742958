import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { capitalize } from '@mui/material';

import FolderIcon from '../../../assets/icons/folder-3.png'

// import ClientAddNewFiles from './ClientAddNewFiles';
import GeneralClientAddFiles from './general-client-add-files';


const GeneralClientFolders = ({ data, setSelectedFolder, userToken, businessId="", businessName="", setChildChanges,  userType="", loading, isDocumentUploadActive }) => {

    const [open, setOpen] = useState(false);
    const [isButtonVisible, setButtonVisible] = useState(false);

    const handleAddDocs = () => {
        setOpen(true)
    }

    useEffect(() => {
        if(isDocumentUploadActive === 1) {
            setButtonVisible(true)
        }
    }, [isDocumentUploadActive])

    // useEffect(() => {
    //     if(userInfo) {
    //         console.log(userInfo)
    //     }
    // }, [userInfo])


    return (
        <>
            <Grid container id='client-view-folders-header' alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography component="h1">{ businessName ? `${businessName} Documents` : 'Personal Documents' }</Typography>
                </Grid>
                {isButtonVisible && (
                    <Grid item>
                        <Button onClick={handleAddDocs}>
                        Upload
                        </Button>
                    </Grid>
                )}
            </Grid>
            {loading ? (
                <Grid container spacing={3}>
                    {Array.from({ length: 3 }).map((_, index) => (
                        <Grid item key={index}>
                            <Skeleton variant="rounded" width={200} height={200} />
                        </Grid>
                    ))}
                </Grid>
            ) : ( 
                <Box id="client-folders-container">
                    <Grid container justifyContent="center" spacing={3} style={{ justifyContent: 'flex-start' }}>
                        {data.map((item, index) => (
                            <Grid container justifyContent="center" item key={index+35} xs={12} sm={6} md={4} lg={3}>
                                <Grid item>
                                    <Paper className="box" onClick={() => setSelectedFolder(item)}>
                                        <img src={FolderIcon} alt={item.name} />
                                        <Typography variant="subtitle1" align="center">
                                            {capitalize(item.name)} ({item.files.length})
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            <GeneralClientAddFiles 
                businessId={businessId} 
                businessName={businessName}
                open={open} 
                setOpen={setOpen} 
                userToken={userToken} 
                setSelectedFolder={setSelectedFolder}
                setChildChanges={setChildChanges}
                userType={userType}
            />
        </>
    )
}

export default GeneralClientFolders;