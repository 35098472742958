import { createSlice } from "@reduxjs/toolkit";
import { registerUser, verifyEmail, loginUser, getUserDetails, verifyOtp } from "../actions/authActions";

const userToken = localStorage.getItem('userToken')
  	? localStorage.getItem('userToken') : null;

const initialState = {
	loading: false,
	userInfo: null,
	userToken,
	error: null,
	success: false
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logout: (state) => {
			localStorage.removeItem('userToken')
			state.loading = false
			state.userInfo = null
			state.userToken = null
			state.error = null
		}
	},
  extraReducers: {
    //register user
		[registerUser.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[registerUser.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.success = true;
		},
		[registerUser.rejected]: (state, { payload }) => {
			state.loading = false
			state.error = payload
		},
		// Verify email
		[verifyEmail.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[verifyEmail.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.success = true;
		},
		[verifyEmail.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		// Verify password
		[loginUser.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
    	[loginUser.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.success = true;
    	},
		[loginUser.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		// Verify OTP
		[verifyOtp.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
    	[verifyOtp.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.userInfo = payload.user;
			state.userToken = payload.userToken;
			state.success = true;
			localStorage.setItem('userToken', payload.userToken);
    	},
		[verifyOtp.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},
		// Get User Details
		[getUserDetails.pending]: (state) => {
			state.loading = true
		},
		[getUserDetails.fulfilled]: (state, { payload }) => {
			state.loading = false
			state.userInfo = payload
		},
		[getUserDetails.rejected]: (state, { payload }) => {
			state.loading = false
		}
  }
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;