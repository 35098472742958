import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useFetchData } from '../../../hooks/useFetchData';

import EditEngLetter from './EditEngLetter';
import AddEngLetter from './AddEngLetter';
import EmptyDataComponent from '../../ui/EmptyDataComponent';


const ViewEngLetter = () => {

    const { userToken } = useSelector((state) => state.user);
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData('/api/general/letter', userToken, childChanges);
    const [editMode, setEditMode] = useState(false);
    const [newLetterOpen, setNewLetterOpen] = useState(false);
    const [letter, setLetter] = useState([])

    const handleEditMode = () => {
        setEditMode(true)
    }

    const handleNewOpen = () => {
        setNewLetterOpen(true)
    }

    useEffect(() => {
        if(data) {
            setLetter(data.text)
        }
    }, [data])



    return (
        <Box id="admin-eng-letter">
            <Grid container mb={3} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography component='h1'>
                        Engagement Letter
                    </Typography>
                </Grid>
                {!data && letter.length === 0 ? (
                    <Grid item mr={5}>
                        <Button
                            className='eng-letter-btn'
                            onClick={handleNewOpen}
                        >
                            Add Letter
                        </Button>
                    </Grid>
                ): null}
            </Grid>
            {loading ? (
                <Typography component="p">
                    Loading...
                </Typography>
            ) : !editMode && letter && letter.length > 0 ? (
                <Grid container className='eng-letter-container'>
                    <Grid item container flexDirection="column" alignItems="end" className='header-container'>
                        <Grid item>
                            <Button className='eng-letter-btn' onClick={handleEditMode}>
                                Edit
                            </Button>
                        </Grid>
                        <Grid item className='date-container' mt={2}>
                            <Typography component="h5">
                                Last Modified:
                            </Typography>
                            {data && data.date ? (
                                <Typography component="span">{data.date}</Typography>
                            ) : (
                                <Typography component="span">Today</Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item className='eng-letter-txt-container'>
                        {letter.map((item, index) => (
                            <Typography key={index} component="p" className="letter-text">
                                {item.paragraph}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            ) : editMode && letter && letter.length > 0 ? (
                <EditEngLetter 
                    letter={data} 
                    userToken={userToken} 
                    setEditMode={setEditMode}
                    setChildChanges={setChildChanges}
                />
            ) : (
                <EmptyDataComponent msg="hello" />
            )}
            <AddEngLetter 
                open={newLetterOpen} 
                setNewLetterOpen={setNewLetterOpen} 
                userToken={userToken} 
                setLetter={setLetter} 
                setChildChanges={setChildChanges}
            />
        </Box>
    )
};

export default ViewEngLetter;

