import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ClientsList from './ClientList';
import Invite from '../general/Invite';
import QuestionnaireList from './Questionnarie/QuestionnarieList';
import FreeQuote from './FreeQuote';
import ViewEngLetter from './EngLetter/ViewEngLetter';
import AccountIntegration from './MergeAcc';
import AdminFolders from './Folders';
import AdminView from './index.js';

const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AdminView />}>
                <Route index element={<Navigate replace to="clients-list" />} />
                <Route path="clients-list" element={<ClientsList />} />
                <Route path="invite" element={<Invite />} />
                <Route path="questionnaire" element={<QuestionnaireList />} />
                <Route path="engagement-letter" element={<ViewEngLetter />} />
                <Route path="folders" element={<AdminFolders />} />
                <Route path="free-quote" element={<FreeQuote />} />
                <Route path="account-integrations" element={<AccountIntegration />} />
            </Route>
        </Routes>
    );
};
  
export default AdminRoutes;