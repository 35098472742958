import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const MergedClientOpenedMenu = ({ handleDrawer, id, userInfo, clientMenu, checkIsActive }) => {

    const [user1Name, setUser1Name] = useState('');
    const [user2Name, setUser2Name] = useState('');

    useEffect(() => {
        if (userInfo && userInfo.mergedAccounts) {
          const mergedAccount = userInfo.mergedAccounts.find(account => account.id === id);
          if (mergedAccount) {
            setUser1Name(mergedAccount.user1Name);
            setUser2Name(mergedAccount.user2Name);
          }
        }
    }, [id, userInfo]);


    return (
        <>
            <DrawerHeader className="client-profile-drawer-header">
                {user1Name && user2Name  && (
                    <Box className="merged-user-drawer-wrapper">
                        <Typography className="merged-user-page" sx={{textTransform: 'capitalize'}}>
                            {user1Name}
                        </Typography>
                        <Typography className="merged-user-page" sx={{textTransform: 'capitalize'}}>
                            {user2Name}
                        </Typography>
                    </Box> 
                )}
                <IconButton onClick={handleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List className="list-open">
                {clientMenu.map((item, index) => (
                    <ListItem>
                        <Link to={`${item.path}/${id}`} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }} key={item.menuItem}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: 'initial',
                                    px: 2.5,
                                    pr: "10px",
                                    pl: "10px"
                                }}
                                className={`list-group-item ${checkIsActive(item.path) ? 'active' : ''}`}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: 2,
                                        justifyContent: 'center'
                                    }}
                                >
                                    {item.menuIcon}
                                </ListItemIcon>
                                <ListItemText primary={item.menuItem} sx={{ display: "block" }} />
                            </ListItemButton>
                        </Link>
                    </ListItem>
                ))}
            </List>
        </>
    )
}


export default MergedClientOpenedMenu;