import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import DialogSuccessMsg from '../../ui/DialogSuccessMsg';


const SplitAccountsDialog = ({ open, currentItem, setCurrentItem, handleClose, userToken}) => {

    const [selectedBusinesses, setSelectedBusinesses] = useState([]);
    const [isUpdated, setUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    const handleCheckboxChange = (businessId, userId) => {
        setSelectedBusinesses((prevSelected) => {
          const existingIndex = prevSelected.findIndex((item) => item.businessId === businessId);
          if (existingIndex !== -1) {
            // Update the existing item
            const updatedItem = { ...prevSelected[existingIndex], userId };
            const updatedSelected = [...prevSelected];
            updatedSelected[existingIndex] = updatedItem;
            return updatedSelected;
          } else {
            // Add a new item
            return [...prevSelected, { businessId, userId }];
          }
        });
    };

    const handleSubmit = async (e) => {
        // Handle form submission
        e.preventDefault();
        setLoading(true);

        // if(currentItem.businesses.length !== selectedBusinesses.length) {
        //     setError('Please, select all main users for businesses.')
        //     return;
        // }

        const mergedData = {
            active: false,
            user1: currentItem.user1.userId,
            user2: currentItem.user2.userId,
            // businesses: selectedBusinesses
        };
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.put(
                `/api/admin/split/account/${currentItem._id}`,
                mergedData,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setCurrentItem(null)
                setUpdated(true);
                setError('');
            }
        } catch (error) {
            console.log('Error')
            setError('Error')
        } finally {
            setLoading(false);
        }
        console.log('Selected Businesses:', mergedData);
    };

    // useEffect(() => {
    //     if(currentItem) {
    //         console.log(currentItem)
    //     }
    // }, [currentItem])

    // useEffect(() => {
    //     if(selectedBusinesses) {
    //         console.log(selectedBusinesses)
    //     }
    // }, [selectedBusinesses])

    return (
        <Dialog
            aria-labelledby="split-accounts-modal"
            aria-describedby="split-accounts-modal"
            open={open}
            onClose={handleClose}
            fullWidth
            id="split-accounts-modal"
            maxWidth="md"
        >
            <Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                <Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header" mb={3}>
                    <Grid item>
                        <Chip color="error" label="Split Accounts" />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {error && <Typography>{error}</Typography>}
                {(currentItem && !isUpdated) ? (
                    <Box component="form" onSubmit={handleSubmit}>
                        <Typography component='h3'>Clients Information</Typography>
                        <Grid container justifyContent="space-between" mb={2} mt={2}>
                            <Grid item xs={5}>
                                <Typography component='h4'>{currentItem.user1.firstName} {currentItem.user1.lastName}</Typography>
                                <Typography component='p'>{currentItem.user1.email}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography component='h4'>{currentItem.user2.firstName} {currentItem.user2.lastName}</Typography>
                                <Typography component='p'>{currentItem.user2.email}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container mt={3} mb={1} justifyContent="flex-end">
                            <Grid item className="split-acc-btn">
                                <Button type='submit'>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                ) : (
                    <DialogSuccessMsg msg="Accounts split successfully."/>
                )}
                
            </Container>

        </Dialog>
    )
}

export default SplitAccountsDialog;