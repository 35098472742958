import {React, useEffect, useState} from 'react'
import { useSelector, useDispatch} from "react-redux";
import { logout } from '../reducers/authReducers';
import { Link, Outlet, useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AccessDeniedImg from '../assets/access-denied.png'

const ProtectedRoute = () => {
  	const { userInfo, userToken } = useSelector((state) => state.user)

  	const navigate = useNavigate();
	const dispatch = useDispatch();


	useEffect(() => {
		if(userInfo && userInfo.view ===  4)  {
			navigate('/profile')
		}
	}, [userInfo])


	useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'userToken' && !e.newValue) { 
                dispatch(logout());
            }
        };

        // Attach the event listener
        window.addEventListener('storage', handleStorageChange);

        // Cleanup
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch]);

  	if(!userInfo || !userToken || (userInfo && userInfo?.role !== 'user')) {
		return (
			<Box id="unauthorized-box">
				<Grid container justifyContent="center">
					<Grid item>
					<img src={AccessDeniedImg} />
					</Grid>
				</Grid>
				<Grid container justifyContent="center" mt={2}>
					<Grid item>
						<Typography component="h1">Access Denied</Typography>
						<Typography component="p">It looks like you don't have the necessary permissions to view this section. Please log in with the appropriate account to continue.</Typography>
						<Link to='/login'>Sign In</Link>
					</Grid>
				</Grid>
			</Box>
		)
  	}
  	return <Outlet />
}

export default ProtectedRoute;