import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import TrashBin from '../../../../../assets/icons/dustbin.png';


const ArchiveDialogContent = ({ business, userToken, dialogType, onClose, updateBusiness, deleteBusiness }) => {

    const identifier = business._id || business.ein;
    const [isArchived, setIsArchived] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = (e) => {
        e.stopPropagation();
        setShowPassword(!showPassword)
    };

    const handlePasswordChange = (e) => {

    }

    /*
        For client it looks like DELETE, but we ARCHIVE business instead 
    */
    const handleArchive = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);

        const data = {
            status: 1,
            password: password
        };

        const updatedBusiness = {
            ...business,
            status: 1,
        };

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                `api/c/dashboard/business/archive/${identifier}`,
                data,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setIsArchived(true);
                setError('');
                updateBusiness(updatedBusiness);
            }
        } catch (error) {
            console.log(error)
            setError(error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Error.');
        } finally {
            setLoading(false);
        }
    }

    // const handleDeleteBusiness = (e) => {
    //     e.stopPropagation();
    //     handleDelete();
    //     deleteBusiness(business._id);
    // }

    return (
        <>
            <Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {isArchived ? (
                    <Grid container justifyContent="center" mb={3}>
                        <Grid item>
                            <Typography variant='subtitle1'>Business was deleted successfully.</Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Grid container justifyContent="center" mb={3}>
                            <Grid item xs={12} justifyContent="center" container>
                                <Grid>
                                    <img src={TrashBin} alt="trash bin icon" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle1'>Are you sure you want to delete the business ${business?.businessName}?</Typography>
                                <Typography variant='subtitle1'>Deleting will permanently remove the business and all associated documents.</Typography>
                                {error && <Typography color="secondary" variant='body1'>{error}</Typography>}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    name="password"
                                    label="Password"
                                    helperText="Please, provide your password to delete this business."
                                    size="small"
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                    value={password}
                                    InputProps={{
                                        endAdornment:(
                                            <InputAdornment position="end">
                                                <IconButton
                                                    name="1"
                                                    edge="end"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }
    
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }} 
                                />

                            </Grid>

                        </Grid>
                        <Grid container justifyContent="center" mb={3} spacing={2}>
                            <Grid item>
                                <Button 
                                    variant='outlined' 
                                    color='primary' 
                                    className='cancel-button' 
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    type="button" 
                                    variant='contained' 
                                    className='delete-button'
                                    disabled={loading}
                                    onClick={handleArchive}
                                >
                                    {loading ? (
                                        <>
                                            Delete
                                            <CircularProgress size={24} color="inherit" />
                                        </>
                                    ) : (
                                        'Delete'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Container>
        </>
    )
}

export default ArchiveDialogContent;