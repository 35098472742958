import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const EditEngLetter = ({ letter, userToken, setEditMode, setChildChanges }) => {

    const [formData, setFormData] = useState({ 
        text: letter?.text || [],
        _id: letter?._id || ''
    });

    useEffect(() => {
        if (letter) {
            setFormData({ text: letter.text, _id: letter._id });
        }
    }, [letter]);

    const handleParagraphChange = (index, value) => {
        const newText = formData.text.map((item, i) => {
            if (index === i) {
                return { ...item, paragraph: value };
            }
            return item;
        });
        setFormData({ ...formData, text: newText });
    };

    const handleAddParagraph = (index) => {
        const newText = [...formData.text];
        newText.splice(index, 0, { paragraph: '' });
        setFormData({ ...formData, text: newText });
    };

    const handleRemoveParagraph = (index) => {
        const newText = formData.text.filter((item, i) => i !== index);
        setFormData({ ...formData, text: newText });
    };

    const updateLetter = async (data) => {
        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            }
        }
        try {
            const res = await axios.post('/api/admin/letter', data, config);
            console.log(res.status)
            if (res.status === 200) {
                setEditMode(false);
                setChildChanges((prevChanges) => !prevChanges);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const submitForm = (event) => {
        event.preventDefault(); // Prevent the default form submit action
        updateLetter(formData);
    };

    const cancelEdit = () => {
        setEditMode(false);
    };

    return (
        <>
            {!!letter ? (
                <Grid container mt={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Box component="form" onSubmit={submitForm}>
                                    {formData.text.map((item, i) => (
                                        <Box key={i}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Typography variant='body2'>Paragraph #{i + 1}</Typography>
                                                <IconButton onClick={() => handleAddParagraph(i + 1)}>
                                                    <AddIcon />
                                                </IconButton>
                                                {formData.text.length > 1 && (
                                                    <IconButton onClick={() => handleRemoveParagraph(i)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <TextField
                                                fullWidth
                                                multiline
                                                required
                                                value={item.paragraph}
                                                onChange={(e) => handleParagraphChange(i, e.target.value)}
                                            />
                                        </Box>
                                    ))}
                                    <Grid container justifyContent="flex-end" mt={2} className='edit-eng-letter-btn-wrapper'>
                                        <Grid item>
                                            <Button className='edit-cancel-btn' type="button" onClick={cancelEdit}>Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className='edit-update-btn' type="submit">Update</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            ) : null}
        </>
    )
}

export default EditEngLetter;