import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import NotFoundImg from '../../assets/404-error.png'


const NotFound = () => {
    return (
        <Box id="not-found-box">
            <Grid container justifyContent="center">
                <Grid item>
                    <img src={NotFoundImg} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={2}>
                <Grid item>
                    <Typography component="h1">The page you are looking for does not exist.</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NotFound;