import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ConsultingService from '../../assets/homepage/consulting-service.png';
import FreeQuoteContainer from "./FreeQuoteComponent";

import useScrollToTop from "../../hooks/useScrollToTop"


const Consulting = () => {
    useScrollToTop();
    
    return (
        <Box id="consulting-page" mb={10}>
            <Grid container mt={5} mb={7}>
				<Grid item>
					<Typography component="h1">
						Consulting
					</Typography>
				    </Grid>
			</Grid>
            <Grid container justifyContent="center" alignItems="center" mb={5} mt={5} className="intro-wrapper">
                <Grid item xs={6}>
                    <Typography component="p">
                        A lot of people view their tax preparer or accountant as someone who simply prepares their taxes or does their bookkeeping, respectively. If you are one of those people then you are definitely doing yourself a disservice and not taking advantage of your tax preparer or accountant to the fullest.
                    </Typography>
                    <Typography component="p">
                        Think of us as your financial guide. Whether you are starting to invest, looking for retirement options, or are preparing to start a business, then we can help you structure all of your goals in a manner where you will pay the least amount of taxes and make the most amount of money.
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <img src={ConsultingService} className="banner-img" />
                </Grid>
            </Grid>
            <Grid container mt={5} mb={7}>
				<Grid item xs={12}>
					<Typography component="h2">
                        Some of our consulting services
					</Typography>
				    </Grid>
			</Grid>
            <Grid container justifyContent="center" columnGap={3} rowGap={3} mb={10}>
                <Grid item xs={5} className="consulting-taxes-item">
                    <Typography component="h3">
                        Tax Planning
                    </Typography>
                    <Typography component="p">
                        Helps people and businesses determine their tax obligations and limiting them whether those obligations are due to a change in their career, getting married or having children, starting or restructuring a business, or anything else.
                    </Typography>
                </Grid>
                <Grid item xs={5} className="consulting-taxes-item">
                    <Typography component="h3">
                        Business Entity Selection/Structuring
                    </Typography>
                    <Typography component="p">
                        Helps those who want to start a business or already have a business, but are unsure what the difference between the various business structures that exist is and which one would suit their situation best.
                    </Typography>
                </Grid>
                <Grid item xs={5} className="consulting-taxes-item">
                    <Typography component="h3">
                        Retirement Accounts (Business and Individual)
                    </Typography>
                    <Typography component="p">
                        Helps those who want to know about various types of retirement accounts (i.e. 401K, Roth 401K, IRA, Roth IRA, HSA, SEP, etc.) and which one is best for their goals and needs.
                    </Typography>
                </Grid>
                <Grid item xs={5} className="consulting-taxes-item">
                    {/* <img src={moneyIcon} /> */}
                    <Typography component="h3">
                        Other
                    </Typography>
                    {/* <Box> */}
                        <Typography component="p">
                            1. Payroll (software, structure, rules)
                        </Typography>
                        <Typography component="p">
                            2. Accounting Software to manage your own books, payroll, benefits, and etc.
                        </Typography>
                        <Typography component="p">
                            3. Shell companies
                        </Typography>
                        <Typography component="p">
                            4. Estate planning
                        </Typography>
                    {/* </Box> */}
                </Grid>
            </Grid>
            <FreeQuoteContainer />
        </Box>
    )
}

export default Consulting;