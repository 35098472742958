import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {useNavigate, Navigate} from 'react-router-dom'
import Box from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import GeneralDropZone from './GeneralDropZone.js';
import GeneralSkipDocuments from './GeneralSkipDocuments';

import ErrorAlert from '../../ui/ErrorAlert'

import { useFetchData } from '../../../hooks/useFetchData';

const GeneralDocumentForm = ({ userToken, handleNextStep, userType }) => {

  	// const { userInfo, userToken } = useSelector((state) => state.user)
	const [loading, data, error] = useFetchData(`/api/client/notes?userType=${userType}`, userToken);
  	const [newUserFiles, setNewUserFiles] = useState({
    	userFiles: []
  	});
  	const [errorMsg, setErrorMsg] = useState('');
	const [isLoading, setIsLoading] =useState(false)
  	const updateUploadedFiles = (files) => {
  		setNewUserFiles({ ...newUserFiles, userFiles: files });
	}

	useEffect(() => {
		if(userType) {
			console.log('User Type from document Form', userType)
		}
	}, [userType])

	const handleOnSubmit = async (e) => {
    	e.preventDefault();

		if (errorMsg !== '') {
			setErrorMsg('Error.');
			return;
		}

		if (newUserFiles.userFiles.length === 0) {
			setErrorMsg('Please select a file to add.');
			return;
		}

		setIsLoading(true);

    	try {
			const formData = new FormData();
			for (let i = 0; i < newUserFiles.userFiles.length; i++) {
				formData.append("files", newUserFiles.userFiles[i]);
			}

			const res = await axios.post(`/api/client/documents-upload?userType=${userType}`, formData, {
				headers: {
					Authorization: `Bearer ${userToken}`,
					'Content-Type': 'multipart/form-data'
				}
			});

			if (res.data.error) {
				setErrorMsg(res.data.error)
			} else {
				handleNextStep()
			}
		} catch (error) {
			console.log(error);
			setErrorMsg('Something went wrong. Please try again later.')
		} finally {
			setIsLoading(false);
		}
  	}

	const skipDocumentsUpload = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		try {
			const res = await axios.post(`/api/client/skip-documents-upload?userType=${userType}`, {skipDocuments: true}, {
				headers: {
					Authorization: `Bearer ${userToken}`,
				}
			});

			if (res.data.error) {
				setErrorMsg(res.data.error)
			} else {
				handleNextStep()
			}

		}catch (error) {
			console.log(error);
			setErrorMsg('Something went wrong. Please try again later.')
		} finally {
			setIsLoading(false);
		}
	}

	// if(data && !data.documents) {
	// 	return (
	// 		<GeneralSkipDocuments 
	// 			skipDocumentsUpload={skipDocumentsUpload}
	// 			isLoading={isLoading}
	// 		/>
	// 	)
	// }


  	return (
    	<>
			<Box id='documents-upload-application-wrapper'>
				<Grid container justifyContent="space-between" alignItems="center" mb={7}>
					<Grid item className='note-wrapper' px={2} xs={8}>
						<Typography component="p">
						Notice: You can choose to upload your documents now or later within your account. We aim to make the process as flexible as possible for you.
						</Typography>
					</Grid>
					<Grid item>
                        <Button
                            onClick={skipDocumentsUpload}
                            className='skip-documents-upload'
                        >
                            Skip
                        </Button>
                    </Grid>
				</Grid>
				<Grid container justifyContent="center" spacing={4}>
					<Grid item xs={6} className="documents-upload-fields-wrapper" component="form" onSubmit={handleOnSubmit}>
						<GeneralDropZone
							// accept=".jpg,.png,.jpeg,"
							label="Documents Upload"
							multiple
							updateFilesCb={updateUploadedFiles}
						/>
						<Grid container mt={3} justifyContent="flex-end">
							<Grid item>
								<Button 
									type="submit" 
									className='submit-files-button'
									disabled={isLoading}
								>
									{isLoading ? (
										<>
											Submit&nbsp;
											<CircularProgress size={18} color="inherit" />
										</>
									) : (
										'Submit'
									)}
								</Button>

							</Grid>
						</Grid>
					</Grid>
					{(data && data.notes && data.notes.length > 0) && (
						<Grid item xs={4} >
							<Box className='notes-container'>
								<Typography component="h4">Please, upload all necessary files</Typography>
								<Grid container>
									{data.notes.map((item, index) => (
										<Grid item xs={12} key={index}>
											<Typography component="p">
												{index + 1}. {item}
											</Typography>
										</Grid>
									))}
								</Grid>
							</Box>
						</Grid>
					)}
				</Grid>
			</Box>
    		{errorMsg && <ErrorAlert show={errorMsg === true} message={errorMsg} />}
    	</>
  	)
}

export default GeneralDocumentForm;