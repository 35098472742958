import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import download from 'downloadjs';
import { useSelector } from "react-redux";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from "@mui/material/Avatar";
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { fileIcons, getFileExtension } from '../../../utils/fileIcons.js'

import ErrorAlert from '../../ui/ErrorAlert';
import PaginationControlled from '../../ui/Pagination';

import { useYearsFilter } from '../../../hooks/useYearsFilter';


const GeneralClientFiles = ({ data, selectedFolder, setSelectedFolder, userToken }) => {

    const {
        years,
        selectedYear,
        handleSelectChange,
        filteredList,
    } = useYearsFilter(selectedFolder?.files);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(15);

    const handleBack = (e) => {
        e.preventDefault();
        setSelectedFolder(null)
    }

    // Update files list if file was updated 
    useEffect(() => {
        if (data) {
            const selectedItem = data.find(item => item.name === selectedFolder.name);
            setSelectedFolder(selectedItem);
        }
    }, [data, selectedFolder]);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredList ? filteredList.slice(indexOfFirstRecord, indexOfLastRecord) : [];
    const nPages = filteredList ? Math.ceil(filteredList.length / recordsPerPage) : 0;

    const downloadFile = async (id, filename, mimetype) => {
        const config = {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };
        
        try {
            const response = await axios.get(`/api/c/dashboard/file/download/${id}`, config);
            const file = new Blob([response.data], { type: mimetype });
      
            if (file.size === 0) {
                setError('Error while downloading file. The file is empty.');
                return;
            }

            const realFileName = filename;
      
            download(file, realFileName, mimetype);
        } catch (error) {
            console.log(error)

            if (error.response && error.response.status === 400) {
                setError('Error while downloading file. Try again later.');
                return;
            }
            setError('Error while downloading file. Please check your internet connection.');
        }
    };

    return (
        <>
            {selectedFolder && selectedFolder?.files? (
                <Box id="client-files-container">
                    <Grid container mb={2}>
                        <Grid item>
                            <Button startIcon={<ChevronLeftIcon />} className='files-go-back-btn' onClick={handleBack}>
                                Go Back
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className="top-section" justifyContent="space-between">
                        <Grid item className="breadcrumb-container">
                            <Breadcrumbs>
                                <Typography className='breadcrumb-main' onClick={handleBack}>All Documents</Typography>
                                <Typography>{selectedFolder.name}</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item className="year-select-container">
                            <FormControl fullWidth>
                                <InputLabel id="year-select-label">Year</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    id="year-select"
                                    size="small" 
                                    value={selectedYear}
                                    label="Year"
                                    onChange={handleSelectChange}
                                >
                                    {years ? (
                                        years
                                        .sort((a, b) => a + b)
                                        .map((item, i) => (
                                            <MenuItem key={i+45} value={item}>{item}</MenuItem>
                                        ))
                                    ) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {selectedFolder?.files.length > 0 ? (
                        <>
                            <Grid container sx={{width: "100%"}}>
                                <Grid item xs={12}>
                                    <List className="file-list">
                                        {currentRecords && currentRecords .map((item, i) => (
                                            <ListItem key={i+55} className="file-list-item">
                                                <Link href={item.file_url} target="_blank">
                                                    <>
                                                        <img src={fileIcons[getFileExtension(item.file_name)] || fileIcons.default} alt="file icon" className='file-icon' />
                                                        {item.file_name}
                                                    </>
                                                </Link>
                                                <ListItemSecondaryAction>
                                                    <IconButton 
                                                        onClick={() => 
                                                        downloadFile(item._id, item.file_name, item.file_mimetype)}
                                                    >
                                                        <FileDownloadIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" mt={4}>
                                <Grid item>
                                    <PaginationControlled
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage} 
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Typography variant="subtitle1" sx={{ m: 2, color: '#777' }}>
                            No files in this folder
                        </Typography>
                    )}
                </Box>
            ) : (
               <p>Error</p>
            )}
            {error && <ErrorAlert show={error} message={error} />} 
        </>
    )
}

export default GeneralClientFiles;