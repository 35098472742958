import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const SuccessAlert = (props) => {
  	const [message] = useState(props.message);
  	const [show, setShow] = useState(props.show);


  	return (
    	<>
			<Collapse in={show} className="alert-message-block">
				<Alert 
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => { setShow(false) }}
						>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					}
				>
					{message}
				</Alert>
			</Collapse>
    	</>
  )
};

export default SuccessAlert;
