import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

// import FileUpload from '../../../general/FileUpload';
import ModalFileUpload from './ModalUploadFiles';
import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';


import { capitalize } from '@mui/material';



const AddNewFiles = ({ open, setOpen, folders, businessId, businessName, setChildChanges, userType }) => {

    const [newUserFiles, setNewUserFiles] = useState({
    	userFiles: [],
		taxYear: '',
		folder: 'uncategorized',
		userType: userType
  	});
	const { userToken } = useSelector((state) => state.user);
	const { id } = useParams();
    const [errorMsg, setErrorMsg] = useState('');
	const [isUploaded, setIsUploaded] = useState(false);
	const [yearError, setYearError] = useState('');
	const [loading, setLoading] = useState(false)

    const updateUploadedFiles = (files) => {
        setNewUserFiles({ ...newUserFiles, userFiles: files });
    }

    const handleClose = () => {
		setChildChanges((prevChanges) => !prevChanges);
        setOpen(false);
		setIsUploaded(false);
		setErrorMsg('');
		setNewUserFiles({
			userFiles: [],
			taxYear: '',
			folder: 'uncategorized',
			userType: userType
		})
		setYearError('');
		setLoading(false);
    }

	const handleInputChange = (event) => {
        const { name, value } = event.target;

		if (name === "taxYear" && !/^\d{0,4}$/.test(value)) {
			return;  // Don't update the state
		}

		// if (name === 'year' && value && parseInt(value) > new Date().getFullYear()) {
		// 	setYearError('Year cannot be more than the current year.');
		// } else if (name === 'year') {
		// 	setYearError(''); 
		// }

        setNewUserFiles({ ...newUserFiles, [name]: value });
    }

    const handleOnSubmit = async (e) => {
    	e.preventDefault();

		if (yearError !== '') {
			setErrorMsg('Please enter all the field values.');
			return;  // Exit the function early
		}

		if (newUserFiles.userFiles.length === 0) {
			setErrorMsg('Please select a file to add.');
			return;  // Exit the function early
		}

		setLoading(true);

    	try {
			const formData = new FormData();
			for (let i = 0; i < newUserFiles.userFiles.length; i++) {
				formData.append("files", newUserFiles.userFiles[i]);
			}
			formData.append('taxYear', newUserFiles.taxYear);
			formData.append('folder', newUserFiles.folder);
			if(userType) {
				formData.append('userType', newUserFiles.userType);
			}
			

        	const url = businessId ? `/api/client-page/files/add/${id}/${businessId}` : `/api/client-page/files/add/${id}`;
			const res = await axios.post(url, formData, {
				headers: {
					Authorization: `Bearer ${userToken}`,
					'Content-Type': 'multipart/form-data'
				}
			});

			if (res.data.error) {
				setIsUploaded(false);
				setErrorMsg(res.data.error)
			} else {
				setIsUploaded(true);
				setErrorMsg('');
			}
		} catch (error) {
			console.log(error);
			setErrorMsg('Something went wrong. Please try again later.')
		} finally {
			setLoading(false);
		}
  	}

    return (
        <>
            <Dialog
        	    aria-labelledby="upload-documents-modal"
        	    aria-describedby="upload-documents-modal"
        	    open={open}
			    onClose={handleClose}
                fullWidth
				id="admin-documents-dialog"
                maxWidth="md"
      	    >
				<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="upload-dialog-header">
						<Grid item>
							<Chip color="warning" label={businessName ? `${businessName} File Upload` : 'Personal File Upload'} />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					{errorMsg && <Typography className='dialog-error-msg' >{errorMsg}</Typography>}
					{isUploaded ? (
						<Box className="admin-doc-modal">
							<DialogSuccessMsg msg="Files uploaded successfully" />
						</Box>
					) : (
						<Box mt={5} mb={5} className="admin-doc-modal" component="form" onSubmit={handleOnSubmit}>
							<ModalFileUpload label="Documents Upload" multiple updateFilesCb={updateUploadedFiles} />
							<Grid container justifyContent="start" className='additional-fields' mt={1} spacing={2}>
								<Grid item xs={6}>
									<TextField 
										fullWidth 
										label="Year" 
										size="small" 
										name='taxYear'
										value={newUserFiles.taxYear}
										onChange={handleInputChange}
										error={!!yearError}
    									helperText={yearError}
										inputProps={{
											maxLength: 4,
											pattern: "\\d{4}"
										}}
									/>
								</Grid>
							</Grid>
							<Grid container justifyContent="start" className='additional-fields' mt={1} spacing={2}>
								{folders && folders.length > 0 ? (
								<Grid item xs={6}>
									<TextField 
										select 
										name="folder" 
										label="Folder" 
										fullWidth 
										size="small"
										value={newUserFiles.folder}
										onChange={handleInputChange}
									>
										{folders.map((item) => (
											<MenuItem value={item} key={item}>
													{capitalize(item)}
											</MenuItem>
										))}
									</TextField>
								</Grid> 
							) : null} 
							</Grid>
							<Grid container justifyContent="flex-end" sx={{width: '95%'}}>
								<Grid item xs={2}>
									<Button 
										className='add-files-btn' 
										type="submit"
										disabled={yearError || loading}
									> 
										{loading ? (
                                            <>
                                                Submit&nbsp;
                                                <CircularProgress size={18} color="inherit" />
                                            </>
                                        ) : (
                                            'Submit'
                                        )}
									</Button>
								</Grid>
							</Grid>
						</Box>
					)}
				</Container>
      		</Dialog>
        </>
    )
}

export default AddNewFiles;