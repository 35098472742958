import React from 'react';
import { Grid, Box, Typography, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import QuickbooksLogo from '../../assets/logos/quickbook-cropped.png';
import WaveLogo from '../../assets/logos/wave-llogo-cropped.png'

const plans = [
    {
        title: "Essentials",
        price: "$50/mo",
        features: [
            "Track income & expenses",
            "Invoice & accept payments",
            "Maximize tax deductions",
            "Run enhanced reports",
            "Capture & organize receipts",
            "Track miles",
            "Manage cash flow",
            "Track sales & sales tax",
            "Send estimates",
            "Manage 1099 contractors",
            "Includes 3 users",
            "Manage & pay bills",
            "Track time"
        ]
    },
    {
        title: "Plus",
        price: "$76/mo",
        features: [
            "Track income & expenses",
            "Invoice & accept payments",
            "Maximize tax deductions",
            "Run comprehensive reports",
            "Capture & organize receipts",
            "Track miles",
            "Manage cash flow",
            "Track sales & sales tax",
            "Send estimates",
            "Manage 1099 contractors",
            "Includes 5 users",
            "Manage & pay bills",
            "Track time",
            "Track inventory"
        ]
    },
    {
        title: "Advanced",
        price: "$175/mo",
        features: [
            "Track income & expenses",
            "Invoice & accept payments",
            "Maximize tax deductions",
            "Run most powerful reports",
            "Capture & organize receipts",
            "Track miles",
            "Manage cash flow",
            "Track sales & sales tax",
            "Send estimates",
            "Manage 1099 contractors",
            "Includes 25 users",
            "Manage & pay bills",
            "Track time",
            "Track inventory",
            "Track project profitability",
            "Business analytics & insights",
            "Batch invoices & expenses",
            "Customize access by role",
            "Exclusive Premium Apps",
            "Dedicated account team",
            "On-demand online training",
            "Automate workflows",
            "Restore company data"
        ]
    }
];

const PricingGrid = () => {
    return (
        <Grid container spacing={3} justifyContent="center">
            {plans.map((plan, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card variant="outlined" className="bookkeeping-item-2">
                        <CardContent>
                            <Box component="a" href="#" sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'center' }}>
                                <img src={QuickbooksLogo} alt="QuickBooks" style={{ height: 24, marginRight: 8 }} />
                            </Box>
                            <Typography component="h3" variant="h5" align="center" gutterBottom>
                                {plan.title}
                            </Typography>
                            <Typography component="p" variant="h6" align="center" gutterBottom>
                                {plan.price}
                            </Typography>
                            <List>
                                {plan.features.map((feature, idx) => (
                                    <ListItem key={idx}>
                                        <ListItemIcon>
                                            <CheckIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary={feature} />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default PricingGrid;