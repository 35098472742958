import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { verifyEmail, loginUser, verifyOtp } from "../../../actions/authActions";
import { useNavigate, Link } from 'react-router-dom';
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const OtpComponent= ({ email, handlePasswordSubmit }) => {

    const [otp, setOtp] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [remainingTime, setRemainingTime] = useState(300);
    const [isExpired, setIsExpired] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(verifyOtp({ email, otp }));
            if(result.error) {
                setError('Invalid or expired OTP')
            }
            if(result.payload.view === 1) {
                navigate('/admin-profile')
            }else if(result.payload.view === 2) {
                navigate('/dashboard')
            }else if(result.payload.view === 3) {
                navigate('/client-accounts')
            }else if(result.payload.view === 4) {
                navigate('/profile')
            }
        }catch (error) {
            console.log(error)
            alert(error.message); // Or handle the error in another way
        }
    };

    const handleResend = (e) => {
        handlePasswordSubmit(e);
        setOtp('');
        setError('');
        setRemainingTime(300);
        setIsExpired(false);
    }

    useEffect(() => {
        const countdown = setInterval(() => {
          setRemainingTime((prevTime) => {
            if (prevTime > 0) {
              return prevTime - 1;
            } else {
              clearInterval(countdown);
              setIsExpired(true);
              return 0;
            }
          });
        }, 1000);
    
        return () => {
          clearInterval(countdown);
        };
      }, []);

    return (
        <>
            <Box className='form-wrapper'>
                <Grid container className='form-header'>
                    <Grid item xs={12}>
                        <Typography component="h1">One Time Password</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="p">Verification code sent to <span>{email}</span> </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {isExpired ? (
                            <Box className='resend-otp'>
                                <Typography component="p" sx={{marginBottom: 0}}>Code expired</Typography>
                                <Button type='submit' onClick={handleResend}>Resend code</Button>
                            </Box>
                            ) : (
                            <Typography component="p">
                                Code expires in <small>{Math.floor(remainingTime / 60)}:{(remainingTime % 60).toString().padStart(2, '0')}</small>
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Box component="form" role="form" onSubmit={handleOtpSubmit} className='login-form'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="One Time Password"
                                required 
                                fullWidth
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                    
                                        handleOtpSubmit(e); // Call your submit handler
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className='resend-otp'>
                            <Typography component="p">Did not get code?</Typography>
                            <Button type='submit' onClick={handleResend}>Resend code</Button>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className="login-button"
                                    sx={{ mt: 1, mb: 2 }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {error && (
                    <Grid container className='login-error-container' justifyContent="center">
                        <Grid item xs={8}>
                            <ErrorOutlineIcon />
                            <Box>
                                <Typography component="p">{error}</Typography>
                                <Button type='submit' onClick={handleResend}>Resend code</Button>
                            </Box>
                            
                        </Grid>

                    </Grid>
                )}
            </Box>
        </>
    )
}

export default OtpComponent;