import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import TrashBin from '../../../../assets/icons/dustbin.png';
import DialogSuccessMsg from '../../../ui/DialogSuccessMsg';

import { useDeleteData } from '../../../../hooks/useDeleteData';

const DeleteFile = ({ open, setOpen, currentFile, userToken, setSelectedFolder, setChildChanges }) => {


    const [handleDelete, deleted, setDeleted, deleteError, deleteLoading] = useDeleteData(`/api/client-page/files/delete/${currentFile?._id}`, userToken);

    const handleClose = () => {
        setChildChanges((prevChanges) => !prevChanges);
        setOpen(false);
		setDeleted(false);
    }

    return (
        <>
            <Dialog
        	    aria-labelledby="delete-documents-modal"
        	    aria-describedby="delete-documents-modal"
        	    open={open}
			    // onClose={handleClose}
                fullWidth
				id="admin-documents-dialog"
                maxWidth="sm"
      	    >
				<Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                    <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item>
                            <Chip color="error" label="Delete Mode" />
                        </Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
                    {deleted ? (
                        <Box className="admin-doc-modal">
                            <DialogSuccessMsg msg="File was deleted successfully." />
                        </Box>
                    ) : (
                        <>
                            <Grid container justifyContent="center" mb={3}>
                                <Grid item xs={12} justifyContent="center" container>
                                    <Grid>
                                        <img src={TrashBin} alt="trash bin icon" />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle1'>Are you sure you want to delete this file?</Typography>
                                    {deleteError && <Typography color="secondary" variant='body1'>{deleteError}</Typography>}
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" mb={3}>
                                <Grid item>
                                    <Typography variant='body1'>"{currentFile?.file_name}"</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" mb={3} spacing={2}>
                                <Grid item>
                                    <Button 
                                        variant='outlined' 
                                        color='primary' 
                                        className='cancel-button' 
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        type="button" 
                                        variant='contained' 
                                        className='delete-button'
                                        disabled={deleteLoading}
                                        onClick={handleDelete}
                                    >
                                        {deleteLoading ? (
                                            <>
                                            Delete&nbsp;
                                            <CircularProgress size={24} color="inherit" />
                                            </>
                                        ) : (
                                            'Delete'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
				</Container>
      		</Dialog>
        </>
    )
}

export default DeleteFile;