import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { registerUser } from "../../actions/authActions";
import { logout } from "../../reducers/authReducers";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Skeleton from '@mui/material/Skeleton';

import SignUpImage from '../../assets/auth/sign-up.png'

import { maskPhone } from '../../utils/maskPhone.js';
import { validateForm } from "../../utils/registerValidation";

import ErrorAlert from '../ui/ErrorAlert';

import './auth.css'
import ErrorMsg from "../screens/ErrorScreen";
import LoadingGrid from "../ui/Loading";

import PersonalQuestions from "./RegisterScreenPersonal";


const RegisterScreen = () => {

    const { token } = useParams();
    const [errorToken, setErrorToken] = useState('');
    const [hasVerified, setHasVerified] = useState(false);
    const [loadingToken, setLoadingToken] = useState(false);
    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        password2: "",
        token: token ? token : ''
    });
    const [userRole, setUserRole] = useState('user');

    const { loading, error, success } = useSelector(
        (state) => state.user
    )
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPasswords, setShowPasswords] = useState({
        password: false,
        password2: false,
    });
    const [inputErrors, setInputErrors] = useState({});
    const [personalErrors, setPersonalErrors] = useState({});
    const [touchedPassword2, setTouchedPassword2] = useState(false);
    const [password2Valid, setPassword2Valid] = useState(false);

    useEffect(() => {

        dispatch(logout())
    }, [token])

    const handleClickShowPassword = (field) => () => {
        setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
    };

    const handleChange = (e) => {
        let value = e.target.value;
        
        if (e.target.name === "phone") {
          maskPhone(e);
          value = e.target.value; 
        }

        if (e.target.name === "password2") {
			setTouchedPassword2(true);
            setPassword2Valid(value === formValues.password);
        }
        
        setFormValues({
          ...formValues,
          [e.target.name]: value
        });
    };

    const handlePersonalQuestionsUpdate = (data) => {
        setFormValues({
            ...formValues,
            ...data
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoadingToken(true);
            try {
                const response = await axios.get(`/api/auth/verify-token/${token}`);
                
                if(response.status === 200) {
                    setUserRole(response.data.role)
                }
            } catch (error) {
                console.log(error);
                setErrorToken("Invalid Token");
            } finally {
                setLoadingToken(false);
                setHasVerified(true);  // Set verification to true here
            }
        };
    
        fetchData();
    }, [token]);


    useEffect(() => {
        if(success) navigate('/login')
    }, [navigate, success]);

    const validatePersonalInputs = () => {
        let tempErrors = {};

        if(!formValues.isMarried) {
            tempErrors.isMarried = "Please specify your marital status.";
        }
        if(formValues.isMarried === "yes" && !formValues.fileSeparateReturns) {
            tempErrors.fileSeparateReturns = "Please answer if you and your spouse want to file separate returns.";
        }
        if(formValues.isMarried === "yes" && formValues.fileSeparateReturns === "no" && !formValues.spouseAccount) {
            tempErrors.spouseAccount = "Please specify if your spouse has an account with TaxBox.";
        }
        if(formValues.isMarried === "yes" && formValues.fileSeparateReturns === "no" && formValues.spouseAccount === "yes" && !formValues.spouseEmail) {
            tempErrors.spouseEmail = "Please provide your spouse's email.";
        }

        setPersonalErrors(tempErrors);
        return Object.keys(tempErrors).length === 0; // return true if no errors
    }


    const submitForm = (e) => {
        e.preventDefault();

        // Assume personal inputs are valid by default
        let isPersonalValid = true;

        // If the user role is 'user', validate the personal inputs
        if (userRole === 'user') {
            isPersonalValid = validatePersonalInputs();
        }

        const validationErrors = validateForm(formValues);
        setInputErrors(validationErrors);
        // If there are errors, handle them (e.g., show error messages)
        if (!isPersonalValid || Object.keys(validationErrors).length > 0) {
            return;
        }
        dispatch(registerUser(formValues))
    }

    if(!hasVerified || loadingToken) {
        return (
            <Box id="register-screen">
                <Grid container className="grid-container" justifyContent="space-around">
                    <Grid item className="image-container">
                        <img src={SignUpImage} />
                    </Grid>
                    <Grid item xs={5}>
                        <Skeleton height={50} />
                        <Skeleton height={50} />
                        <Skeleton height={50} />
                        <Skeleton height={50} />
                        <Skeleton height={100} />
                        <Skeleton height={150} />
                    </Grid>
                </Grid>
            </Box>
        );
    }
    
    if(!token || errorToken) {
        return <ErrorMsg msg="No access to this page" />;
    }

   
    


    return (
        <>
            <Box id="register-screen">
                <Grid container className="grid-container" justifyContent="space-around">
                    <Grid item className="image-container">
                        <img src={SignUpImage} />
                    </Grid>
                    <Grid item container className="form-container" component="form" role="form" onSubmit={submitForm}>
                        <Grid item xs={12} mb={4}>
                            <Typography component="h1">Create account</Typography>
                        </Grid>
                        <Grid item xs={12} mb={2} className="form-subtitle">
                            <Typography component="h3">Account Details</Typography>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <TextField
                                name="firstName"
                                label="First Name" 
                                size="small"
                                fullWidth
                                required
                                value={formValues.firstName}
                                onChange={handleChange}
                                error={!!inputErrors.firstName}
                                helperText={inputErrors.firstName}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <TextField 
                                label="Last Name" 
                                name="lastName"
                                size="small"
                                fullWidth 
                                required
                                value={formValues.lastName}
                                onChange={handleChange}
                                error={!!inputErrors.lastName}
                                helperText={inputErrors.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <TextField 
                                label="Email" 
                                name="email"
                                size="small"
                                fullWidth 
                                required 
                                value={formValues.email}
                                onChange={handleChange} 
                                error={!!inputErrors.email}
                                helperText={inputErrors.email}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <TextField 
                                name="phone"
                                label="Phone #"
                                size="small"
                                fullWidth   
                                value={formValues.phone}
                                onChange={handleChange} 
                                required
                                error={!!inputErrors.phone}
                                helperText={inputErrors.phone}
                            />
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <TextField 
                                name="password"
                                type={showPasswords.password ? 'text' : 'password'}
                                label="Password" 
                                size="small"
                                fullWidth 
                                required 
                                value={formValues.password}
                                onChange={handleChange}
                                error={!!inputErrors.password}
                                helperText={inputErrors.password}
                                InputProps={{
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            <IconButton
                                                name="1"
                                                edge="end"
                                                onClick={handleClickShowPassword('password')}
                                            >
                                                {showPasswords.password ? <VisibilityOffIcon /> : <VisibilityIcon /> }

                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} 
                            />
                        </Grid>
                        <Grid item xs={12} mb={3}>
                            <TextField 
                                name="password2"
                                type={showPasswords.password2 ? 'text' : 'password'} 
                                label="Confirm Password" 
                                size="small"
                                fullWidth 
                                required 
                                value={formValues.password2}
                                onChange={handleChange}
                                error={!!inputErrors.password2 || (touchedPassword2 && !password2Valid)}
                                helperText={inputErrors.password2}
                                InputProps={{
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={handleClickShowPassword('password2')}
                                            >
                                                {showPasswords.password2 ? <VisibilityOffIcon /> : <VisibilityIcon /> }

                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} 
                            />
                        </Grid>
                        {userRole === 'user' && (
                            <PersonalQuestions onUpdate={(data) => handlePersonalQuestionsUpdate(data)} personalErrors={personalErrors} />
                        )}
                        
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Grid item>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className="auth-button"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign Up
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {error && <ErrorAlert show={!!error} message={Object.values(error)[0]} />}
        </>
    )
}

export default RegisterScreen;