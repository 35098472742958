import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{ py: 3, px: 2, mt: 'auto'}}
        >
            <Container maxWidth="lg" >
               <Typography component="h6">Copyright © 2023 TaxBox., Ltd. All Rights Reserved.</Typography>
               <Typography component="p">Developed by BY</Typography>
            </Container>
        </Box>
    );
}

export default Footer;