import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import download from 'downloadjs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { fileIcons, getFileExtension } from '../../../../utils/fileIcons.js'

import ErrorAlert from '../../../ui/ErrorAlert';
import EditFileInfo from './EditFileInfo';
import DeleteFile from './DeleteFile.js';
import EditMultipleFiles from './EditMultipleFiles.js';
import CopyFiles from './CopyFiles.js';
import PaginationControlled from '../../../ui/Pagination.js';

import { useYearsFilter } from '../../../../hooks/useYearsFilter';



const ClientFiles = ({ data, selectedFolder, setSelectedFolder, userToken, businesses, folders, setChildChanges, businessId }) => {

    const {
        years,
        selectedYear,
        handleSelectChange,
        filteredList,
    } = useYearsFilter(selectedFolder?.files);
    const [error, setError] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [isEditModeForMultipleFiles, setEditModeForMultipleFiles] = useState(false);
    const [isCopyMode, setCopyMode] = useState(false)
    const [currentFile, setCurrentFile] = useState(null);
    const [isDeleteMode, setDeleteMode] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFileNames, setSelectedFileNames] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(15);

    useEffect(() => {
       setSelectedFiles([]);
       setSelectedFileNames([])
    }, [selectedYear])

    const handleBack = (e) => {
        e.preventDefault();
        setSelectedFolder(null)
    }

    const handleEditOpen = (item) => {
        setEditMode(true);
        setCurrentFile(item);
    }

    const handleDeleteOpen = (item) => {
		setCurrentFile(item);
    	setDeleteMode(true)
 	}

    const handleEditOpenForMultipleFiles = (item) => {
        setEditModeForMultipleFiles(true);
    }

    const handleCopyOpen = () => {
        setCopyMode(true)
    }

    const handleCheckboxChange = (fileId, fileName, taxYear) => {
        setSelectedFiles(prevSelectedFiles =>
            prevSelectedFiles.includes(fileId)
                ? prevSelectedFiles.filter(id => id !== fileId)
                : [...prevSelectedFiles, fileId]
        );

        setSelectedFileNames(prevSelectedFileNames => {
            const fileIndex = prevSelectedFileNames.findIndex(file => file.file_name === fileName);
    
            if (fileIndex > -1) {
                // Remove the file object if it already exists
                return prevSelectedFileNames.filter(file => file.file_name !== fileName);
            } else {
                // Add a new file object
                const newFile = { file_name: fileName, taxYear: taxYear };
                return [...prevSelectedFileNames, newFile];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedFiles.length === filteredList.length) {
            setSelectedFiles([]); 
            setSelectedFileNames([]); 
        } else {
            setSelectedFiles(filteredList.map(item => item._id)); 
            setSelectedFileNames(filteredList.map(item => {
                return {
                    file_name: item.file_name, 
                    taxYear: item.taxYear
                };
            }));
        }
    };
    

    // Update files list if file was updated 
    useEffect(() => {
    if (data) {
        const selectedItem = data.find(item => item.name === selectedFolder.name);
        setSelectedFolder(selectedItem);
    }
    }, [data, selectedFolder]);

    const downloadFile = async (id, filename, mimetype) => {
        const config = {
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        };
        
        try {
            const response = await axios.get(`/api/client-page/file/download/${id}`, config);
            const file = new Blob([response.data], { type: mimetype });
      
            if (file.size === 0) {
                setError('Error while downloading file. The file is empty.');
                return;
            }

            const realFileName = filename;
      
            download(file, realFileName, mimetype);
        } catch (error) {
            console.log(error)

            if (error.response && error.response.status === 400) {
                setError('Error while downloading file. Try again later.');
                return;
            }
            setError('Error while downloading file. Please check your internet connection.');
        }
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredList ? filteredList.slice(indexOfFirstRecord, indexOfLastRecord) : [];
    const nPages = filteredList ? Math.ceil(filteredList.length / recordsPerPage) : 0;

    return (
        <>
            {selectedFolder && selectedFolder?.files ? (
                <Box id="admin-folders-files-container">
                    <Grid container mb={2}>
                        <Grid item>
                            <Button startIcon={<ChevronLeftIcon />} className='files-go-back-btn' onClick={handleBack}>
                                Go Back
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className="top-section" justifyContent="space-between">
                        <Grid item className="breadcrumb-container">
                            <Breadcrumbs>
                                <Typography className='breadcrumb-main' onClick={handleBack}>All Documents</Typography>
                                <Typography>{selectedFolder.name}</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item className="year-select-container">
                            <FormControl fullWidth>
                                <InputLabel id="year-select-label">Year</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    id="year-select"
                                    size="small" 
                                    value={selectedYear}
                                    label="Year"
                                    onChange={handleSelectChange}
                                >
                                    {years ? (
                                        years
                                        .sort((a, b) => a + b)
                                        .map((item, i) => (
                                            <MenuItem key={i} value={item}>{item}</MenuItem>
                                        ))
                                    ) : null}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                        
           
                    {selectedFolder?.files.length > 0 ? (
                        <>
                            <Grid container className='multiple-edit-btn-wrapper'>
                                <Grid item>
                                    <Button
                                        onClick={handleSelectAll}
                                        className='select-all-btn'
                                    >
                                        Select All
                                    </Button>
                                </Grid>
                                {selectedFiles.length > 0 && (
                                    <>
                                        <Grid item>
                                            <Button
                                                className='multiple-edit-btn'
                                                onClick={handleEditOpenForMultipleFiles}
                                            >
                                                Edit
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                className='multiple-copy-btn'
                                                onClick={handleCopyOpen}
                                            >
                                                Copy
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid container sx={{width: "100%"}}>
                                <Grid item xs={12}>
                                    <List className="file-list">
                                        {currentRecords && currentRecords.map((item, i) => (
                                            <ListItem key={i} className="file-list-item">
                                                <Checkbox
                                                    checked={selectedFiles.includes(item._id)}
                                                    onChange={() => handleCheckboxChange(item._id, item.file_name, item.taxYear)}
                                                />
                                                <a href={item.file_url} target="_blank" rel="noopener noreferrer">
                                                    <>
                                                        <img src={fileIcons[getFileExtension(item.file_name)] || fileIcons.default} alt="file icon" className='file-icon' />
                                                        {item.file_name}
                                                    </>
                                                </a>
                                                <ListItemSecondaryAction>
                                                    <IconButton 
                                                        onClick={() => 
                                                        downloadFile(item._id, item.file_name, item.file_mimetype)}
                                                    >
                                                        <FileDownloadIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleEditOpen(item)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDeleteOpen(item)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" mt={4} mb={4}>
                                <Grid item>
                                    <PaginationControlled
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage} 
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <Typography variant="subtitle1" sx={{ m: 2, color: '#777' }}>
                            No files in this folder
                        </Typography>
                    )}
                    <EditFileInfo 
                        open={isEditMode} 
                        setOpen={setEditMode} 
                        currentFile={currentFile} 
                        businesses={businesses}
                        userToken={userToken}
                        folders={folders}
                        setChildChanges={setChildChanges}
                    />
                    <EditMultipleFiles 
                        open={isEditModeForMultipleFiles}
                        setOpen={setEditModeForMultipleFiles}
                        businesses={businesses}
                        folders={folders}
                        selectedFolder={selectedFolder.name}
                        userToken={userToken}
                        setChildChanges={setChildChanges}
                        selectedFiles={selectedFiles}
                        businessId={businessId}
                        setSelectedFiles={setSelectedFiles}
                    />
                    <DeleteFile 
                        open={isDeleteMode}
                        setOpen={setDeleteMode}
                        currentFile={currentFile} 
                        userToken={userToken}
                        setSelectedFolder={setSelectedFolder}
                        setChildChanges={setChildChanges}
                    />
                    <CopyFiles 
                        open={isCopyMode}
                        setOpen={setCopyMode}
                        selectedFileNames={selectedFileNames}
                        selectedFiles={selectedFiles}
                        setSelectedFileNames={setSelectedFileNames}
                        setSelectedFiles={setSelectedFiles}
                        setChildChanges={setChildChanges}
                    />
                </Box>
            ) : (
               <p>Error</p>
            )}
            {error && <ErrorAlert show={error} message={error} />} 
            

        </>
    )
}

export default ClientFiles;