import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import axios from "axios";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import { maskEin } from '../../../utils/maskEin';

import { useFetchData } from '../../../hooks/useFetchData';

import ErrorAlert from '../../ui/ErrorAlert'

import MergeAccImg from '../../../assets/merge-acc.png'


const GeneralBusinessForm2 = ({ userToken, handleNextStep, userType}) => {

    const [businesses, setBusinesses] = useState([{
        businessName: '',
        ein: '',
        dba: '',
        fka: ''
    }]);
    const [loading, data, error] = useFetchData(`/api/client/questionnaire/business?userType=${userType}`, userToken);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(null);


    const handleInputChange = (index, e) => {
        let value = e.target.value;
        const updatedBusinesses = [...businesses];
        if (e.target.name === "ein") {
            maskEin(e);
            value = e.target.value; 
        }
        updatedBusinesses[index][e.target.name] = value;
        setBusinesses(updatedBusinesses);
    };

    const addInputSet = () => {
        setBusinesses([...businesses, { businessName: '', ein: '', dba: '', fka: '' }]);
    };

    const removeInputSet = (indexToRemove) => {
        const updatedBusinesses = businesses.filter((_, index) => index !== indexToRemove);
        setBusinesses(updatedBusinesses);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setIsError(null);
    
        try {
            const response = await axios.post(`/api/client/general-application/business?userType=${userType}`, {
                businesses: businesses
            }, {
                headers: {
                    'Authorization': `Bearer ${userToken}`
                }
            });
    
            if (response.status === 200) {
                setBusinesses([{businessName: '', ein: '', dba: '', fka: ''}])
                handleNextStep()
            }
    
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.data && error.response.data.error) {
                setIsError(error.response.data.error);
            } else {
                setIsError('Something went wrong. Please try again later.');
            }
        }
    };



    return (
        <Box id="business-application-form">
            <Grid container justifyContent="space-between" alignItems="center" mb={4}>
                {(userType && userType === 2 && data && data.length > 0) && (
                    <Grid item className='note-wrapper' px={2} xs={8}>
                        <Typography component="p">
                        Notice: In this merged account view, the 'Business' section is display-only. If you wish to add a new business, please do so from your individual account later.
                        </Typography>
                    </Grid>
                )}
                {(userType && userType === 2 && data && data.length === 0) && (
                    <Grid item className='note-wrapper' px={2} xs={8}>
                        <Typography component="p">
                        Notice: In this merged account view, the 'Business' section is display-only. You currently have no businesses linked to this merged account. If you wish to add a business, please do so from your individual account.
                        </Typography>
                    </Grid>
                )}
                {(data && data.length > 0) && (
                    <Grid item>
                        <Button
                            onClick={handleNextStep}
                            className='skip-business-application'
                        >
                            Continue
                        </Button>
                    </Grid>
                )} 
            </Grid>
            {(userType && userType === 1) && (
                <>
                    <Grid container component="form" onSubmit={handleSubmit}>
                        <Grid item container alignItems="center">
                            {(data && data.length > 0) && (
                                data.map((item, index) => (
                                    <Grid item container xs={5} key={index}>
                                        <Grid item container xs={11} className='business-input-set' mb={2}>
                                            <Grid item xs={12} mb={2}>
                                                <TextField 
                                                    label="Business Name"
                                                    size="small"
                                                    name="businessName"
                                                    fullWidth
                                                    required
                                                    value={item.businessName}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} mb={2}>
                                                <TextField 
                                                    label="EIN"
                                                    size="small"
                                                    name="ein"
                                                    fullWidth
                                                    required
                                                    value={item.ein}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} mb={2}>
                                                <TextField 
                                                    label="DBA"
                                                    size="small"
                                                    name="dba"
                                                    fullWidth
                                                    value={item.dba}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} mb={2}>
                                                <TextField 
                                                    label="FKA"
                                                    name="fka"
                                                    size="small"
                                                    fullWidth
                                                    value={item.fka}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            )}
                            {businesses.map((_, index) => (
                                <Grid item container xs={5} key={index}>
                                    <Grid item container xs={11} className='business-input-set' mb={2}>
                                        {index !== 0 && (
                                            <Grid item xs={12} container justifyContent="flex-end" className='delete-business-set-btn'>
                                                <Grid item>
                                                    <IconButton  onClick={() => removeInputSet(index)} >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} mb={2}>
                                            <TextField 
                                                label="Business Name"
                                                size="small"
                                                name="businessName"
                                                fullWidth
                                                required
                                                value={businesses[index]?.businessName}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mb={2}>
                                            <TextField 
                                                label="EIN"
                                                size="small"
                                                name="ein"
                                                fullWidth
                                                required
                                                value={businesses[index]?.ein }
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mb={2}>
                                            <TextField 
                                                label="DBA"
                                                size="small"
                                                name="dba"
                                                fullWidth
                                                value={businesses[index]?.dba}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mb={2}>
                                            <TextField 
                                                label="FKA"
                                                name="fka"
                                                size="small"
                                                fullWidth
                                                value={businesses[index]?.fka}
                                                onChange={(e) => handleInputChange(index, e)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={1} style={{ marginTop: '20px' }}>
                                <Button variant="contained" color="primary" onClick={addInputSet} className='add-business-set-btn'>
                                    +
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid xs={10} item container justifyContent="flex-end" mt={3}>
                            <Grid item>
                                <Button 
                                    className='submit-business-application-btn'
                                    type='submit'
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            Submit&nbsp;
                                            <CircularProgress size={18} color="inherit" />
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            {(userType && userType === 2) && (
                <>
                    {(data && data.length > 0) ? (
                        data.map((item, index) => (
                            <Grid item container xs={5} key={index}>
                                <Grid item container xs={11} className='business-input-set' mb={2}>
                                    <Grid item xs={12} mb={2}>
                                        <TextField 
                                            label="Business Name"
                                            size="small"
                                            name="businessName"
                                            fullWidth
                                            required
                                            value={item.businessName}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <TextField 
                                            label="EIN"
                                            size="small"
                                            name="ein"
                                            fullWidth
                                            required
                                            value={item.ein}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <TextField 
                                            label="DBA"
                                            size="small"
                                            name="dba"
                                            fullWidth
                                            value={item.dba}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <TextField 
                                            label="FKA"
                                            name="fka"
                                            size="small"
                                            fullWidth
                                            value={item.fka}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    ) : (
                        <Box id="skip-documents-box">
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <img src={MergeAccImg} />
                                </Grid>
                            </Grid>
                        </Box>
                    )}    
                </>
            )}   
            {isError && <ErrorAlert show={!!isError} message="Error" />}
        </Box>
    )
}

export default GeneralBusinessForm2;