import React, { useState, useEffect } from 'react';
import axios from "axios"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';


import ErrorAlert from '../../../ui/ErrorAlert';
import SuccessAlert from '../../../ui/SuccessAlert';


const PasswordSettings = (props) => {
    const [passwords, setPasswords] = useState({
        password: "",
        newPassword: "",
        newPassword2: ""
    });
    const [showPasswords, setShowPasswords] = useState({
        password: false,
        newPassword: false,
        newPassword2: false
    });
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        number: false,
        lower: false,
        upper: false,
        special: false,
    });
    const [passwordError, setPasswordError] = useState(false);
    const [touchedConfirmPassword, setTouchedConfirmPassword] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClickShowPassword = (field) => () => {
        setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswords(prevPasswords => {
            const updatedPasswords = { ...prevPasswords, [name]: value };

            if (name === "newPassword") {
                // Update the password requirements
                setPasswordRequirements({
                    length: value.length >= 8,
                    number: /\d/.test(value),
                    lower: /[a-z]/.test(value),
                    upper: /[A-Z]/.test(value),
                    special: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value),
                });
            }

            if (name === "newPassword2") {
                setPasswordError(updatedPasswords.newPassword !== updatedPasswords.newPassword2);
            }

            return updatedPasswords;
        });
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        if (name === "newPassword2") {
            setTouchedConfirmPassword(true);
            setPasswordError(passwords.newPassword !== passwords.newPassword2);
        }
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const config = {
            headers: {
              Authorization: `Bearer ${props.userToken}`,
            }
        }
        try {
            const response = await axios.post(`/api/client/settings/p`, passwords, config);
            if(response.status === 200) {
                setUpdated(true);
                setLoading(false);
                setPasswords({
                    password: "",
                    newPassword: "",
                    newPassword2: ""
                })
            }
        } catch (err) {
            console.log(err)
            setError(err.response && err.response.data && err.response.data.error ? err.response.data.error : 'Could not save new password. Please try again later.');
            setLoading(false);
        }
    }

    return (
        <>
            <Container>
                <Grid container justifyContent="flex-start" mb={5}>
                    <Grid item>
                        <Typography component="h3">
                            Password
                        </Typography>
                    </Grid>
                </Grid>
                {/* {error && <Typography className='error-msg'>{error}</Typography>} */}
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={6} container>
                            <Grid item xs={12} mb={2}>
                                <TextField 
                                    type={showPasswords.password ? 'text' : 'password'}
                                    fullWidth 
                                    required
                                    label="Old Password"
                                    name='password'
                                    size="small"
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment:(
                                            <InputAdornment position="end">
                                                <IconButton
                                                    name="1"
                                                    edge="end"
                                                    onClick={handleClickShowPassword('password')}
                                                >
                                                    {showPasswords.password ? <VisibilityOffIcon /> : <VisibilityIcon /> }

                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}     
                                />
                            </Grid>
                            <Grid item xs={12} mb={2}>
                                <TextField 
                                    type={showPasswords.newPassword ? 'text' : 'password'}
                                    fullWidth 
                                    required
                                    label="New Password"
                                    name='newPassword'
                                    size="small"
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment:(
                                            <InputAdornment position="end">
                                                <IconButton
                                                    name="1"
                                                    edge="end"
                                                    onClick={handleClickShowPassword('newPassword')}
                                                >
                                                    {showPasswords.newPassword ? <VisibilityOffIcon /> : <VisibilityIcon /> }

                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}     
                                />
                            </Grid>
                            <Grid item xs={12} mb={2}>
                                <TextField 
                                    type={showPasswords.newPassword2 ? 'text' : 'password'}
                                    fullWidth 
                                    required
                                    label="Confirm Password"
                                    size="small"
                                    name='newPassword2'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={passwordError}
                                    InputProps={{
                                        endAdornment:(
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    onClick={handleClickShowPassword('newPassword2')}
                                                >
                                                    {showPasswords.newPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon /> }

                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}     
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="flex-end">
                                <Grid item>
                                    <Button 
                                        variant="contained" 
                                        className='save-btn'
                                        type='submit'
                                        disabled={passwordError || !Object.values(passwordRequirements).every(requirement => requirement) || Object.values(passwords).some(value => value === '')}
                                    >
                                        {loading ? (
                                            <>
                                                Save&nbsp;
                                                <CircularProgress size={16} color="inherit" />
                                            </>
                                        ) : (
                                            'Save'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5} container className='password-requirements-container'>
                            <Grid item xs={12}>
                                <Typography 
                                    component="p"
                                    className={passwordRequirements.length ? 'green' : 'grey'}
                                >
                                     <CheckIcon />
                                    Password must be at least 8 characters
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    component="p"
                                    className={passwordRequirements.number ? 'green' : 'grey'}
                                >
                                    <CheckIcon />
                                    Password must include a number
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    component="p"
                                    className={passwordRequirements.lower ? 'green' : 'grey'}
                                >
                                    <CheckIcon />
                                    Password must include a lower letter
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    component="p"
                                    className={passwordRequirements.upper ? 'green' : 'grey'}
                                >
                                     <CheckIcon />
                                    Password must include a capital letter
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography 
                                    component="p"
                                    className={passwordRequirements.special ? 'green' : 'grey'}
                                >
                                     <CheckIcon />
                                    Password must include a special character
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            {updated && <SuccessAlert show={updated} message={"Password was successfully updated!"} />}
            {error && <ErrorAlert show={!!error} message={error} />}
        </>
    )
}

export default PasswordSettings;
