import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/authReducers';
import adminClientReducers from './reducers/adminClientReducers';

const store = configureStore({
  reducer: {
    user: userReducer,
    adminClient: adminClientReducers,
  }
})

export default store;