import React from 'react';
import Box from '@mui/material/Box';


const PersonalClientInfo = ({ clientInfo }) => {
    
    return (
        <>
            <Box className="section-content">
                <div className="field">
                    <label htmlFor="firstName">First Name:</label>
                    <span id="firstName" style={{textTransform: 'capitalize'}}>{clientInfo.firstName}</span>
                </div>
                <div className="field">
                    <label htmlFor="lastName">Last Name:</label>
                    <span id="lastName" style={{textTransform: 'capitalize'}}>{clientInfo.lastName}</span>
                </div>
                <div className="field">
                    <label htmlFor="email">Email:</label>
                    <span id="email">
                        {clientInfo.email}
                    </span>
                </div>
                <div className="field">
                    <label htmlFor="phone">Phone Number:</label>
                    <span id="phone">{clientInfo.phone}</span>
                </div>
            </Box>
        </>
    )
}

export default PersonalClientInfo;