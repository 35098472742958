import * as React from 'react';
import Pagination from '@mui/material/Pagination';


const PaginationControlled = ({ nPages, currentPage, setCurrentPage }) => {

  const handleChange = (event, value) => {
    setCurrentPage(value)
  };

  return (
    <>
      <Pagination count={nPages} page={currentPage} onChange={handleChange} />
    </>
  )

};

export default PaginationControlled;