import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

const groups = ['All Questions', 'Personal Information', 'Personal Residence', 'Dependents', 'Work & Income Sources', 'Healthcare', 'Education', 
'Deductions and Credits', 'Investments', 'Retirement or Severance', 'Sale of Your Home', 'Gifts', 'Foreign Matters', 'Miscellaneous' ]

import {generateUniqueKey } from '../../../utils/generateKey';


const AllQuestionsView = ({ data, handleEditOpen, handleDeleteOpen }) => {


    return (
        <>
            {[...new Set(data.map(q => q.group))].map((group) => (
                <Box key={generateUniqueKey()}>
                    <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                        {group}
                    </Typography>
                    {data
                    .filter(question => question.group === group)
                    .sort((que1, que2) => que1.order - que2.order)
                    .map((question, index) => (
                        <Box className='questionnaire-list-item' key={generateUniqueKey()}>
                            <Grid container alignItems="center">
                                <Grid item className='questionnaire-number'>
                                    <Avatar>{question.order}</Avatar>
                                </Grid>
                                <Grid item xs className='questionnaire-text'>
                                    <Typography>{question.text} <span>({question.type === 'general' ? 'Yes/No' : 'Textfield'})</span> </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Edit">
                                        <IconButton onClick={() => handleEditOpen(question, index)}>
                                            <EditIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton onClick={() => handleDeleteOpen(question, index)}>
                                            <DeleteIcon color='error' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            {question.subQuestion && question.subQuestion.length > 0 && (
                                <Grid item xs={12} container className='questionnaire-subquestions-container'>
                                    <Typography sx={{ fontSize: '1rem', fontFamily: "Poppins", color: "#0b1fd1", fontWeight: 500, fontStyle: 'italic' }}>
                                        Sub Questions:
                                    </Typography>
                                    {question.subQuestion.map((item, index) => (
                                        <Grid xs={12} item key={generateUniqueKey()}>
                                            <Typography sx={{ fontSize: '0.9rem', fontFamily: "Poppins", color: "#131313", fontWeight: 400, pl:'5px' }}>
                                                {index + 1}. {item.subQuestionText}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                            {question.note && (
                                <Grid item xs={12} container className='questionnaire-note-container'>
                                    <Grid item xs={12}>
                                        <Typography xs={12} component='p' sx={{ fontSize: '1rem', fontFamily: "Poppins", color: "#0b1fd1", fontWeight: 500, fontStyle: 'italic' }}>
                                            Note for Questionnaire:
                                        </Typography>
                                    </Grid>
                                    <Typography component='p' sx={{ fontSize: '0.9rem', fontFamily: "Poppins", color: "#131313", fontWeight: 400, pl:'5px' }}>
                                        {question.note}
                                    </Typography>
                                </Grid>
                            )}
                            {question.noteForDocuments && (
                                <Grid item xs={12} container className='questionnaire-note-container'>
                                    <Grid item xs={12}>
                                        <Typography xs={12} component='p' sx={{ fontSize: '1rem', fontFamily: "Poppins", color: "#0b1fd1", fontWeight: 500, fontStyle: 'italic' }}>
                                            Note for Documents Upload:
                                        </Typography>
                                    </Grid>
                                    <Typography component='p' sx={{ fontSize: '0.9rem', fontFamily: "Poppins", color: "#131313", fontWeight: 400, pl:'5px' }}>
                                        {question.noteForDocuments}
                                    </Typography>
                                </Grid>
                            )}
                        </Box>
                    ))}
                </Box>
            ))}
        </>
    );
};

export default AllQuestionsView;