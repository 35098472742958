import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';


const MergeAccountBusinesses = ({businesses, selectedClient1, selectedClient2 }) => {

    return (
        <>
            {businesses.map((item) => {
                const client1InBusiness = item.users.find(u => u.user === selectedClient1.id );
                const client2InBusiness = item.users.find(u => u.user === selectedClient2.id);

                const client1Frozen = client1InBusiness ? !client1InBusiness.active : false;
                const client2Frozen = client2InBusiness ? !client2InBusiness.active : false;
            

                return (
                    <Grid item xs={12} className='business-item' key={item._id}>
                        <Box className='info-item'>
                            <Typography variant="h5">Business Name:</Typography>
                            <Typography variant="span">{item.businessName}</Typography>
                        </Box>
                        <Box className='info-item'>
                            <Typography variant="h5">Business EIN:</Typography>
                            <Typography variant="span">{item.ein}</Typography>
                        </Box>
                        {client1InBusiness && (
                            <Box className='info-item info-name-item'>
                                <Typography variant="h6">
                                    {selectedClient1.name}
                                </Typography>
                                {client1InBusiness.permissions === 'upload' ? (
                                    <>
                                        <Chip label="View" className='view-chip' variant="outlined"/>
                                        <Chip label="Upload" className='upload-chip' variant="outlined"/>
                                    </>
                                ) : (
                                    <>
                                    <Chip label={client1InBusiness.permissions} className='view-chip' variant="outlined"/>
                                    {client1Frozen && " (frozen)"}
                                    </>
                                )}
                            </Box>
                        )}
                        {client2InBusiness && (
                            <Box className='info-item info-name-item'>
                                <Typography variant="h6">
                                    {selectedClient2.name} 
                                </Typography>
                                {client2InBusiness.permissions === 'upload' ? (
                                    <>
                                        <Chip label="View" className='view-chip' variant="outlined"/>
                                        <Chip label="Upload" className='upload-chip' variant="outlined"/>
                                    </>
                                ) : (
                                    <>
                                    <Chip label={client2InBusiness.permissions} className='view-chip' variant="outlined"/>
                                    {client2Frozen && <Typography>(frozen)</Typography>}
                                    </>
                                )}
                            </Box>
                        )}
                    </Grid>
                )
            })}
        </>

    )
}

export default MergeAccountBusinesses;