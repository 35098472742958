import React from 'react';
import Dialog from '@mui/material/Dialog';


import ArchiveDeleteDialogContent from './ArchiveDeleteDialogContent';
import ViewDialogContent from './ViewDialogContent';

const BusinessDialog = ({ dialogType, business, onClose, userToken, updateBusiness, deleteBusiness, id }) => {

    return (

        <Dialog 
            open={dialogType !== null} 
            onClose={onClose}
            fullWidth
			id="admin-business-dialog"
            maxWidth="sm"
            onClick={(e) => e.stopPropagation()}
        >
            {dialogType === 'view' &&
                <ViewDialogContent 
                    business={business}
                    onClose={onClose}
                />
            }
            {
                (dialogType === 'archive' || dialogType === 'delete' || dialogType === 'restore') && (
                    <ArchiveDeleteDialogContent 
                        userToken={userToken}
                        business={business}
                        dialogType={dialogType}
                        onClose={onClose}
                        updateBusiness={updateBusiness}
                        deleteBusiness={deleteBusiness}
                        id={id}
                    />
                )
            }
        </Dialog>
    );
};

export default BusinessDialog;