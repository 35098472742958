import React, {useEffect} from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import ClientProfile from "./ClientProfile";
import ClientQuestionnaires from "./ClientQuestionnaires";
import ClientDocs from "./ClientDocuments";
import ClientBusinessDocuments from "./BusinessDocuments";
import ClientRefunds from "./ClientRefunds";
import ClientPage from './index';



const AdminClientPageRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<ClientPage />}>
                <Route index element={<Navigate replace to="client-page/:id" />} />
                <Route path="client-page/:id" element={<ClientProfile />} />
                <Route path="client-page/history/:id" element={<ClientQuestionnaires />} />
                <Route path="client-page/documents/:id" element={<ClientDocs />} />
                <Route path="client-page/business/documents/:id" element={<ClientBusinessDocuments />} />
                <Route path="client-page/refunds/:id" element={<ClientRefunds />} />
            </Route>
        </Routes>
    );
};
  
export default AdminClientPageRoutes;