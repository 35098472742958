import React, {useState, useEffect} from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ButtonGroup from '@mui/material/ButtonGroup';
import CircularProgress from '@mui/material/CircularProgress';

import FormIcon from '../../assets/icons/free-quote/form.png'
import ThumbIcon from '../../assets/icons/free-quote/thumb.png'
import ThumbIcon1 from '../../assets/icons/free-quote/thumb-1.png'
import ReviewIcon from '../../assets/icons/free-quote/review.png'
import AuditIcon from '../../assets/icons/free-quote/audit.png'

import useScrollToTop from "../../hooks/useScrollToTop"



// import freeQuoteBanner from '../../assets/free-quote-banner.png'
import '../../styles/free-quote.css'

function getTodayDate () {
  	const timeElapsed = Date.now();
  	const today = new Date(timeElapsed);
  	return today.toDateString();
}
const FreeQuote = () => {

	useScrollToTop();


	const [formData, setFormData] = useState({
		date: getTodayDate(),
		userType: 'personal', // default to Personal
		firstName: '',
		lastName: '',
		email: '',
		businessName: '',
		message: ''
	});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');



	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true)
	
		const data = new FormData();
		Object.keys(formData).forEach(key => {
			data.append(key, formData[key]);
		});
	
		try {
			const response = await axios.post('/api/general/estimate', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			if (response.status === 200) {
				setIsSubmitted(true);
				setFormData({
					date: getTodayDate(),
					userType: 'personal', // default to Personal
					firstName: '',
					lastName: '',
					email: '',
					businessName: '',
					message: ''
				});
				setError('')

			} else {
				setError('Could not submit a form. Please Try again later.')
				console.error(result.error);
			}
		} catch (error) {
			setError('Could not submit a form. Please Try again later.')
			console.error('There was an error uploading the file.', error);
		} finally {
			setLoading(false)
		}
	};

  	return (
    	<>
    		<Box id="free-quote-page">
				<Grid container mb={10} mt={3} className="header-container">
					<Grid item>
						<Typography component="h1">
							Get a Free Quote
						</Typography>
					</Grid>
				</Grid>
				<Box>
					<Grid container justifyContent="space-around" className="main-grid">
						{/* Left Column */}
						<Grid item xs={6} container className="left-part">
							<Grid item container xs={12} className="item">
								<Grid item container flexWrap="noWrap" className="item-container item-container-1">
									<Grid item>
										<img src={FormIcon} />
									</Grid>
									<Grid item>
										<Typography component="h3">
											Submit Your Details
										</Typography>
										<Typography component="p">
											Fill out the user-friendly form with your financial details. The more accurate your information, the better we can tailor our quote to your needs.
										</Typography>
									</Grid>
								</Grid>
							</Grid>

							<Grid item container xs={12} className="item item-2">
								<Grid item container flexWrap="noWrap" className="item-container item-container-2">
									<Grid item>
										<img src={ReviewIcon} />
									</Grid>
									<Grid item>
										<Typography component="h3">
											Expert Review
										</Typography>
										<Typography component="p">
											Our certified accountant will review your submission and prepare a personalized quote based on the information you've provided.
										</Typography>
									</Grid>
								</Grid>
							</Grid>

							<Grid item container xs={12} className="item">
								<Grid item container flexWrap="noWrap" className="item-container item-container-3">
									<Grid item>
										<img src={ThumbIcon1} />
									</Grid>
									<Grid item>
										<Typography component="h3">
											Receive & Decide
										</Typography>
										<Typography component="p">
											You'll receive your free quote in your inbox. Review it at your own pace, and when you're ready, reach out to us to get started or ask any questions.
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						{/* Right Column */}
						<Grid item xs={5} className="right-part">
						{error && <Typography className="error-msg">{error}</Typography>}
                            {isSubmitted && <Typography className="success-msg">Form has been successfully submitted. We'll be in touch soon.</Typography>}
							<Box className="free-quote-wrapper" component="form" onSubmit={handleSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<ButtonGroup className='client-type-btn-wrapper'>
											<Button 
												name="userType"
												value="personal" 
												onClick={handleInputChange}
												className={formData.userType === 'personal' ? 'active' : ''}
											>
													Personal
											</Button>
											<Button 
												name="userType"
												value="business" 
												onClick={handleInputChange}
												className={formData.userType  === 'business' ? 'active' : ''}
											>
												Business
											</Button>
                        				</ButtonGroup>

									</Grid>
									<Grid item xs={12}>
										<TextField
											name="firstName"
											required
											size="small"
											label="First Name"
											fullWidth
											value={formData.firstName}
											onChange={handleInputChange}
										/>

									</Grid>
									<Grid item xs={12}>
										<TextField
											name="lastName"
											required
											size="small"
											label="Last Name"
											fullWidth
											value={formData.lastName}
											onChange={handleInputChange}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											name="email"
											required
											size="small"
											label="Email"
											fullWidth
											value={formData.email}
											onChange={handleInputChange}
										/>
									</Grid>
									{formData.userType  === 'business' && (
										<Grid item xs={12} >
											<TextField
												name="businessName"
												size="small"
												label="Business Name"
												required
												fullWidth
												value={formData.businessName}
												onChange={handleInputChange}
											/>
										</Grid>
									)}
									<Grid item xs={12} >
										<TextField
											name="message"
											size="small"
											multiline
											rows="4"
											label="Message"
											fullWidth
											value={formData.message}
											onChange={handleInputChange}
										/>
									</Grid>
									<Grid item xs={12} container justifyContent="flex-end">
                                        <Grid item>
                                            <Button
                                                className="free-quote-submit-btn"
                                                disabled={loading}
                                                type="submit"
                                            >
                                                 {loading ? (
                                                    <>
                                                        Submit&nbsp;
                                                        <CircularProgress size={18} color="inherit" />
                                                    </>
                                                ) : (
                                                    'Submit'
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>

								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
    		</Box>
   		</>
  	)
}

export default FreeQuote;