import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { capitalize } from '@mui/material';

import FolderIcon from '../../../../assets/icons/folder-3.png'

import AddNewFiles from './AddNewFiles';


const Folders = ({ 
    data, 
    setSelectedFolder, 
    folders, 
    businessId="", 
    businessName="", 
    setChildChanges, 
    userType, 
    isDocumentUploadActive }) => {

    const [open, setOpen] = useState(false);
    const [isButtonVisible, setButtonVisible] = useState(true);

    const handleAddDocs = () => {
        setOpen(true)
    }

    useEffect(() => {
            if(isDocumentUploadActive === 2) {
                setButtonVisible(false)
            } else {
                setButtonVisible(true)
            }
    }, [setButtonVisible])


    return (
        <>
            <Grid container id='folders-header' alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography component="h1">{ businessName? `${businessName} Documents` : 'Personal Documents' }</Typography>
                </Grid>
                {businessName && isDocumentUploadActive === 2 && (
                    <Grid item className='inactive-account-msg'>
                        <Typography component="span">
                            Inactive business
                        </Typography>
                        <Tooltip title="Access is currently restricted for this user in this business. As a result, you are unable to upload documents here at this time.">
                            <HelpOutlineIcon />
                        </Tooltip>
                    </Grid>
                )}
                {userType === "1" && isDocumentUploadActive === 2 && (
                    <Grid item className='inactive-account-msg'>
                        <Typography component="span">
                            Inactive account
                        </Typography>
                        <Tooltip title="Documents cannot be uploaded here because the client currently has an active merged account.">
                            <HelpOutlineIcon />
                        </Tooltip>
                    </Grid>
                )}
                 {userType === "2" && isDocumentUploadActive === 2 && (
                    <Grid item className='inactive-account-msg'>
                        <Typography component="span">
                            Inactive account
                        </Typography>
                        <Tooltip title="Documents cannot be uploaded here because the merged account is not active anymore.">
                            <HelpOutlineIcon />
                        </Tooltip>
                    </Grid>
                )}
                {isButtonVisible && (
                    <Grid item xs={2}>
                        <Button onClick={handleAddDocs}>
                            Upload
                        </Button>
                    </Grid>
                )}
                
            </Grid>
            <Box id="admin-folders-container">
                <Grid container justifyContent="center" spacing={3} style={{ justifyContent: 'flex-start' }}>
                    {data.map((item, index) => (
                        <Grid container justifyContent="center" item key={index} xs={12} sm={6} md={4} lg={3}>
                            <Grid item>
                                <Paper className="box" onClick={() => setSelectedFolder(item)}>
                                    <img src={FolderIcon} alt={item.name} />
                                    <Typography variant="subtitle1" align="center">
                                        {capitalize(item.name)} ({item.files.length})
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <AddNewFiles 
                businessId={businessId} 
                businessName={businessName}
                open={open} 
                setOpen={setOpen} 
                folders={folders}
                setSelectedFolder={setSelectedFolder}
                setChildChanges={setChildChanges}
                userType={userType}
            />
        </>
    )
}

export default Folders;