import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { userLoginPass } from '../../actions/authActions';
import Error from '../screens/Error';

import ResetPassImg from '../../assets/auth/reset-pass.png';
import SuccessReset from '../../assets/auth/password-changed.png'

import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

import './auth.css'

const ResetPassword = () => {

  	const { token } = useParams()

	const [state, setState] = useState({
		password: '',
		password2: ''
	})
	const [showPasswords, setShowPasswords] = useState({
		password: false,
		password2: false,
	});
  	const [error, setError] = useState(null);
	const [touchedPassword, setTouchedPassword] = useState(false);
	const [touchedPassword2, setTouchedPassword2] = useState(false);
	const [passwordValid, setPasswordValid] = useState(false);
	const [password2Valid, setPassword2Valid] = useState(false);
	const [isPasswordReset, setIsPasswordReset] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isSubmitBtnEnabled, setSubmitBtnEnabled] = useState(true);
	const [resendLink, setResendLink] = useState(false);

	const handleClickShowPassword = (field) => () => {
        setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
    };

	const handlePasswordValidation = (password) => {
        // regex for password validation: 8-30 chars, 1 num, 1 lowercase, 1 uppercase, 1 special char
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,30}$/;
        return pattern.test(password);
    };

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post(`/api/auth/reset/${token}`, state);
			if (response.status === 200) {
				setLoading(false);
				setState({
					password: '',
					password2: ''
				});
				setError(false);
				setIsPasswordReset(true);
			} else {
				setLoading(false);
				setError('Something went wrong. Please, try again later.');
				setIsPasswordReset(false);
			}
		}catch(e) {
			console.log(e)
			setLoading(false);
			setIsPasswordReset(false);
			if(e.response && e.response.data && e.response.data.error) {
				setError(e.response.data.error);
				if (e.response.data.error === "Link is expired") {
					setResendLink(true); 
				} else {
					setResendLink(false); 
				}
			} else {
				setError('Something went wrong. Please, try again later')
			}
		}
	}

	const handleChange = (e) => {
		let { name, value } = e.target;
		
		if (name === "password") {
			setTouchedPassword(true);
            setPasswordValid(handlePasswordValidation(value));
        }

        if (name === "password2") {
			setTouchedPassword2(true);
            setPassword2Valid(value === state.password);
        }

		setState({
			...state,
			[name]: value
		});
	};

	useEffect(() => {
		if(loading) {
			setSubmitBtnEnabled(false)
		} else if(!state.password || !state.password2) {
			setSubmitBtnEnabled(false)
		} else if (touchedPassword && !passwordValid) {
			setSubmitBtnEnabled(false)
		} else if (touchedPassword2 && !password2Valid) {
			setSubmitBtnEnabled(false)
		} else {
			setSubmitBtnEnabled(true)
		}
	}, [loading, state, touchedPassword, touchedPassword2, passwordValid, password2Valid])


  	return (
    	<>
			<Box id='reset-pass2'>
				<Grid container className="grid-container" justifyContent="space-around">
					<Grid item className="left-container">
						<img src={ResetPassImg} />
					</Grid>
					<Grid item container className="right-container">
						<Box className='reset-pass-wrapper'>
							{!isPasswordReset ? (
								<>
									<Grid container className='reset-pass-header'>
										<Grid item xs={12}>
											<Typography component="h1">Reset Password</Typography>
										</Grid>
										<Grid item xs={12}>
											<Typography component="p">Password must be 8-30 chars with 1 uppercase, 1 lowercase, 1 number, and 1 special character.</Typography>
										</Grid>
									</Grid>
									<Box component="form" role="form" onSubmit={handleSubmit} className='reset-form'>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<TextField
													placeholder="New password"
													variant="outlined"
													name="password"
													fullWidth
													autoComplete="new-password"
													value={state.password}
													onChange={handleChange}
													type={showPasswords.password ? 'text' : 'password'}
													InputProps={{
														endAdornment:(
															<InputAdornment position="end">
																<IconButton
																	name="1"
																	edge="end"
																	onClick={handleClickShowPassword('password')}
																>
																	{showPasswords.password ? <VisibilityOffIcon /> : <VisibilityIcon /> }
					
																</IconButton>
															</InputAdornment>
														)
													}} 
													error={touchedPassword && !passwordValid}
													helperText={
														touchedPassword && !passwordValid &&
														"Invalid password"
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													placeholder="Confirm password"
													variant="outlined"
													name="password2"
													fullWidth
													autoComplete="new-password"
													value={state.password2}
													onChange={handleChange}
													type={showPasswords.password2 ? 'text' : 'password'} 
													InputProps={{
														endAdornment:(
															<InputAdornment position="end">
																<IconButton
																	edge="end"
																	onClick={handleClickShowPassword('password2')}
																>
																	{showPasswords.password2 ? <VisibilityOffIcon /> : <VisibilityIcon /> }
					
																</IconButton>
															</InputAdornment>
														)
													}}
													error={touchedPassword2 && !password2Valid}
													helperText={
														touchedPassword2 && !password2Valid && "Passwords don't match."
													} 
												/>
											</Grid>
											<Grid item xs={12} container justifyContent="flex-end">
												<Grid item>
													<Button
														type="submit"
														fullWidth
														variant="contained"
														className="reset-button"
														sx={{ mt: 1, mb: 2 }}
														disabled={!isSubmitBtnEnabled}
													>
														{loading ? (
															<>
																Submit&nbsp;
																<CircularProgress size={18} color="inherit" />
															</>
														) : (
															'Submit'
														)}
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Box>
									{error && (
										<Grid container className='reset-error-container' justifyContent="center">
											<Grid item xs={8}>
												<ErrorOutlineIcon />
												<Box>
													<Typography component="p">{error}</Typography>
													{resendLink && (
														<Link to="/reset">Resend</Link>
													)}
												</Box>
											</Grid>
										</Grid>
									)}
								</>
							) : (
								<Grid container className='reset-pass-success-box' spacing={1} justifyContent="center">
									<Grid item className='img-container'>
										<img src={SuccessReset} />
									</Grid>
									<Grid item xs={12}>
										<Typography component="h3">Password Changed</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography component="p">Your password has been changed successfully.</Typography>
									</Grid>
									<Grid item xs={12}>
										<Link to="/login">Back to Login</Link>
									</Grid>
								</Grid>
							)}
						</Box>
					</Grid>
				</Grid>
			</Box>
      		{/* <Container>
      			<Grid className="auth-container" container justifyContent="center" mt={8}>
        			<Grid item>
          				<Card className="auth-card">
							<Box
								className="card-header"
							>
								<Typography variant="h4">
									Reset Password
								</Typography>
							</Box>
            				<Box pt={4} pb={3} px={3}>
								{!isPasswordReset ? (
									<>
										{error && (
											<>
											<Typography>
												{error}
											</Typography>
											<Typography pb={2}>
											<Link to='/reset'>Resend</Link>
											</Typography>
											
											</>
										)}
										<Box component="form" role="form" onSubmit={handleSubmit}>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextField
														label="New password"
														variant="standard"
														name="password"
														fullWidth
														autoComplete="new-password"
														value={state.password}
														onChange={handleChange}
														type={showPasswords.password ? 'text' : 'password'}
														InputProps={{
															endAdornment:(
																<InputAdornment position="end">
																	<IconButton
																		name="1"
																		edge="end"
																		onClick={handleClickShowPassword('password')}
																	>
																		{showPasswords.password ? <VisibilityOffIcon /> : <VisibilityIcon /> }
						
																	</IconButton>
																</InputAdornment>
															)
														}} 
														error={touchedPassword && !passwordValid}
														helperText={
															touchedPassword && !passwordValid &&
															"Password must be 8-30 chars with 1 uppercase, 1 lowercase, 1 number, and 1 special character."
														}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														label="Confirm password"
														variant="standard"
														name="password2"
														fullWidth
														autoComplete="new-password"
														value={state.password2}
														onChange={handleChange}
														type={showPasswords.password2 ? 'text' : 'password'} 
														InputProps={{
															endAdornment:(
																<InputAdornment position="end">
																	<IconButton
																		edge="end"
																		onClick={handleClickShowPassword('password2')}
																	>
																		{showPasswords.password2 ? <VisibilityOffIcon /> : <VisibilityIcon /> }
						
																	</IconButton>
																</InputAdornment>
															)
														}}
														error={touchedPassword2 && !password2Valid}
														helperText={
															touchedPassword2 && !password2Valid && "Passwords don't match."
														} 
													/>
												</Grid>
											</Grid>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												sx={{ mt: 3, mb: 2 }}
												className="auth-button"
												disabled={!password2Valid}
											>
												Submit
											</Button>
										</Box>
									</>
								) : (
									<Box>
										<Typography>Password reset successfully.</Typography>
										<Typography>
											Please, proceed to <Link to='/login'>Login</Link>
										</Typography>
									</Box>
								)}
            				</Box>
          				</Card>
        			</Grid>
      			</Grid>
    		</Container> */}
    	</>
  	)
}

export default ResetPassword;