import { createTheme } from "@mui/material/styles";

const arcBlue = "#000";
const arcOrange = "#FFBA60";
const arcGrey = "#868686";

export default createTheme({
  palette: {
    common: {
      blue: arcBlue,
      orange: arcOrange
    },
    primary: {
      main: arcBlue
    },
    secondary: {
      main: arcOrange
    }
  },
  typography: {
    tab: {
      // fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      color: "white",
      fontSize: "1rem"
    },
    estimate: {
      fontFamily: "Poppins",
      fontSize: "1rem",
      textTransform: "none",
      color: "white"
    },
    h1: {
      // fontFamily: "Raleway",
      fontWeight: 700,
      fontSize: "3.5rem",
      color: arcBlue,
      lineHeight: 1.5
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "2.5rem",
      color: arcBlue,
      lineHeight: 1.5
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: "2.5rem",
      color: arcBlue
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: "1.75rem",
      color: arcBlue,
      fontWeight: 700
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Poppins",
      color: arcBlue
    },
    subtitle1: {
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: 400,
      color: arcGrey
    },
    subtitle2: {
      color: "white",
      fontWeight: 400,
      fontSize: "1.3rem"
    },
    body1: {
      fontSize: "1.25rem",
      color: arcGrey,
      fontWeight: 300
    },
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      color: arcGrey
    },
    learnButton: {
      borderColor: arcBlue,
      borderWidth: 2,
      textTransform: "none",
      color: arcBlue,
      borderRadius: 50,
      // fontFamily: "Roboto",
      fontWeight: "bold"
    }
  },
  overrides: {
    MuiInputLabel: {
      // root: {
      //   color: arcBlue,
      //   fontSize: "1rem"
      // }
    },
    MuiInput: {
      root: {
        color: arcGrey,
        fontWeight: 300
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${arcBlue}`
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${arcBlue}`
        }
      }
    }
  }
});