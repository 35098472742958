import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControlLabel, Typography, TextField, Grid } from '@mui/material';

function PersonalQuestions({ onUpdate, personalErrors }) {
    const [isMarried, setIsMarried] = useState(''); 
    const [fileSeparateReturns, setFileSeparateReturns] = useState('');
    const [spouseAccount, setSpouseAccount] = useState('');
    const [spouseEmail, setSpouseEmail] = useState('');

    // This function will notify the parent component whenever there's a change
    const notifyUpdate = () => {
        if(onUpdate) {
            onUpdate({
                isMarried,
                fileSeparateReturns,
                spouseAccount,
                spouseEmail
            });
        }
    }

    useEffect(() => {
        if(personalErrors) {
            console.log(personalErrors)
        }
    }, [personalErrors])

    useEffect(() => {
        notifyUpdate();
    }, [isMarried, fileSeparateReturns, spouseAccount, spouseEmail]);

    return (
        <>
            <Grid item xs={12} mb={1} className="form-subtitle">
                <Typography component="h3">Additional Details</Typography>
            </Grid>
            {/* Are you married? */}
            <Grid item xs={12} className='additional-input' mb={2}>
                <Typography>Are you married?</Typography>
                <RadioGroup row value={isMarried} onChange={e => setIsMarried(e.target.value)}>
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                </RadioGroup>
                {personalErrors.isMarried && <Typography className='personal-error' color="error">{personalErrors.isMarried}</Typography>}
            </Grid>

            {isMarried === "yes" && (
                <>
                    <Grid item xs={12} className='additional-input' mb={2}>
                        <Typography>Do you and your spouse want to file separate returns?</Typography>
                        <RadioGroup row value={fileSeparateReturns} onChange={e => setFileSeparateReturns(e.target.value)}>
                            <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                        </RadioGroup>
                        {personalErrors.fileSeparateReturns && <Typography className='personal-error' color="error">{personalErrors.fileSeparateReturns}</Typography>}
                    </Grid>
                    {fileSeparateReturns === "no" && (
                        <>
                            <Grid item xs={12} className='additional-input' mb={2}>
                                <Typography>Does your spouse have an account with TaxBox or will your spouse create an account with TaxBox?</Typography>
                                <RadioGroup row value={spouseAccount} onChange={e => setSpouseAccount(e.target.value)}>
                                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                                {personalErrors.spouseAccount && <Typography className='personal-error' color="error">{personalErrors.spouseAccount}</Typography>}
                            </Grid>
                            {spouseAccount === "yes" && (
                                <>
                                    <Grid item xs={12} className='additional-input'>
                                        <Typography>Please provide your spouse's email to help us merge the accounts.</Typography>
                                        <TextField 
                                            label="Spouse's Email" 
                                            name="spouseEmail"
                                            size='small'
                                            fullWidth 
                                            required 
                                            value={spouseEmail}
                                            onChange={e => setSpouseEmail(e.target.value)} 
                                            error={!!personalErrors.spouseEmail}
                                            helperText={personalErrors.spouseEmail}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className='note-msg'>
                                        <Typography component="p">
                                        Thank you for answering the questions. We've noted that your account will be merged with your spouse's account. This process typically takes 24 to 48 hours.
                                        </Typography>
                                        <Typography component="p">
                                        Good News! While we're merging the accounts, you can still access your individual profile. If you'd like to add a business or make any updates, you're welcome to do so.
                                        </Typography>
                                        <Typography component="p">
                                        Once the merging is complete, we'll send you an email notification. Please keep an eye on your inbox.
                                        </Typography>

                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default PersonalQuestions;