import React from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import TrashBin from '../../../assets/icons/dustbin.png'

import { useDeleteData } from '../../../hooks/useDeleteData';


const DeleteModal = ({open, currentQuestion, userToken, setDeleteOpen, setChildChanges}) => {

    const [handleDelete, deleted, setDeleted, deleteError, deleteLoading] = useDeleteData(`/api/admin/questionnaire/${currentQuestion?._id}`, userToken);

    const handleClose = () => {
        setDeleteOpen(false);
        setDeleted(false);
        setChildChanges((prevChanges) => !prevChanges);
    }


    return (
        <>
            <Dialog
                aria-labelledby="delete-question-modal"
        	    aria-describedby="delete-new-question-modal"
        	    open={open}
			    // onClose={handleClose}
                fullWidth
				id="delete-question-modal"
                maxWidth="sm"
            >
                <Container className="delete-question-container" sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                    <Grid container justifyContent="flex-end">
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
                    {deleted ? (
                        <Grid container justifyContent="center" mb={3}>
                            <Grid item>
                                <Typography variant='subtitle1'>Question was deleted successfully.</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Grid container justifyContent="center" mb={3}>
                                <Grid item xs={12} justifyContent="center" container>
                                    <Grid>
                                        <img src={TrashBin} alt="trash bin icon" />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant='subtitle1'>Are you sure you want to delete this question?</Typography>
                                    {deleteError && <Typography color="secondary" variant='body1'>{deleteError}</Typography>}
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" mb={3}>
                                <Grid item>
                                    <Typography variant='body1'>"{currentQuestion?.text}"</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" mb={3} spacing={2}>
                                <Grid item>
                                    <Button 
                                        variant='outlined' 
                                        color='primary' 
                                        className='cancel-button' 
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button 
                                        type="button" 
                                        variant='contained' 
                                        className='delete-button'
                                        disabled={deleteLoading}
                                        onClick={handleDelete}
                                    >
                                        {deleteLoading ? (
                                            <>
                                            Deleting&nbsp;
                                            <CircularProgress size={24} color="inherit" />
                                            </>
                                        ) : (
                                            'Delete'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Container>
            </Dialog>
        </>
    )
}

export default DeleteModal;