import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ErrorAlert from '../ui/ErrorAlert';
import SuccessAlert from '../ui/SuccessAlert';

import MailImage from '../../assets/cat-mail.svg'
import SendEmailImage from '../../assets/send-email.svg'


const Invite = () => {
	const { userInfo, userToken } = useSelector((state) => state.user)
  	const [emails, setEmails] = useState([
		{ email: '', type: 0 }
	]);
  	const [error, setError] = useState(null);
  	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userType, setUserType] = useState('user');
	const [invalidEmails, setInvalidEmails] = useState([]);

	useEffect(() => {
		if(userInfo) {
			console.log(userInfo)
			setUserType(userInfo.role)
		}
	}, [userInfo])

  	const addEmailField = () => {
    	setEmails([...emails, { email: '', type: 0 }]);
  	};

  	const removeEmailField = (index) => {
    	setEmails(emails.filter((email, i) => i !== index));
  	};

	const isValidEmail = (email) => {
		const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return pattern.test(email);
	};

  	const handleEmailChange = (event, index) => {
		const newEmails = [...emails];
    	newEmails[index].email = event.target.value;

		// Check email validity
		if (!isValidEmail(event.target.value)) {
			setInvalidEmails(prevInvalid => {
				if (!prevInvalid.includes(index)) {
					return [...prevInvalid, index];
				}
				return prevInvalid;
			});
		} else {
			setInvalidEmails(prevInvalid => prevInvalid.filter(i => i !== index));
		}

    	setEmails(newEmails);
  	};

	const handleTypeChange = (event, index) => {
		const newEmails = [...emails];
		newEmails[index].type = event.target.checked ? 1 : 0;
		setEmails(newEmails);
	};

  	const handleSubmit = async (e) => {
		e.preventDefault();

		if(invalidEmails.length > 0) {
			setError('You provided invalid email format');
			return;
		}

		setError(null);
		setLoading(true);
	
		const config = {
			headers: {
				Authorization: `Bearer ${userToken}`,
			},
		};

		const data = {
			emails: emails,
			userType: userType
		};

		// const endpoint = userType === 'admin' ? '/api/general/invite-admin' : '/api/general/invite-user';
		try {
			const response = await axios.post('/api/general/invite', data, config);

			if (response.status === 200) {
				setSuccess(true);
				setEmails([{ email: '', type: 0 }]);
			} else {
				setError('Something went wrong. Please, try again later.')
			}
		} catch (error) {
			console.log(error);
			if (error.response && error.response.data && error.response.data.data && error.response.data.data.errors) {
				const errorArray = error.response.data.data.errors.map((err) => `${err.email}: Already exists`);
				setError(errorArray);
			} else {
				setError('Error');
			}
		} finally {
			setLoading(false);
		}
  	};

  	return (
    	<>
      		<Box className="invitation-container">
        		<Grid container direction="column" mb={5}>
					<Grid item>
						<Typography variant="h1">Send Invitation</Typography>
					</Grid>
				</ Grid>
				<Grid container justifyContent="space-between">
					<Grid item xs={6} className="invitation-form-container">
						{userType === 'user' && (
							<img src={SendEmailImage} />
						)}
						<Box component="form" onSubmit={handleSubmit} mt={4}>
							{emails.map((email, index) => (
								<Grid container alignItems="center" key={index} mb={3}>
									<Grid item xs={9}>
										<TextField
										label={`Email ${index + 1}`}
										fullWidth
										required
										value={email.email}
										size="small"
										onChange={(event) => handleEmailChange(event, index)}
										error={invalidEmails.includes(index)} 
										helperText={invalidEmails.includes(index) ? "Invalid email format" : ""}
										/>
									</Grid>
									{/* {userType === 'admin' && (
										<Grid item xs={1}>
											<Checkbox 
												icon={<PeopleAltOutlinedIcon />} 
												checkedIcon={<PeopleAltIcon />} 
												checked={email.type === 1}
												onChange={(event) => handleTypeChange(event, index)}
											/>
										</Grid>
									)} */}
									{index !== 0 && (
										<Grid item xs={1}>
											<IconButton onClick={() => removeEmailField(index)}>
												<HighlightOffIcon color="error" />
											</IconButton>
										</Grid>
									)}
									{index === emails.length - 1 && (
										<Grid item xs={1}>
											<IconButton onClick={addEmailField}>
												<AddCircleOutlineIcon color="success" />
											</IconButton>
										</Grid>
									)}
								</Grid>
							))}
							<Grid container>
								<Grid item xs={10}>
									<Button 
										type="submit" 
										className='send-invite-btn'
										disabled={loading || invalidEmails.length > 0}
									>
										{loading ? (
											<>
												Send&nbsp;
												<CircularProgress size={18} color="inherit" />
											</>
										) : (
											'Send'
										)}
									</Button>
								</Grid>
							</Grid>
						</Box>

					</Grid>
					<Grid item xs={6} className="image-container">
						{userType === 'admin' && (
							<img src={SendEmailImage} />
						)}
						{userType === 'user' && (
							
						
						<Box className='discount-info'>
							<Grid container gap={2}>
								<Grid item xs={12}>
									<Typography component="p">
										A client gets a 5% discount on their total invoice for each person that they invite AND that that person files their return with us.
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography component="p">
										If someone was invited by an existing client and they file their return with us they will also get a 5% discount for being referred to us.
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography component="p">
										The 5% discount stacks up to 25% per year. For example, if you invite 8 people and they all file their returns with us then you are eligible for a total of 40% discount. 25% of that discount will be used in the upcoming invoice and the remaining 15% will used in the following year. So, do not worry, your discounts will not be lost and will keep rolling forward until they are all used up.
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography component="p">
										The invitation discounts will be available indefinitely until we are no longer accepting new clients and we will let people know if we are not accepting new clients. However, when we resume accepting new clients then the discount will resume as well.
									</Typography>
								</Grid>
							</Grid>
						</Box>
						)}
					</Grid>
				</Grid>			
      		</Box>
			{error && error.length > 0 && (
				<>
					{error.map((errorMessage, index) => (
						<ErrorAlert key={index} show={true} message={errorMessage} customClass={`alert-${index}`} />
					))}
				</>
			)}			
			{success && <SuccessAlert show={success} message={'Invitations Sent!'} />}
    	</>
  	);
};

export default Invite;