import React, {useEffect, useState} from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

import ContactUsImg from '../../assets/contact-us.png'
import CallIcon from '../../assets/icons/contact-us/telephone.png'
import EmailIcon1 from '../../assets/icons/contact-us/mail.png';
import LocationIcon from '../../assets/icons/contact-us/location-pin.png';

import '../../styles/contact-us.css'

const ContactUs = () => {

    const [state, setState] = useState({
        fullName: '',
        email: '',
        subject: '',
        message: ''
    })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setState((state) => ({
            ...state,
            [name]: value,
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            const response = await axios.post('/api/general/contact', state);
            console.log(response)
            if (response.status === 200) {
                setIsSubmitted(true);
                setError('');
                setState({
                    fullName: '',
                    email: '',
                    subject: '',
                    message: ''
                })
            } else {
                setError('Could not submit a form. Please Try again later.')
            }

        } catch (err) {
            setError('Could not submit a form. Please Try again later.')
            console.log(err);
        } finally {
            setLoading(false)
        }
    }
    


    return(
        <Box id="contact-us-page">
            <Grid container mb={10} mt={3}>
                <Grid item>
                    <Typography component="h1">
                        Contact Us
                    </Typography>
                </Grid>
            </Grid>
            <Box className="contact-form-container">
                <Container>
                    <Grid container justifyContent="space-between" className="form-grid">
                        <Grid item xs={5}>
                            <Typography component="h3">Reach out to us by filling out the form below.</Typography>
                            {error && <Typography className="error-msg">{error}</Typography>}
                            {isSubmitted && <Typography className="success-msg">Thank you! Your message has been successfully submitted. We'll be in touch soon.</Typography>}
                            <Box component="form" onSubmit={handleSubmit}>
                                <Grid container>
                                    <Grid item xs={12} mb={2}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            required
                                            label="Full Name" 
                                            name="fullName"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <PersonIcon />
                                                </InputAdornment>,
                                            }}
                                            onChange={handleChange}
                                            value={state.fullName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            required
                                            label="Email" 
                                            type="email"
                                            name="email"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">
                                                    <EmailIcon />
                                                </InputAdornment>,
                                            }}
                                            onChange={handleChange}
                                            value={state.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            required
                                            label="Subject" 
                                            name="subject"
                                            onChange={handleChange}
                                            value={state.subject}
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={2}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            required
                                            label="Message" 
                                            name="message"
                                            multiline
                                            rows={4}
                                            onChange={handleChange}
                                            value={state.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <Grid item>
                                            <Button
                                                className="contact-submit-btn"
                                                disabled={loading}
                                                type="submit"
                                            >
                                                 {loading ? (
                                                    <>
                                                        Submit&nbsp;
                                                        <CircularProgress size={18} color="inherit" />
                                                    </>
                                                ) : (
                                                    'Submit'
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={ContactUsImg} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className="contact-grid-wrapper">
                <Container>
                    <Grid container justifyContent="space-evenly" className="contact-grid-container" spacing={4}>
                        <Grid item className="item">
                            <Box className="contact-grid-item">
                                <Grid>
                                    <Grid item xs={12}>
                                        <img src={EmailIcon1} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="p">info@taxbox.com</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item className="item">
                            <Box className="contact-grid-item">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={CallIcon} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="p">(234) 567-8989</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item className="item">
                            <Box className="contact-grid-item">
                                <Grid container>
                                    <Grid item xs={12}>
                                        <img src={LocationIcon} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="p">123 Main Street, Anytown, USA</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>

    )
}

export default ContactUs;