import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from '../actions/authActions';
import { Link, Outlet, useNavigate } from "react-router-dom";

import LoadingList from '../components/ui/LoadingList';

const MergedApplicationProtectedRoute = () => {

	const [loading, setLoading] = useState(false);
    const { userInfo } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getUserDetails()).then(() => {
			setLoading(false);
		});
	}, []);

	useEffect(() => {
        const currentYear = new Date().getFullYear();
    
        if (!loading && userInfo && (userInfo.view !== 3 || userInfo.completedYearFromMergedAccount === currentYear)) {
            navigate('/client-accounts');
        }
    }, [userInfo, navigate]);


	if (loading) {
		return <LoadingList type="application" />; 
	}

  	return <Outlet />
}

export default MergedApplicationProtectedRoute;