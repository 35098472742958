import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import { useFetchData } from '../../../hooks/useFetchData';
import {generateUniqueKey } from '../../../utils/generateKey';

const groups = ['All Questions', 'Personal Information', 'Personal Residence', 'Dependents', 'Work & Income Sources', 'Healthcare', 'Education', 
'Deductions and Credits', 'Investments', 'Retirement or Severance', 'Sale of Your Home', 'Gifts', 'Foreign Matters', 'Miscellaneous' ]

import AddNewQuestion from './AddNewQuestion';
import UpdateQuestion from './UpdateQuestion';
import DeleteModal from './DeleteModal';
import ViewQuestion from './ViewQuestion';
import EmptyDataComponent from '../../ui/EmptyDataComponent';

import AllQuestionsView from './AllQuestionsView';
import SpecificGroupQuestionsView from './SpecificGroupQuestionsView';

const QuestionnaireList = () => {

	const { userToken } = useSelector((state) => state.user);
	const [childChanges, setChildChanges] = useState(false);
	const [loading, data, error] = useFetchData('/api/general/questionnaire', userToken, childChanges);
  	const [questionGroup, setQuestionGroup] = useState('All Questions')
  	const [currentQuestion, setCurrentQuestion] = useState(null);
	
  	const [currentIndex, setCurrentIndex] = useState(-1);
  	const [isNewOpen, setNewOpen] = useState(false);
	const [isEditOpen, setEditOpen] = useState(false);
	const [isDeleteOpen, setDeleteOpen] = useState(false);
	const [isViewOpen, setViewOpen] = useState(false);
	
  	const handleNewOpen = () => {
    	setNewOpen(true)
 	}

	const handleEditOpen = (question, index) => {
		setCurrentQuestion(question);
    	setCurrentIndex(index);
    	setEditOpen(true)
 	}

	const handleDeleteOpen = (question, index) => {
		setCurrentQuestion(question);
    	setCurrentIndex(index);
    	setDeleteOpen(true)
 	}

	 const handleViewOpen = () => {
    	setViewOpen(true)
 	}

  	const handleGroup = (e) => {
    	setQuestionGroup(e.target.value)
  	}

  	const refreshList = () => {
    	setCurrentQuestion(null);
    	setCurrentIndex(-1);
  	}
 

  	return (
    	<>
    		<Box className='questionnaire-list-container'>
				<Grid container justifyContent="space-between" alignItems="center" mb={5}>
					<Grid item>
						<Typography component='h1'>
            				Questionnaire
          				</Typography>
					</Grid>
					<Grid item container xs={4} justifyContent="flex-end" gap={2}>
						<Grid item>
							<Button onClick={handleNewOpen} className='add-que-btn'>Add New</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid container mb={3} justifyContent="start">
					<Grid item xs={6}>
						<TextField
            				select
            				label="Select group"
            				defaultValue={questionGroup}
            				name="group"
            				fullWidth
            				onChange={handleGroup}
							size="small"
          				>
            				{groups.map((option, index) => (
              					<MenuItem key={generateUniqueKey()} value={option}>
                					{option}
              					</MenuItem>
            				))}
          				</TextField>
					</Grid>
				</Grid>
				<Grid container justifyContent="start">
					<Grid item xs={11}>
						{data && data.length ? (
            				questionGroup === 'All Questions' ? (
								<AllQuestionsView 
									data={data} 
									handleEditOpen={handleEditOpen} 
									handleDeleteOpen={handleDeleteOpen} 
								/>
            				) : (
								<SpecificGroupQuestionsView 
									data={data} 
									questionGroup={questionGroup} 
									handleEditOpen={handleEditOpen} 
									handleDeleteOpen={handleDeleteOpen} 
								/>
            				)
        				) : (
							<EmptyDataComponent 
								msg="No questions have been added yet. Start by adding some questions to create a questionnaire."
							/>
        				)}
					</Grid>
				</Grid>
    		</Box>
    		<AddNewQuestion 
				open={isNewOpen} 
				setNewOpen={setNewOpen} 
				userToken={userToken} 
				refreshList={refreshList}
				setChildChanges={setChildChanges}
			/>
			<UpdateQuestion 
				open={isEditOpen} 
				setEditOpen={setEditOpen} 
				currentQuestion={currentQuestion} 
				userToken={userToken} 
				setChildChanges={setChildChanges}
			/>
			<DeleteModal 
				open={isDeleteOpen}
				setDeleteOpen={setDeleteOpen}
				currentQuestion={currentQuestion}
				userToken={userToken}
				refreshList={refreshList}
				setChildChanges={setChildChanges}
			/>
			<ViewQuestion 
				open={isViewOpen}
				setViewOpen={setViewOpen}
				data={data}
				groups={groups}
			/>
    	</>
  	)
}

export default QuestionnaireList;

