import React, { useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Outlet, useLocation } from "react-router-dom";
import { styled  } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import HistoryIcon from '@mui/icons-material/History';
import PaidIcon from '@mui/icons-material/Paid';
import MailIcon from '@mui/icons-material/Mail';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


import MergedClientOpenedMenu from "./MergedClientDrawerMenu/MergedOpenedMenu";
import MergedClientClosedMenu from "./MergedClientDrawerMenu/MergedClosedMenu";


const drawerWidth = 240;

const clientMenu = [
    {
		menuItem: 'Account',
		menuIcon: <PeopleAltIcon />,
        path: '/dashboard/merge/my-account'
	},
	{
		menuItem: 'History',
		menuIcon: <HistoryIcon />,
        path: '/dashboard/merge/history'
	}, 
	{
		menuItem: 'Documents',
		menuIcon: <FileCopyIcon />,
        path: '/dashboard/merge/documents'
    },
	{
		menuItem: 'Refunds',
		menuIcon: <PaidIcon />,
        path: '/dashboard/merge/refunds'
	},
	// {
	// 	menuItem: 'Invite',
	// 	menuIcon: <MailIcon />
	// }
]

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



const MergedClientDashboard = () => {
 	const [open, setOpen] = useState(true);
    const {id} = useParams();
    const { userInfo, userToken } = useSelector((state) => state.user);
       
     
	const handleDrawer = () => {
		setOpen(!open);
	};

    const location = useLocation();

    const checkIsActive = (path) => {
        // Split the given path into pathname and search parts
        const [pathName, search = ''] = path.split('?');

        const locationPathName = location.pathname.split('/').slice(0, -1).join('/');
    
        // Check if pathname matches
        if (locationPathName !== pathName) {
            return false;
        }
    
        // If there are query parameters in the given path, check them too
        if (search && location.search !== '?' + search) {
            return false;
        }
    
        return true;
    };


  	return (
    	<Box sx={{ display: 'flex' }} id="drawer-container">
      		<Drawer variant="permanent" open={open}>
              {open ? (
                    <MergedClientOpenedMenu
                        handleDrawer={handleDrawer}
                        id={id}
                        userInfo={userInfo}
                        clientMenu={clientMenu}
                        checkIsActive={checkIsActive}
                    />

                ) : (
                    <MergedClientClosedMenu 
                        handleDrawer={handleDrawer}
                        id={id}
                        userInfo={userInfo}
                        clientMenu={clientMenu}
                        checkIsActive={checkIsActive}
                    />
                )}
      		</Drawer>
            <Box className="client-dashboard" component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Outlet />
            </Box>
    	</Box>
  	);
}

export default MergedClientDashboard;