import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import DialogSuccessMsg from '../../ui/DialogSuccessMsg';

const groups = ['Personal Information', 'Personal Residence', 'Dependents', 'Work & Income Sources', 'Healthcare', 'Education', 
'Deductions and Credits', 'Investments', 'Retirement or Severance', 'Sale of Your Home', 'Gifts', 'Foreign Matters', 'Miscellaneous' ]

const AddNewQuestion = ({ open, setNewOpen, userToken, setChildChanges }) => {

  	const [state, setState] = useState({
		text: '',
		group: '',
		note: '',
		type: 'general',
		order: '',
		new: true,
		subQuestion: []
	});
	const [isSubmitted, setSubmitted] = useState(false)
  	const [error, setError] = useState(null); 
	const [loading, setLoading] = useState(false)


	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'order') {
			const numericValue = value.replace(/\D/g, '');
			setState({ ...state, [name]: numericValue });
		} else if (name === 'new') {
			setState({ ...state, [name]: e.target.checked });
		} else {
			setState({ ...state, [name]: value });
		}
	};

	const addSubQuestion = () => {
		setState({
		  ...state,
		  subQuestion: [...state.subQuestion, { subQuestionText: '', subQuestionType: '' }],
		});
	};

	const deleteSubQuestion = (index) => {
		const subQuestions = [...state.subQuestion];
		subQuestions.splice(index, 1);
		setState({ ...state, subQuestion: subQuestions });
	};

	const handleSubQuestionTextChange = (e, index) => {
		const subQuestions = [...state.subQuestion];
		subQuestions[index].subQuestionText = e.target.value;
		setState({ ...state, subQuestion: subQuestions });
	};
	
	const handleSubQuestionTypeChange = (e, index) => {
		const subQuestions = [...state.subQuestion];
		subQuestions[index].subQuestionType = e.target.value;
		setState({ ...state, subQuestion: subQuestions });
	};

	const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                '/api/admin/questionnaire',
                state,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setSubmitted(true);
                setError('');
            }
        } catch (error) {
			if(error.response.data.error) {
				setError(error.response.data.error)
			} else {
				setError('Error occurred while processing your request. Please try again later.')
			}
            console.log("Error")
        } finally {
            setLoading(false);
        }
    };

	const handleClose = () => {
		setNewOpen(false);
		setSubmitted(false);
		setError('');
		setLoading(false);
		setChildChanges((prevChanges) => !prevChanges);
		setState({
			text: '',
			group: '',
			note: '',
			type: 'general',
			order: '',
			new: true,
			subQuestion: []
		})
	}

  	return (
		<>
			<Dialog
				aria-labelledby="add-new-question-modal"
        	    aria-describedby="add-new-question-modal"
				id='add-que-modal'
        	    open={open}
			    onClose={handleClose}
                fullWidth
				maxWidth="md"
			>
				<Container className="new-question-container" sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="add-que-header" mb={3}>
						<Grid item>
							<Chip color="success" label="Add Question" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseRoundedIcon />
							</IconButton>
						</Grid>
					</Grid>
					{!isSubmitted ? (
						<>
							{error && <Typography className='dialog-error-msg' textAlign="center">{error}</Typography>}
						
							<Grid container mb={3}>
								<Grid item xs={12}>
									<Box component="form" onSubmit={handleSubmit}>
										<Grid container spacing={2} justifyContent="center">
											<Grid item xs={10}>
												<TextField
													label="Question"
													multiline
													fullWidth
													required
													name="text"
													size="small"
													value={state.text}
													onChange={handleChange}
													className="custom-textfield"
												/>
											</Grid>
											<Grid item xs={10}>
												<FormLabel>Check as NEW</FormLabel>
												<Switch
													name="new"
													checked={state.new}
													onChange={handleChange}
												/>
											</Grid>
											<Grid item xs={10}>
												<TextField
													select
													label="Group"
													fullWidth
													required
													name="group"
													value={state.group}
													onChange={handleChange}
													size="small"
												>
													{groups.map((option) => (
														<MenuItem value={option} key={option}>
														{option}
														</MenuItem>
													))}
												</TextField>
											</Grid>
											<Grid item xs={10}>
												<FormLabel>Type Of Question</FormLabel>
												<RadioGroup
													name="type"
													value={state.type}
													onChange={handleChange}
													row
													size="small"
												>
													<FormControlLabel
														value="input"
														control={<Radio />}
														label="Textfield"
													/>
													<FormControlLabel
														value="general"
														control={<Radio />}
														label="Yes/No"
													/>
												</RadioGroup>
											</Grid>
											<Grid item xs={10}>
												<TextField
													label="Note for Questionnaire"
													multiline
													fullWidth
													name="note"
													value={state.note}
													onChange={handleChange}
													size="small"
												/>
											</Grid>
											<Grid item xs={10}>
												<TextField
													label="Note For Documents Upload"
													multiline
													fullWidth
													name="noteForDocuments"
													value={state.noteForDocuments}
													onChange={handleChange}
													size="small"
												/>
											</Grid>
											<Grid item xs={10}>
												<TextField
													type="text"
													label="Order #"
													fullWidth
													required
													name="order"
													value={state.order}
													onChange={handleChange}
													size="small"
												/>
											</Grid>
											{state.subQuestion.length === 0 ?(
												<Grid item xs={10}>
													<Button type="button" className='add-sub-que-btn' onClick={addSubQuestion}>Add Sub Que</Button>
												</Grid>
											) : (
												state.subQuestion.map((subQuestion, index) => (
													<Grid item xs={10} key={index} className="sub-que-wrapper" mt={2}>
														<Grid item xs={12} mb={1}>
															<Box className='sub-que-actions'>
																<FormLabel>Sub Question {index+ 1}</FormLabel>
																<Box>
																	<IconButton onClick={() => deleteSubQuestion(index)}>
																		<DeleteIcon color="error" />
																	</IconButton>
																	<IconButton onClick={addSubQuestion}>
																		<AddCircleOutlineIcon color="success" />
																	</IconButton>
																</Box>
															</Box>
															<TextField
																multiline
																fullWidth
																label="Sub Question"
																value={subQuestion.subQuestionText}
																onChange={(e) => handleSubQuestionTextChange(e, index)}
																size="small"
															/>
														</Grid>
														<Grid item xs={12}>
															<FormLabel>Type Of Sub Question</FormLabel>
															<RadioGroup
																aria-label="sub-question"
																value={subQuestion.subQuestionType}
																onChange={(e) => handleSubQuestionTypeChange(e, index)}
																row
																size="small"
															>
																<FormControlLabel
																	value="input"
																	control={<Radio />}
																	label="Textfield"
																/>
																<FormControlLabel
																	value="general"
																	control={<Radio />}
																	label="Yes/No"
																/>
															</RadioGroup>
														</Grid>
													</Grid>
												))
											)}
											
											<Grid item xs={10} container justifyContent="flex-end">
												<Grid item>
													<Button className='add-que-btn' type="submit" disabled={loading}>
														{loading ? (
                                                    		<>
																Submit&nbsp;
																<CircularProgress size={16} color="inherit" />
															</>
                                                		) : (
                                                    		'Submit'
                                                		)}
													</Button>
												</Grid>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</>
					) : (
						<DialogSuccessMsg msg="Success! Question has been saved." />
					)}
					
				</Container>
			</Dialog>
		</>
  )
}

export default AddNewQuestion;