import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useFetchData } from '../../../../hooks/useFetchData';

import GeneralClientFiles from '../../../general-client-components/general-client-documents/general-client-files';
import GeneralClientFolders from '../../../general-client-components/general-client-documents/general-client-folders';

const MergedAccountDocuments = () => {
    
    const {id} = useParams();
    const { userToken, userInfo } = useSelector((state) => state.user);
    const [childChanges, setChildChanges] = useState(false);
    const [loading, data, error] = useFetchData(`/api/c/dashboard/merged-account/personal/files/${id}`, userToken, childChanges);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folders, setFolders] = useState([]);

    useEffect(() => {
        if(data) {
            console.log(data)
        }
    }, [data])
    

    useEffect(() => {
        if (data && data.files) {
          const folderNames = data.files.map((item) => item.name);
          setFolders(folderNames);
        }
    }, [data]);

    return (
        <>
            {loading && <p>Loading...</p>}
            {data && (
                !selectedFolder ? (
                    <GeneralClientFolders 
                        data={data.files} 
                        setSelectedFolder={setSelectedFolder} 
                        userToken={userToken}
                        folders={folders}
                        setChildChanges={setChildChanges}
                        userInfo={userInfo}
                        userType="2"
                        isDocumentUploadActive={data?.userStatus}
                        loading={loading}
                    />
                ) : (
                    <GeneralClientFiles 
                        data={data.data} 
                        selectedFolder={selectedFolder} 
                        setSelectedFolder={setSelectedFolder} 
                        userToken={userToken} 
                    />
                )
            )}
        </>
    )
}

export default MergedAccountDocuments;