import React, {useEffect, useState} from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";


import AboutUsImg from '../../assets/about/about-us.png';
import FormIcon from '../../assets/icons/free-quote/form.png';
import ThumbIcon from '../../assets/icons/free-quote/thumb.png';
import ThumbIcon1 from '../../assets/icons/free-quote/audit.png';
import ReviewIcon from '../../assets/icons/free-quote/review.png';
import AuditIcon from '../../assets/icons/free-quote/audit.png';

import '../../styles/about-us.css';

const statements = [
    "Give a shit",
    "Care about your finances",
    "Respect and value you and ourselves",
    "Are straightforward and honest",
    "Love to simplify the complex"
];

const About = () => {

	const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeIn(false);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % statements.length);
                setFadeIn(true);
            }, 500); // This timeout should match the duration of the fade out transition
        }, 3000); // Change every 3 seconds

        return () => clearInterval(interval);
    }, []);

	return (
		<Box id="about-us-page" mb={10}>
			<Box className="intro-section">
				<Container>
					<Grid container className="grid-section">
						<Grid item className="intro-content">
							<Typography component="h2" align="center">
								We are
							</Typography>
							<Box className="tax-box">
								<Typography component="span" color="primary">Tax</Typography>
								<Typography component="span" color="primary">Box</Typography>
							</Box>
							<Typography component="p">
								Redefining the tax industry, the only way we know how. With quality service, technology, and charm.
							</Typography>
							<Typography>
							We get it, taxes suck, but what sucks more is when your tax professional takes your money for preparing your return and doesn't explain or assist you in any other way. We strongly believe that educating the taxpayer and being proactive with them is the key to helping them understand their finances better and help them succeed!
							</Typography>
							{/* <Button
								component="a"
								href="/services/consulting"
								className="learn-more-btn"
							>
								Learn More
							</Button> */}
						</Grid>
						<Grid item className="img-content">
							<img src={AboutUsImg} />
						</Grid>

					</Grid>
				</Container>
			</Box>
			<Box className="our-mission-section-intro">
				<Grid container className="grid-container-title">
					<Grid item className="content-wrapper-title" xs={12}>
						<Typography variant="h2">Our Mission</Typography>
							<Typography component="p">
								Help our clients grow both professionally and personally
							</Typography>
							<Typography component="p">
								So, what do we bring to the table as an invite/referral-based company?
							</Typography>
					</Grid>
				</Grid>
			</Box>
			<Box className="our-mission-section">
				<Container>
					<Grid container className="grid-container">
						<Grid item className="content-wrapper">
						<img src={AboutUsImg} style={{width: '100%'}} />
							{/* <Typography variant="h2">Our Mission</Typography>
							<Typography component="p">
								Help our clients grow both professionally and personally
							</Typography>
							<Typography component="p">
								So, what do we bring to the table as an invite/referral-based company?
							</Typography> */}
						</Grid>
						<Grid item container className="items-wrapper">
							<Grid item container flexDirection="column" className="first-column">
								<Grid item className="item">
									<img src={ThumbIcon1} />
									<Typography component="h4">Personal Touch</Typography>
									<Typography component="p">Being invite/referral- based lets us have a more hands on approach if needed and just more time for you.</Typography>
								</Grid>
								<Grid item className="item">
									<img src={ReviewIcon} />
									<Typography component="h4">Partnership</Typography>
									<Typography component="p">Everyone should work with someone they trust and we are no exception. We want to know our clients and make sure they won’t ghost us and we won’t ghost them.</Typography>
								</Grid>
							</Grid>
							<Grid item container flexDirection="column" className="second-column">
								<Grid item className="item">
									<img src={ReviewIcon} />
									<Typography component="h4">Expertise</Typography>
									<Typography component="p">We simplify the tax world and help you understand it. After all, isn’t that what experts do?</Typography>
								</Grid>
								<Grid item className="item">
									<img src={ThumbIcon1} />
									<Typography component="h4">Symbiosis</Typography>
									<Typography component="p">When you grow, we grow with you to match your needs and expand our services and tools.</Typography>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</Container>
			</Box>
			<Box className="quote-container">
				<Container>
					<Grid container>
						<Grid item>
							<Typography>
								You may be wondering why we do all of this and why limit ourselves to invite/referral only? Well...
							</Typography>
							<Typography>
								We at TaxBox
							</Typography>
							 <Fade in={fadeIn} timeout={500}>
                <Typography variant="h4">
                    {statements[currentIndex]}
                </Typography>
            </Fade>

						</Grid>
					</Grid>
				</Container>
			</Box>
		</Box>
		
	)
}

export default About;