import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';


import { useFetchData } from '../../../hooks/useFetchData';
import EmptyDataComponent from '../../ui/EmptyDataComponent';



const GeneralClientRefunds = ({ accountType }) => {

	const { id } = useParams();
    const { userToken } = useSelector((state) => state.user);
    const endpoint = id 
    ? `/api/c/dashboard/refunds/${id}`
    : '/api/c/dashboard/refunds';

    const [loading, data, error] = useFetchData(endpoint, userToken);


    return (
        <>
            <Box id="refunds-wrapper">
                <Grid container justifyContent="space-between" alignItems="center" className='refunds-header'>
                    <Grid item>
                        <Typography component="h1" variant='h1'>
                            Refunds
                        </Typography>
                        
                    </Grid>
                </Grid>
                {data && data.length ? (
                    <Grid container spacing={2}>
                        {data.map((item, index) => (
                            <Grid item xs={12} sm={12} md={6} key={item._id}>
                                <Card mb={2} className={`refund-item ${parseFloat(item.amount) < 0 ? 'negative' : 'positive'}`}>
                                    <Grid container sx={{ m: 0, p: 2 }} justifyContent="space-between" alignItems="center">
                                        <Grid item container xs={11}>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1'>
                                                    Jurisdiction:
                                                    <Typography component="span">
                                                        {item.jurisdiction}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1'>
                                                    Year:
                                                    <Typography component="span">
                                                        {item.taxYear}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1'>
                                                    Amount:
                                                    <Typography component="span">
                                                        ${item.amount}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='subtitle1'>
                                                    Link:
                                                    <Link href={item.link} target="_blank">
                                                        <Typography component="span">
                                                            {item.link}
                                                        </Typography>
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <EmptyDataComponent msg="There are currently no refunds information listed for your account." />
                ) }
            </Box>
        </>
    )
}

export default GeneralClientRefunds;