import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';


const LoadingGrid = () => {

    return (
        <Grid container spacing={3} ml={3} mt={3}>
            {Array.from({ length: 3 }).map((_, index) => (
                <Grid item key={index}>
                    <Skeleton variant="rounded" width={300} height={200} />
                </Grid>
            ))}
        </Grid>
    )
}

export default LoadingGrid;