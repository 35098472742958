import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import SuccessAlert from '../../../ui/SuccessAlert';


const BusinessSettings = ({userToken, businesses, businessFormValues, setBusinessFormValues}) => {

    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editModes, setEditModes] = useState({});

    const handleEditMode = (id) => {
        // If the current form is being edited, disable edit mode
        if (editModes[id]) {
            setEditModes({...editModes, [id]: false});
            return;
        }

        // If another form is currently editable, do nothing
        if (Object.values(editModes).includes(true)) {
            return;
        }

        setEditModes({...editModes, [id]: true});
    }

    const handleChange = (id) => (e) => {
        setBusinessFormValues({
            ...businessFormValues,
            [id]: {
                ...businessFormValues[id],
                [e.target.name]: e.target.value
            }
        });
    }


    const handleSubmit = async (e, id) => {
        e.preventDefault();
        if (!editModes[id]) {
            return;
        }
    
        setLoading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${userToken}`,
            }
        }
    
        const updatedBusiness = businessFormValues[id];
    
        try {
            const response =  await axios.post(`/api/client/settings/business/${id}`, updatedBusiness, config);
    
            setEditModes({
                ...editModes,
                [id]: false
            });
    
            setUpdated(true);
            setLoading(false);
        } catch (err) {
            setError(true);
            setLoading(false);
        }
    };

   
    return (
        <>
            <Container>
                <Grid container justifyContent="space-between" mb={5}>
                    <Grid item>
                        <Typography component="h3">
                            Business Information
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {businesses && businesses.length > 0 && Object.keys(businessFormValues).length > 0 ? (
                        businesses.map((business, index) => (
                            <Grid key={index} item xs={6}>
                                <Box component="form" onSubmit={(e) => handleSubmit(e, business._id)} className='settings-business-item'>
                                    <Grid container justifyContent="flex-end" mb={1}>
                                        <Grid item>
                                            <IconButton onClick={() => handleEditMode(business._id)}>
                                                <EditIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} mb={3}>
                                            <TextField 
                                                fullWidth 
                                                required 
                                                size='small'
                                                label="Business Name"
                                                name='businessName'
                                                value={businessFormValues[business._id].businessName}
                                                disabled={!editModes[business._id]}
                                                onChange={handleChange(business._id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mb={3}>
                                            <TextField 
                                                fullWidth 
                                                required 
                                                size='small'
                                                label="Business EIN"
                                                name='ein'
                                                value={businessFormValues[business._id].ein}
                                                disabled
                                                onChange={handleChange(business._id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mb={3}>
                                            <TextField 
                                                fullWidth 
                                                size='small'
                                                label="Doing Business As"
                                                name='dba'
                                                value={businessFormValues[business._id].dba}
                                                disabled={!editModes[business._id]}
                                                onChange={handleChange(business._id)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mb={3}>
                                            <TextField 
                                                fullWidth 
                                                size='small'
                                                label="Formerly Known As"
                                                name='fka'
                                                value={businessFormValues[business._id].fka}
                                                disabled={!editModes[business._id]}
                                                onChange={handleChange(business._id)}
                                            />
                                        </Grid>
                                    </Grid>
                                    {editModes[business._id] && (
                                        <Grid container>
                                            <Grid item xs={12} container justifyContent="flex-end" columnGap={2}>
                                                <Grid item>
                                                    <Button onClick={() => handleEditMode(business._id)} variant="contained" className='cancel-btn'>Cancel</Button>
                                                </Grid>
                                                <Grid>
                                                    <Button variant="contained" className='save-btn' type='submit'>
                                                        {loading ? (
                                                            <>
                                                                Save&nbsp;
                                                                <CircularProgress size={16} color="inherit" />
                                                            </>
                                                        ) : (
                                                            'Save'
                                                        )}
                                                    </Button>
                                                </Grid>  
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                        ))
                    ) : null}
                </Grid>
            </Container>  
            {updated && <SuccessAlert show={updated} message={"Your information was successfully updated!"} />}
        </>
    )
}

export default BusinessSettings;