import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from "@mui/material/Typography";
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PaidIcon from '@mui/icons-material/Paid';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const MobileDrawer = ({ handleDrawer, id, handleDocumentsMenu, isDocumentsOpen, isClientPage, clientDetails }) => {

    const location = useLocation();

    const checkIsActive = (path) => {
        // Split the given path into pathname and search parts
        const [pathName, search = ''] = path.split('?');
    
        // Check if pathname matches
        if (location.pathname !== pathName) {
            return false;
        }
    
        // If there are query parameters in the given path, check them too
        if (search && location.search !== '?' + search) {
            return false;
        }
    
        return true;
    };

    return (
        <>
        <DrawerHeader className="client-profile-drawer-header">
            <IconButton onClick={handleDrawer}>
                <ChevronRightIcon />
            </IconButton>
        </DrawerHeader>
        <Divider />
        <List className="list-closed">
            <ListItem sx={{ display: 'block' }}>
                <Tooltip title="Home" placement="right-start">
                    <ListItemButton 
                        component={Link} 
                        to='/admin-profile'
                        sx={{
                            minHeight: 48,
                            justifyContent: 'center',
                            px: 2.5,
                            pr: "10px",
                            pl: "10px"
                        }}
                        className="list-group-item"
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: '0',
                                justifyContent: 'center',
                            }}
                        >
                            <HomeIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
                <Tooltip title="Client Profile" placement="right-start">
                    <ListItemButton 
                        component={Link} 
                        to={isClientPage ? `/admin/client-page/${id}` : `/admin/merge/client-page/${id}`}
                        sx={{
                            minHeight: 48,
                            justifyContent: 'center',
                            px: 2.5,
                            pr: "10px",
                            pl: "10px"
                        }}
                        className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/${id}` : `/admin/merge/client-page/${id}`) ? 'active' : ''}`}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: '0',
                                justifyContent: 'center',
                            }}
                        >
                            <AccountBoxIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>
            </ListItem>
            <ListItem sx={{ display: 'block' }}>
                <Tooltip title="History" placement="right-start">
                    <ListItemButton 
                        component={Link} 
                        to={isClientPage ? `/admin/client-page/history/${id}` :  `/admin/merge/client-page/history/${id}`}
                        sx={{
                            minHeight: 48,
                            justifyContent: 'center',
                            px: 2.5,
                            pr: "10px",
                            pl: "10px"
                        }}
                        className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/history/${id}` : `/admin/merge/client-page/history/${id}`) ? 'active' : ''}`}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: '0',
                                justifyContent: 'center',
                            }}
                        >
                            <HistoryIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>
            </ListItem>
            <Divider />
            <ListItem sx={{ display: 'block' }}>
                <Tooltip title="Documents" placement="right-start">
                    <ListItemButton 
                        sx={{
                            minHeight: 48,
                            justifyContent: 'center',
                            px: 2.5,
                            pr: "10px",
                            pl: "10px"
                        }}
                        className="list-group-item"
                        onClick={handleDocumentsMenu}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: '0',
                                justifyContent: 'center',
                            }}
                        >
                            <FileCopyIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>
            </ListItem>
            {isDocumentsOpen && (
                <Collapse in={isDocumentsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem sx={{pl: '40px'}}>
                            <Tooltip title="Personal Documents" placement="right-start">
                                <ListItemButton 
                                    component={Link} 
                                    to={isClientPage ? `/admin/client-page/documents/${id}` :  `/admin/merge/client-page/documents/${id}`}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: 'center',
                                        px: 2.5,
                                        pr: "10px",
                                        pl: "10px"
                                    }}
                                    className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/documents/${id}` : `/admin/merge/client-page/documents/${id}`) ? 'active' : ''}`}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: '0',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <PersonIcon />
                                    </ListItemIcon>
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                        {isClientPage && !!clientDetails && clientDetails.businesses.length > 0 && clientDetails.businesses.map((business, index) => (
                            <ListItem sx={{pl: '40px'}}>
                                <Tooltip title={business.businessName + ' Documents'} placement="right-start">
                                    <ListItemButton 
                                        component={Link} 
                                        to={`/admin/client-page/business/documents/${id}?businessId=${business._id}`}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: 'center',
                                            px: 2.5,
                                            pr: "10px",
                                            pl: "10px"
                                        }}
                                        className={`list-group-item ${checkIsActive(`/admin/client-page/business/documents/${id}?businessId=${business._id}`) ? 'active' : ''}`}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: '0',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <WorkIcon />
                                        </ListItemIcon>
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            )}
            <Divider />
            <ListItem sx={{ display: 'block' }}>
                <Tooltip title="Refunds" placement="right-start">
                    <ListItemButton 
                        component={Link} 
                        to={isClientPage ? `/admin/client-page/refunds/${id}` :  `/admin/merge/client-page/refunds/${id}`}
                        sx={{
                            minHeight: 48,
                            justifyContent: 'center',
                            px: 2.5,
                            pr: "10px",
                            pl: "10px"
                        }}
                        className={`list-group-item ${checkIsActive(isClientPage ? `/admin/client-page/refunds/${id}` : `/admin/merge/client-page/refunds/${id}`) ? 'active' : ''}`}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: '0',
                                justifyContent: 'center',
                            }}
                        >
                            <PaidIcon />
                        </ListItemIcon>
                    </ListItemButton>
                </Tooltip>
            </ListItem>
            
        </List>


        </>
    )

}

export default MobileDrawer;