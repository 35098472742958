import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const GeneralPersonalQuestionnaireModal = ({open, setOpen, unansweredQuestions, groups }) => {

	const scrollWindow = () => {
		window.scrollTo(
			{
				top: 0, 
				behavior: "smooth"
			}
		)
	}
	const handleClose = () => {
		setOpen(false);
        scrollWindow();
	}

	return (
		<Dialog
			aria-labelledby="unanswered-questions-modal"
        	aria-describedby="unanswered-questions-modal"
        	open={open}
			onClose={handleClose}
            fullWidth
            maxWidth="md"
            id="unanswered-questions-modal"
		>
			<Container sx={{ m: 0, p: 2 }}>
                <Grid container justifyContent="end">
					<Grid item>
						<IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
					</Grid>
				</Grid>
				<Grid container justifyContent="center">
					<Grid item xs={12}>
						<Typography component="h5" textAlign="center">Please, answer these questions before you submit the form</Typography>
					</Grid>
				</Grid>
				<Grid container>
                    <Grid item xs={12}>
                        {groups.map((group) => {
                            const groupQuestions = unansweredQuestions.filter((item) => item.group.toLowerCase() === group.toLowerCase());
                            if (groupQuestions.length > 0) {
                                return (
                                <Box key={group}>
                                    <Typography className="group-heading" component="h6">
                                        {group}
                                    </Typography>
                                    <Grid container spacing={1}>
                                    {groupQuestions.map((item) => {
                                        return (
                                            <Grid item xs={12} key={item.question} pt={0}>
                                                <Typography component="p">
                                                    {item.question}
                                                </Typography>
                                            </Grid>
                                        )
                                    })}
                                    </Grid>
                                </Box>
                                );
                            } else {
                                return null;
                            }
                        })}              
                    </Grid>
                </Grid>
			</Container>
		</Dialog>
	)
};

export default GeneralPersonalQuestionnaireModal;