export const faq = [
  {
    question: 'Do I need to make an account to get a quote?',
    answer: 'No, simply email us at “info@taxbox.com” so we can get an understanding of what your taxes entail and we will provide a rough estimate. Please note that there is a minimum amount that we charge regardless of the simplicity of the return and that the estimate provided is only an estimate and could either be higher or lower depending on the actual preparation complexities that may be encountered during actual preparation.'
  },
  {
    question: 'How does this work?',
    answer: 'It\'s simple! When you create an account, you will be shown an engagement letter that you will sign so that we can work together. After that you will be prompted to answer a few questions about yourself and your unique tax situation. Based on your answers to those questions we will contact you and let you know what docs you should upload if you don\'t already know. After the docs are uploaded, we will start working on your tax return right away and will contact you if we have any follow-up questions or whether your tax return is complete and ready for your review and signatures.'
  },
  {
    question: 'How long does it take for my tax return to be completed?',
    answer: 'Unfortunately, the answer depends on how complicated your taxes are and how quickly you are able to provide us with all the documents and answer any follow-up questions we may have. However, if you urgently need your taxes done for any reason, we can complete your taxes within 1-2 days assuming all the documentation has been provided and you are quick about answering our follow-up questions, but this will include an urgency fee*. (Urgency fee will be $35).'
  },
  {
    question: 'What is the cancellation policy?',
    answer: 'If you request to cancel the engagement and a draft return has been already provided to you for your viewing you will be billed 50% of our minimum return preparation fee.'
  },
  {
    question: 'How do I know that you are the right CPA for me?',
    answer: 'To be honest, you don\'t. However, feel free to email us and have a chat. If you like our replies and what we can do for you and our interactions with you then feel free to choose us. We never aggressively pursue anyone for their business, hence that\'s why we are an invite-only business. If we really wanted to grow the business extremely fast and not care about whose business we take on, then we would just let anyone sign-up. However, we want to have a personalized touch with our clients and we want to trust our clients because they come recommended to us by our existing clients who already like our services.'
  },
  {
    question: 'If I don\'t know anyone to give me an invite how can I get an invite?',
    answer: 'Chances are pretty low, but feel free to email us at info@taxbox.com and we can chat. This way we can both vet each other and see if we will be a good fit. If everything works out, I will personally send you an invite.'
  },
]