import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';

import TrashBin from '../../../../../assets/icons/dustbin.png';
import DialogSuccessMsg from '../../../../ui/DialogSuccessMsg';

import { useDeleteData } from '../../../../../hooks/useDeleteData';
import { fetchAdminClientDetails } from '../../../../../actions/adminClientActions';

const ArchiveDeleteDialogContent = ({ business, userToken, dialogType, onClose, updateBusiness, deleteBusiness, id }) => {

    const dispatch = useDispatch();
    const identifier = business._id || business.ein;
    const [isArchived, setIsArchived] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [handleDelete, deleted, setDeleted, deleteError, deleteLoading] = useDeleteData(`/api/client-page/business/delete/${identifier}`, userToken);

    useEffect(() => {
        if(deleted) {
            dispatch(fetchAdminClientDetails({ clientId: id, userType: 1 }));
        }
    }, [deleted])
    /*
        * The handleArchiveOrRestore function is responsible for archiving or restoring a business
        * depending on the dialogType. 
        * If the dialogType is 'archive', it sets the business status to 1, indicating that the business is archived.
        * If the dialogType is not 'archive', it sets the business status to 0, indicating that the business is restored.
        * The function sends an HTTP request to the corresponding API endpoint with the new status and updates the business
        * object accordingly. If an error occurs, it sets an error message to the state.
    */
    const handleArchiveOrRestore = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);

        const statusValue = dialogType === 'archive' ? 1 : 0;

        const data = {
            status: statusValue,
        };

        const updatedBusiness = {
            ...business,
            status: statusValue,
        };

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                `/api/client-page/business/archive/${identifier}`,
                data,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setIsArchived(true);
                setError('');
                updateBusiness(updatedBusiness);
            }
        } catch (error) {
            console.log(error)
            setError(error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Error.');
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteBusiness = (e) => {
        e.stopPropagation();
        handleDelete();
        deleteBusiness(business._id);
    }

    const actionName = dialogType === 'archive'
    ? 'archive'
    : dialogType === 'restore'
        ? 'restore'
        : 'delete';

    const actionPrompt = dialogType === 'archive'
        ? `Are you sure you want to archive the business "${business?.businessName.toUpperCase()}"?`
        : dialogType === 'restore'
            ? `Are you sure you want to restore the business "${business?.businessName.toUpperCase()}"?`
            : `Are you sure you want to delete the business "${business?.businessName.toUpperCase()}"?`;

    const actionDescription = dialogType === 'archive'
        ? 'Archiving will hide the business and all associated documents from both clients and admins.'
        : dialogType === 'restore'
            ? 'Restoring will make the business and all associated documents visible to both clients and admins.'
            : 'Deleting will permanently remove the business and all associated documents from both clients and admins.';

    const buttonClass = dialogType === 'archive'
        ? 'archive-button'
        : dialogType === 'restore'
            ? 'restore-button'
            : 'delete-button';

    const successMessage = dialogType === 'archive'
        ? 'Business was archived successfully.'
        : dialogType === 'restore'
            ? 'Business was restored successfully.'
            : 'Business was deleted successfully.';


    const displayError = dialogType === 'archive' || dialogType === 'restore' ? error : deleteError;

    const handleSubmit = dialogType === 'archive' || dialogType === 'restore' ? handleArchiveOrRestore : handleDeleteBusiness;

    return (
        <>
            <Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Chip label="Delete Mode" color='error'/>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {(isArchived || deleted) ? (
                    <DialogSuccessMsg msg={successMessage} />
                ) : (
                    <>
                        <Grid container justifyContent="center" mb={3}>
                            <Grid item xs={12} justifyContent="center" container>
                                <Grid>
                                    <img src={TrashBin} alt="trash bin icon" />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant='subtitle1'>{actionPrompt}</Typography>
                                <Typography variant='subtitle1'>{actionDescription}</Typography>
                                {displayError && <Typography color="secondary" variant='body1'>{displayError}</Typography>}
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" mb={3} spacing={2}>
                            <Grid item>
                                <Button 
                                    variant='outlined' 
                                    color='primary' 
                                    className='cancel-button' 
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    type="button" 
                                    variant='contained' 
                                    className={buttonClass}
                                    disabled={loading || deleteLoading}
                                    onClick={handleSubmit}
                                >
                                    {loading || deleteLoading ? (
                                        <>
                                            {actionName.charAt(0).toUpperCase() + actionName.slice(1)}&nbsp;
                                            <CircularProgress size={24} color="inherit" />
                                        </>
                                    ) : (
                                        actionName.charAt(0).toUpperCase() + actionName.slice(1)
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Container>
        </>
    )
}

export default ArchiveDeleteDialogContent;