import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from '../actions/authActions';
import { Link, Outlet } from "react-router-dom";
import { logout } from '../reducers/authReducers';

import AccessDeniedImg from '../assets/access-denied.png'

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

const AdminProtectedRoute = () => {

	const [loading, setLoading] = useState(false);
	const { userInfo, userToken } = useSelector((state) => state.user)
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUserDetails()).then(() => {
			setLoading(false);
		});
	}, []);

	useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'userToken' && !e.newValue) { 
                dispatch(logout());
            }
        };

        // Attach the event listener
        window.addEventListener('storage', handleStorageChange);

        // Cleanup
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [dispatch]);

	
	if (loading) {
		return (
			<Grid container  justifyContent="space-between">
				<Grid item xs={3}>
					<Skeleton variant="rounded" height={400} />
				</Grid>
				<Grid item xs={8}>
					<Skeleton variant="rounded" height={400}  />
				</Grid>
			</Grid>
		); 
	}

  	if(!userInfo || !userToken || (userInfo && userInfo?.role !== 'admin')) {
		return (
			<Box id="unauthorized-box">
				<Grid container justifyContent="center">
					<Grid item>
					<img src={AccessDeniedImg} />
					</Grid>
				</Grid>
				<Grid container justifyContent="center" mt={2}>
					<Grid item>
						<Typography component="h1">Access Denied</Typography>
						<Typography component="p">It looks like you don't have the necessary permissions to view this section. Please log in with the appropriate account to continue.</Typography>
						<Link to='/login'>Sign In</Link>
					</Grid>
				</Grid>
			</Box>
		)
  	}
	
  	return <Outlet />
}

export default AdminProtectedRoute;