import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


const ViewDialogContent = ({ business, onClose }) => {

    useEffect(() => {
        if(business) {
            console.log(business)
        }
    }, [business])

    return (
        <Container sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }} id="admin-business-view-dialog">
            <Grid container justifyContent="space-between" alignItems="center" mb={5}>
                <Grid item>
                    <Box display="flex" alignItems="center">
                        <Chip color="success" label="View mode" sx={{ marginRight: 1 }} />
                        {!business.userStatus && (
                            <Chip color="error" label="Not active" />
                        )}
                    </Box>
                </Grid>
                <Grid item>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
            <Grid container spacing={2}>
                {business.frozenAt && (
                    <Grid item xs={12} container alignItems="center">
                        <Grid item xs={5}>
                            <Typography variant="h6" color="textSecondary">
                                Deactivated since:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{business.frozenAt}</Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} container>
                    <Grid item xs={5}>
                        <Typography variant="h6" color="textSecondary">
                            Business Name:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{business.businessName}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={5}>
                        <Typography variant="h6" color="textSecondary">
                            Business EIN:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{business.ein}</Typography>
                    </Grid>
                </Grid>
                {business.dba && (
                    <Grid item xs={12} container>
                        <Grid item xs={5}>
                            <Typography variant="h6" color="textSecondary">
                                DBA:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{business.dba}</Typography>
                        </Grid>
                    </Grid>
                )}
                {business.fka && (
                    <Grid item xs={12} container>
                        <Grid item xs={5}>
                            <Typography variant="h6" color="textSecondary">
                                FKA:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">{business.fka}</Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            {(business.activeUsers.length > 0 || business.frozenUsers.length > 0) && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" color="textSecondary" className='other-users-title'>
                        Other Users
                    </Typography>
                    {business.activeUsers.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ mt: 2 }} className='other-users-subtitle'>
                                Active Users
                            </Typography>
                            <List className='users-list'>
                                {business.activeUsers.map((user, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={user.name} secondary={`Permissions: ${user.permissions}`} />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                    {business.frozenUsers.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ mt: 2 }} className='other-users-subtitle'>
                                Frozen Users
                            </Typography>
                            <List className='users-list'>
                                {business.frozenUsers.map((user, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={user.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                </>
            )}
        </Box>
        </Container>
    )
}

export default ViewDialogContent;