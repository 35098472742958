import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';

import { useFetchData } from '../../../../hooks/useFetchData';

const AddBusinessUser = ({userToken, businessId, setNewUsers, newUsers, activeUsers}) => {

    const [loading, data, error] = useFetchData(`/api/admin/business/clients/${businessId}`, userToken);
    const [clients, setClients] = useState([]);
    const maxAdditionalUsers = 3 - activeUsers.length;
    const [inputCount, setInputCount] = useState(Math.min(1, maxAdditionalUsers));

    useEffect(() => {
        if(data) {
            setClients(data)
        }
    }, [data]);

    const addNewClientInput = () => {
        setInputCount(prevCount => Math.min(prevCount + 1, maxAdditionalUsers));
    };

    const options = clients.length > 0 ? clients.map((option) => {
        const firstLetter = option.firstName[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option
        };
    }) : [];

    const updateNewUsers = (index, newUser) => {
        setNewUsers(prevUsers => {
            const updatedUsers = [...prevUsers];
            updatedUsers[index] = newUser;
            return updatedUsers;
        });
    };

    const handleClientChange = (index, value) => {
        setNewUsers(prevUsers => {
            // Create a shallow copy of the previous users array
            const updatedUsers = [...prevUsers];
    
            if (value) {
                // If a user is selected, update the user at the given index
                const newUser = { 
                    user: value.id, 
                    permissions: 'view', 
                    firstName: value.firstName, 
                    lastName: value.lastName 
                };
                updatedUsers[index] = newUser;
            } else {
                // If a user is unselected, remove the user at the given index
                updatedUsers.splice(index, 1);
            }
    
            return updatedUsers;
        });
    };

    const handleCheckboxChange = (userId, newPermission) => {
        setNewUsers(prevUsers => {
            return prevUsers.map(user => {
                if (user.user === userId) {
                     // If "upload" is already checked and it's clicked again, set permissions to "view"
                    if(newPermission === 'upload' && user.permissions === 'upload') {
                        return {
                            ...user,
                            permissions: 'view'
                        };
                    } else {
                        return {
                            ...user,
                            permissions: newPermission
                        };
                    }   
                }
                return user; // Return the user unchanged if it's not the one we're looking for
            });
        });
    };

    const isOptionEqualToValue = (option, value) => option.id === value?.user;

    const getOptionDisabled = (option) => {
        return newUsers.some(client => client?.id === option.id);
    };

    return (
        <>
            {Array.from({ length: inputCount }).map((_, index) => (
            <Box key={index}>
                <Autocomplete 
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    sx={{ width: 400 }}
                    size="small"
                    value={newUsers[index] || null}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e, value) => {e.stopPropagation(); handleClientChange(index, value); }}
                    isOptionEqualToValue={isOptionEqualToValue}
                    getOptionDisabled={getOptionDisabled}
                    renderInput={(params) => <TextField {...params} label="Choose client" fullWidth />}
                    disabled={activeUsers.length >= 3}
                />
                {newUsers[index] && (
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography>{newUsers[index].name}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{newUsers[index]._id}</Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={newUsers[index].permissions === 'view' || newUsers[index].permissions === 'upload'}
                                        onChange={(e) => handleCheckboxChange(newUsers[index].user, 'view')}
                                    />
                                }
                                label="View"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        checked={newUsers[index].permissions === 'upload'}
                                        onChange={(e) => handleCheckboxChange(newUsers[index].user, 'upload')}

                                    />
                                }
                                label="Upload"
                            />
                        </Grid>
                        <Grid item className='warning' xs={12}>
                                <Typography className='warning-msg'>{newUsers[index].firstName} {newUsers[index].lastName} will be granted access to this business and its associated documents.</Typography>
                        </Grid>
                    </Grid>
                )}
            </Box>
            ))}
             {inputCount < maxAdditionalUsers && (
                <IconButton className='add-more-btn' onClick={addNewClientInput}>
                    <AddIcon />
                </IconButton>
            )}
        </>
    )
}

export default AddBusinessUser;