import React, { useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { customTrim } from '../../../utils/custom-trim';
import { generateUniqueKey } from '../../../utils/generateKey';

import DialogSuccessMsg from '../../ui/DialogSuccessMsg';


const groups = ['Personal Information', 'Personal Residence', 'Dependents', 'Work & Income Sources', 'Healthcare', 'Education', 
'Deductions and Credits', 'Investments', 'Retirement or Severance', 'Sale of Your Home', 'Gifts', 'Foreign Matters', 'Miscellaneous' ]

const UpdateQuestion = ({ userToken, open, currentQuestion, setEditOpen, setChildChanges }) => {

  	const [updated, setUpdated] = useState(false);
	const [error, setError] = useState('');
	const [question, setQuestion] = useState(currentQuestion);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if(currentQuestion) {
			setQuestion(currentQuestion)
		}
	}, [currentQuestion])



	const handleClose = () => {
		setEditOpen(false);
		setUpdated(false);
		setQuestion(currentQuestion);
		setChildChanges((prevChanges) => !prevChanges);
		setError('');
	};

	const handleSubQuestionTextChange = (e, index) => {
		const subQuestions = [...question.subQuestion];
		subQuestions[index].subQuestionText = e.target.value;
		setQuestion({ ...question, subQuestion: subQuestions });
	};
	
	const handleSubQuestionTypeChange = (e, index) => {
		const subQuestions = [...question.subQuestion];
		subQuestions[index].subQuestionType = e.target.value;
		setQuestion({ ...question, subQuestion: subQuestions });
	};
	// const handleSubQuestionTextChange = (e, index) => {
	// 	const updatedSubQuestions = [...question.subQuestion];
	// 	updatedSubQuestions[index] = {
	// 	  ...updatedSubQuestions[index],
	// 	  subQuestionText: e.target.value,
	// 	};
	// 	setQuestion({ ...question, subQuestion: updatedSubQuestions });
	//   };
	  
	//   const handleSubQuestionTypeChange = (e, index) => {
	// 	const updatedSubQuestions = [...question.subQuestion];
	// 	updatedSubQuestions[index] = {
	// 	  ...updatedSubQuestions[index],
	// 	  subQuestionType: e.target.value,
	// 	};
	// 	setQuestion({ ...question, subQuestion: updatedSubQuestions });
	//   };
	
	const addSubQuestion = () => {
		setQuestion({
		  ...question,
		  subQuestion: [...question.subQuestion, { subQuestionText: '', subQuestionType: '' }],
		});
	};
	
	const deleteSubQuestion = (index) => {
		const subQuestions = [...question.subQuestion];
		subQuestions.splice(index, 1);
		setQuestion({ ...question, subQuestion: subQuestions });
	};
	

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'order') {
			const numericValue = value.replace(/\D/g, '');
			setQuestion({ ...question, [name]: numericValue });
		} else if (name === 'new') {
			setQuestion({ ...question, [name]: e.target.checked });
		} else {
			setQuestion({ ...question, [name]: value });
		}
	};

	const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
		customTrim(question);
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            };
            const response = await axios.post(
                '/api/admin/questionnaire/update',
                question,
                config
            );
            if (response.data.error) {
                setError(response.data.error);
            } else {
                setUpdated(true);
                setError('');
            }
        } catch (error) {
            console.log('Error')
            setError('Error occurred while processing your request. Please try again later.')
        } finally {
            setLoading(false);
        }
    };

  	return (
		<>
			<Dialog
				aria-labelledby="update-que-modal"
        	    aria-describedby="update-que-modal"
				id='update-que-modal'
        	    open={open}
			    onClose={handleClose}
                fullWidth
				maxWidth="md"
			>
				<Container className="update-question-container" sx={{ m: 0, p: 2 }} style={{ overflow: 'auto' }}>
					<Grid container justifyContent="space-between" alignItems="center" className="update-que-header" mb={3}>
						<Grid item>
							<Chip color="success" label="Update Question" />
						</Grid>
						<Grid item>
							<IconButton onClick={handleClose}>
								<CloseRoundedIcon />
							</IconButton>
						</Grid>
					</Grid>
					{!updated ? (
						<>
							{error && <Typography className='dialog-error-msg' textAlign="center">{error}</Typography>}
							{question ? (
								<Grid container mb={3}>
									<Grid item xs={12}>
										<Box component="form" onSubmit={handleSubmit}>
											<Grid container spacing={2} justifyContent="center">
												<Grid item xs={10}>
													<TextField
														label="Question"
														multiline
														fullWidth
														required
														error={!question.text}
														name="text"
														size="small"
														value={question.text}
														onChange={handleChange}
														className="custom-textfield"
													/>
												</Grid>
												<Grid item xs={10}>
													<FormLabel>Check as NEW</FormLabel>
													<Switch
														name="new"
														checked={question.new}
														onChange={handleChange}
													/>
												</Grid>
												<Grid item xs={10}>
													<TextField
														select
														label="Group"
														fullWidth
														required
														name="group"
														value={question.group.toLowerCase()}
														onChange={handleChange}
														size="small"
													>
														{groups.map((option) => (
															<MenuItem value={option.toLowerCase()} key={option}>
															{option}
															</MenuItem>
														))}
													</TextField>
												</Grid>
												<Grid item xs={10}>
													<FormLabel>Type Of Question</FormLabel>
													<RadioGroup
														name="type"
														value={question.type}
														onChange={handleChange}
														row
														size="small"
													>
														<FormControlLabel
															value="input"
															control={<Radio />}
															label="Textfield"
														/>
														<FormControlLabel
															value="general"
															control={<Radio />}
															label="Yes/No"
														/>
													</RadioGroup>
												</Grid>
												<Grid item xs={10}>
													<TextField
														label="Note for Questionnaire"
														multiline
														fullWidth
														name="note"
														value={question.note}
														onChange={handleChange}
														size="small"
													/>
												</Grid>
												<Grid item xs={10}>
													<TextField
														label="Note For Documents Upload"
														multiline
														fullWidth
														name="noteForDocuments"
														value={question.noteForDocuments}
														onChange={handleChange}
														size="small"
													/>
												</Grid>
												<Grid item xs={10}>
													<TextField
														type="text"
														label="Order #"
														fullWidth
														required
														error={!question.order}
														name="order"
														value={question.order}
														onChange={handleChange}
														size="small"
													/>
												</Grid>
												{question.subQuestion && question.subQuestion.length > 0 ? (
													question.subQuestion.map((subQuestion, index) => (
														<Grid item xs={10} key={index} className="sub-que-wrapper" mt={2}>
															<Grid item xs={12} mb={1}>
																<Box className='sub-que-actions'>
																	<FormLabel>Sub Question {index+ 1}</FormLabel>
																	<Box>
																		<IconButton onClick={() => deleteSubQuestion(index)}>
																			<DeleteIcon color="error" />
																		</IconButton>
																		<IconButton onClick={addSubQuestion}>
																			<AddCircleOutlineIcon color="success" />
																		</IconButton>
																	</Box>
																</Box>
																<TextField
																	multiline
																	fullWidth
																	label="Sub Question"
																	value={subQuestion.subQuestionText}
																	onChange={(e) => handleSubQuestionTextChange(e, index)}
																	size="small"
																/>
															</Grid>
															<Grid item xs={12}>
																<FormLabel>Type Of Sub Question</FormLabel>
																<RadioGroup
																	aria-label="sub-question"
																	value={subQuestion.subQuestionType}
																	onChange={(e) => handleSubQuestionTypeChange(e, index)}
																	row
																	size="small"
																>
																	<FormControlLabel
																		value="input"
																		control={<Radio />}
																		label="Textfield"
																	/>
																	<FormControlLabel
																		value="general"
																		control={<Radio />}
																		label="Yes/No"
																	/>
																</RadioGroup>
															</Grid>
														</Grid>
													))
												) : (
													<Grid item xs={10}>
														<Button type="button" className='add-sub-que-btn' onClick={addSubQuestion}>Add Sub Que</Button>
													</Grid>
												)}
												<Grid item xs={10} container justifyContent="flex-end">
													<Grid item>
														<Button className='update-que-btn' type="submit">Update</Button>
													</Grid>
												</Grid>
											</Grid>
										</Box>
									</Grid>
								</Grid>
							): null}
						</>
					) : (
						<DialogSuccessMsg msg="Success! Question has been updated." />
					)}
				</Container>
			</Dialog>
		</>
  	)
}

export default UpdateQuestion;