import React, {useEffect, useState, useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';


const GeneralSignaturePad = (props) => {
  	const sigCanvas = useRef()

	const create = () => {
		const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
		props.setImageURL(URL);
		props.setOpenSignaturePad(false);
	};

	return (
		<>
			<Dialog 
				open={props.openSignaturePad} 
				id='signature-modal' 
				onClose={() => props.setOpenSignaturePad(false)}
				fullWidth
            	maxWidth="sm"
			>
				<Box py={2} px={2}>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Button className='cancel-btn' onClick={() => props.setOpenSignaturePad(false)}>
								Cancel
							</Button>
						</Grid>
						<Grid item>
							<Typography component="p">
								Signature
							</Typography>
						</Grid>
						<Grid item>
							<Button 
								className='clear-btn'
								onClick={() => sigCanvas.current.clear()}
								variant='outlined'
							>
								Clear
							</Button>
						</Grid>
						
					</Grid>
				
					<Box className='canvas-container'>
						<SignatureCanvas penColor="black" canvasProps={{ className: "sigCanvas" }} ref={sigCanvas} />

					</Box>
					<Grid container justifyContent="center" mt={2}>
						<Grid item>
							<Button className='done-btn' onClick={create}>
								Done
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Dialog>
		</>
	)

}

export default GeneralSignaturePad;