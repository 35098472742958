import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { verifyEmail, loginUser } from "../../../actions/authActions";
import { Link } from 'react-router-dom';
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import KeyIcon from '@mui/icons-material/Key';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import LoginIcon from '../../../assets/auth/login1.png'
import LoginIcon2 from '../../../assets/auth/login2.png'
import LoginIcon3 from '../../../assets/auth/login3.png'

const LoginComponent= ({ email, setEmail, password, setPassword, handlePasswordSubmit, loginError }) => {

    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [isPasswordVisisble, setPasswordVisible] = useState(false)
    const dispatch = useDispatch();
    const [error, setError] = useState('');

    const handleShowPassword = () => {
        setPasswordVisible(!isPasswordVisisble)
    }

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(verifyEmail(email));
            // Will throw an error if the promise was rejected
            unwrapResult(result); 
            // This line will only be reached if the promise was fulfilled
            setShowPasswordInput(true); 
        }catch (error) {
            setShowPasswordInput(false);
            setError(error.message)
        }
    };

    return (
        <>
            <Box className='form-wrapper'>
                <Grid container className='form-header'>
                    <Grid item xs={12}>
                        <Typography component="h1">Welcome Back!</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="p">Please sign in to continue</Typography>
                    </Grid>
                    <Grid item className='login-img'>
                        <img src={LoginIcon3} />
                    </Grid>
                </Grid>
                {!showPasswordInput && (
                    <>
                        <Box component="form" role="form" onSubmit={handleEmailSubmit} className='login-form' mt={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                        required 
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className="login-button"
                                            sx={{ mt: 1, mb: 2 }}
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {error && (
                            <Grid container className='login-error-container' justifyContent="center">
                                <Grid item xs={8}>
                                    <ErrorOutlineIcon />
                                    <Typography component="p">{error}</Typography>
                                </Grid>

                            </Grid>
                        )}
                    </>
                )}
                {showPasswordInput && (
                    <>
                        <Grid container justifyContent="center" mt={2} mb={1}>
                            <Grid item className='forgot-pass-link'>
                                <Link to="/reset">
                                    Forgot a password?
                                </Link>
                            </Grid>
                        </Grid>
                        <Box component="form" role="form" onSubmit={handlePasswordSubmit} className='login-form'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        type={isPasswordVisisble ? 'text' : 'password'} 
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your password"
                                        required
                                        fullWidth
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">
                                                <KeyIcon />
                                            </InputAdornment>,
                                            endAdornment:(
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        name="1"
                                                        edge="end"
                                                        onClick={handleShowPassword}
                                                    >
                                                        {isPasswordVisisble ? <VisibilityOffIcon /> : <VisibilityIcon /> }
        
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-end">
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className="login-button"
                                            sx={{ mt: 1, mb: 2 }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                            </Grid>
                            </Grid>
                        </Box>
                        {loginError && (
                            <Grid container className='login-error-container' justifyContent="center">
                                <Grid item xs={8}>
                                    <ErrorOutlineIcon />
                                    <Typography component="p">{loginError}</Typography>
                                </Grid>

                            </Grid>
                        )}
                    </>
                )}

            </Box>
        </>
    )
}

export default LoginComponent;