
export const customTrim = (o) => {
	for (const [k, v] of Object.entries(o)) {
	  if (Object(v) === v)
		customTrim(v)
	  else if (typeof v === 'string')
		o[k] = v.trim();
	}
	return o;
}
