import React  from 'react';
import { useDispatch } from "react-redux";
import { getUserDetails } from '../../../actions/authActions';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import ThankYouImg from '../../../assets/icons/thank-you.png';


const currentYear = new Date(Date.now()).getFullYear();

const GeneralSuccessApplicationMessage = ({ userType }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


	const handleClick = async () => {
		const newInfo = await dispatch(getUserDetails());
        console.log('New Info from success msg', newInfo)
		if(newInfo.payload.completedYear === currentYear && newInfo.payload.mergedAccount !== 2) {
			navigate('/dashboard')
		}
        if(newInfo.payload.completedYearFromMergedAccount === currentYear) {
			navigate(`/dashboard/merge/my-account/${newInfo.payload.activeMergedAccountId}`)
		}
	}

    return (
        <Box id="success-application-wrapper">
            <Grid container justifyContent="center" mt={2}>
                <Grid item xs={6}>
                    <img src={ThankYouImg} />
                </Grid>
            </Grid>
            <Grid container mt={4} justifyContent="center">
                <Grid item mb={2}>
                    <Typography component="h2">
                    All sections of your questionnaire have been successfully completed and submitted. Thank you!
                    </Typography>
                </Grid>
                <Grid item mb={2}>
                    <Typography component="p">
                        You can now proceed to your dashboard. Should you have any questions or need further assistance, please don't hesitate to contact us.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={4} justifyContent="center">
                <Grid item mb={2}>
                    <Button 
                        onClick={handleClick}
                    >
                        Go to Dashboard
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default GeneralSuccessApplicationMessage;